import React from "react";
import styled from "styled-components";
import { PoppinsBoldWhite20px, ValignTextMiddle, PoppinsBoldWhite36px } from "../../styledMixins";


function Group46649(props) {
  const { className } = props;

  return (
    <Group466491 className={`group-46649 ${className || ""}`}>
      <Frame46677 className="frame-46677">
        <WhatOurUsersSay className="what-our-users-say">
          <span>
            <span className="span0-6 poppins-bold-zircon-20px">What </span>
            <span className="span1-6 poppins-bold-blaze-orange-20px">our users say</span>
          </span>
        </WhatOurUsersSay>
        <READOurCustomerStories className="read-our-customer-stories">
          <span className="span0-7 poppins-bold-zircon-36px">READ our </span>
          <span className="span1-7 poppins-bold-blaze-orange-36px">customer stories</span>
        </READOurCustomerStories>
      </Frame46677>
    </Group466491>
  );
}

const Group466491 = styled.div`
  position: relative;
  display: flex;
  width: 624px;
  height: 87px;

  &.group-46649.group-46649-2 {
    align-items: center;
    justify-content: center;
  }
`;

const Frame46677 = styled.div`
  display: inline-flex;
  width: 624px;
  height: 87px;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

const WhatOurUsersSay = styled.div`
  ${ValignTextMiddle}
  ${PoppinsBoldWhite20px}
            position: relative;
  width: 350px;
  height: 33px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const READOurCustomerStories = styled.div`
  ${PoppinsBoldWhite36px}
  position: relative;
  width: 624px;
  letter-spacing: 0;
  line-height: 50px;
`;

export default Group46649;
