import React from "react";
import Frame6622 from "../Frame6622";
import styled from "styled-components";


function OURCUSTOMERS(props) {
  const { className } = props;

  return (
    <OURCUSTOMERS1 className={`our-customers-3 ${className || ""}`}>
      <Frame6622 />
      <Frame46635 className="frame-46635-2" src="/img/frame-46635-1.png" alt="Frame 46635" />
    </OURCUSTOMERS1>
  );
}

const OURCUSTOMERS1 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  position: absolute;
  top: 74px;
  left: 186px;

  &.our-customers-3.our-customers-4 {
    top: 32px;
    left: 46px;
  }
`;

const Frame46635 = styled.img`
  position: relative;
  flex: 0 0 auto;
`;

export default OURCUSTOMERS;
