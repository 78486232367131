import React from "react";
import styled from "styled-components";
import { PoppinsSemiBoldWhite20px } from "../../styledMixins";


function BigButton(props) {
  const { children } = props;

  return (
    <BigButton1>
      <GetStartedForFree>{children}</GetStartedForFree>
    </BigButton1>
  );
}

const BigButton1 = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 10px;
  padding: 20px 42px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--primaryorange);
  border-radius: 200px;
`;

const GetStartedForFree = styled.div`
  ${PoppinsSemiBoldWhite20px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const BigButton2 = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 10px;
  padding: 20px 42px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--primaryorange);
  border-radius: 200px;
`;

const GetStartedForFree1 = styled.div`
  ${PoppinsSemiBoldWhite20px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const BigButton3 = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 10px;
  padding: 20px 42px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--primaryorange);
  border-radius: 200px;
`;

const GetStartedForFree2 = styled.div`
  ${PoppinsSemiBoldWhite20px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

export default BigButton;
