import React from "react";
import styled from "styled-components";
import {
  PoppinsBoldZircon16px,
  PoppinsBoldBlueRibbon16px,
  ValignTextMiddle,
  PoppinsLightZircon12px,
} from "../../styledMixins";


function Frame46791(props) {
  const { number, administrativeControlHub, efficientlyManageU } = props;

  return (
    <Frame467911>
      <Frame46790>
        <Number>{number}</Number>
        <AdministrativeControlHub>{administrativeControlHub}</AdministrativeControlHub>
      </Frame46790>
      <EfficientlyManageU>{efficientlyManageU}</EfficientlyManageU>
    </Frame467911>
  );
}

const Frame467911 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
`;

const Frame46790 = styled.div`
  display: inline-flex;
  align-items: flex-start;
  position: relative;
  flex: 0 0 auto;
`;

const Number = styled.div`
  ${ValignTextMiddle}
  ${PoppinsBoldBlueRibbon16px}
            position: relative;
  width: 25px;
  height: 24px;
  margin-top: -2px;
  margin-left: -1px;
  -webkit-text-stroke: 1px var(--primaryghost-white);
  letter-spacing: 0;
  line-height: normal;
`;

const AdministrativeControlHub = styled.div`
  ${ValignTextMiddle}
  ${PoppinsBoldZircon16px}
            position: relative;
  width: 317px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const EfficientlyManageU = styled.p`
  ${PoppinsLightZircon12px}
  position: relative;
  width: 348px;
  letter-spacing: 0;
  line-height: 18px;
`;

export default Frame46791;
