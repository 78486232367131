import React from "react";
import styled from "styled-components";
import { PoppinsNormalEbonyClay12px, PoppinsSemiBoldEbonyClay12px } from "../../styledMixins";


function Frame46768(props) {
  const { surname, trueCorp } = props;

  return (
    <Frame467681>
      <Ellipse48></Ellipse48>
      <Surname>{surname}</Surname>
      <TrueCorp>{trueCorp}</TrueCorp>
    </Frame467681>
  );
}

const Frame467681 = styled.div`
  display: flex;
  flex-direction: column;
  width: 254px;
  height: 160px;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px;
  position: relative;
  background-color: var(--secondarywhite01);
  border-radius: 12px;
  overflow: hidden;
`;

const Ellipse48 = styled.div`
  position: absolute;
  top: 14px;
  left: -148px;
  width: 475px;
  height: 475px;
  border-radius: 237.37px;
  transform: rotate(129.72deg);
  background: linear-gradient(
    180deg,
    rgb(244.0000006556511, 246.0000005364418, 255) 0%,
    rgba(244.0000006556511, 246.0000005364418, 255, 0) 100%
  );
`;

const Surname = styled.p`
  ${PoppinsSemiBoldEbonyClay12px}
  position: relative;
  align-self: stretch;
  margin-top: -474.62586481167546px;
  letter-spacing: 0;
  line-height: 16.9px;
`;

const TrueCorp = styled.div`
  ${PoppinsNormalEbonyClay12px}
  position: relative;
  align-self: stretch;
  margin-top: -473.62586481167546px;
  letter-spacing: 0;
  line-height: 16.9px;
`;

export default Frame46768;
