import React from "react";
import styled from "styled-components";
import { PoppinsBoldBlue36px, ValignTextMiddle, PoppinsBoldBlazeOrange20px } from "../../styledMixins";


function Frame66222(props) {
  const { className } = props;

  return (
    <Frame6622 className={`frame-6622-1 ${className || ""}`}>
      <PLANPRICING className="plan-pricing">PLAN &amp; PRICING</PLANPRICING>
      <PricingBuiltForBu className="pricing-built-for-bu">PRICING BUILT FOR BUSINESSES OF ALL SIZES</PricingBuiltForBu>
    </Frame6622>
  );
}

const Frame6622 = styled.div`
  display: inline-flex;
  position: relative;
  flex-direction: column;
  align-items: center;

  &.frame-6622-1.frame-6622-2 {
    position: absolute;
    top: 56px;
    left: 120px;
  }
`;

const PLANPRICING = styled.div`
  ${ValignTextMiddle}
  ${PoppinsBoldBlazeOrange20px}
            position: relative;
  width: 160px;
  height: 36px;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const PricingBuiltForBu = styled.p`
  ${ValignTextMiddle}
  ${PoppinsBoldBlue36px}
            position: relative;
  width: 784px;
  height: 47px;
  text-align: center;
  letter-spacing: 0;
  line-height: 58px;
  white-space: nowrap;
`;

export default Frame66222;
