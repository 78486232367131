import React from "react";
import styled from "styled-components";


function Frame46703(props) {
  const { className } = props;

  return (
    <Frame467031 className={`frame-46703 ${className || ""}`}>
      <Group46643 className="group-46643">
        <Rectangle13313 className="rectangle-13313"></Rectangle13313>
      </Group46643>
    </Frame467031>
  );
}

const Frame467031 = styled.div`
  display: inline-flex;
  align-items: flex-end;
  gap: 362px;
  position: relative;
  flex: 0 0 auto;

  &.frame-46703.frame-46703-1 {
    position: absolute;
    top: 214px;
    left: 813px;
    flex: unset;
  }
`;

const Group46643 = styled.div`
  position: relative;
  display: flex;
  width: 77px;
  height: 8px;
  background-color: var(--primaryorange);
`;

const Rectangle13313 = styled.div`
  width: 38.5px;
  height: 8px;
  background-color: var(--primaryblue-ryb);
`;

export default Frame46703;
