import React from "react";
import Group46649 from "../Group46649";
import Frame46682 from "../Frame46682";
import styled from "styled-components";


function Frame46707(props) {
  const { className, frame466781Props } = props;

  return (
    <Frame467071 className={`frame-46707 ${className || ""}`}>
      <Group46649 />
      <Frame46682
        frame466781Props={frame466781Props.frame466781Props}
        frame466782Props={frame466781Props.frame466782Props}
        frame466841Props={frame466781Props.frame466841Props}
        frame466842Props={frame466781Props.frame466842Props}
      />
    </Frame467071>
  );
}

const Frame467071 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  position: relative;
  flex: 0 0 auto;

  &.frame-46707.frame-46707-1 {
    position: absolute;
    top: 18px;
    left: 24px;
    flex: unset;
  }
`;

export default Frame46707;
