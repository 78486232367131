import React from "react";
import styled from "styled-components";
import { InterNormalTuna18px } from "../../styledMixins";


function List(props) {
  const { userLicense, className } = props;

  return (
    <List1 className={`list ${className || ""}`}>
      <BxsCheckCircle21
        className="bxs-check-circle-2-1"
        src="/img/bxs-check-circle--2--1@2x.png"
        alt="bxs-check-circle (2) 1"
      />
      <UserLicense className="user-license">{userLicense}</UserLicense>
    </List1>
  );
}

const List1 = styled.div`
  display: flex;
  width: 300px;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
`;

const BxsCheckCircle21 = styled.img`
  position: relative;
  width: 24px;
  height: 24px;
`;

const UserLicense = styled.div`
  ${InterNormalTuna18px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const LineOAWebCommerce = styled.div`
  ${InterNormalTuna18px}

  .list.list-1  & {
    width: 280px;
    margin-right: -12px;
  }
`;

const ProductManagement = styled.div`
  ${InterNormalTuna18px}

  .list.list-2  & {
    width: 280px;
    margin-right: -12px;
  }
`;

const LineOA = styled.div`
  ${InterNormalTuna18px}

  .list.list-3  & {
    width: 280px;
    margin-right: -12px;
  }
`;

const FacebookMessenger = styled.div`
  ${InterNormalTuna18px}

  .list.list-4  & {
    width: 280px;
    margin-right: -12px;
  }
`;

const Chatbot = styled.div`
  ${InterNormalTuna18px}

  .list.list-5  & {
    width: 280px;
    margin-right: -12px;
  }
`;

const AutomationFlow = styled.div`
  ${InterNormalTuna18px}

  .list.list-6  & {
    width: 280px;
    margin-right: -12px;
  }
`;

const Report = styled.div`
  ${InterNormalTuna18px}

  .list.list-7  & {
    width: 280px;
    margin-right: -12px;
  }
`;

const Dashboard = styled.div`
  ${InterNormalTuna18px}

  .list.list-8  & {
    width: 280px;
    margin-right: -12px;
  }
`;

const OnboardingRolloutSupport = styled.div`
  ${InterNormalTuna18px}

  .list.list-9  & {
    width: 280px;
    margin-right: -12px;
  }
`;

const SLASupport = styled.div`
  ${InterNormalTuna18px}

  .list.list-10  & {
    width: 280px;
    margin-right: -12px;
  }
`;

const OneTimeSetupFeesTHB = styled.div`
  ${InterNormalTuna18px}

  .list.list-11  & {
    width: 280px;
    margin-right: -12px;
  }
`;

const PerCompanyMonthTHB = styled.p`
  ${InterNormalTuna18px}

  .list.list-12  & {
    width: 280px;
    margin-right: -12px;
  }
`;

const LineOAWebCommerce1 = styled.div`
  ${InterNormalTuna18px}

  .list.list-14  & {
    width: 280px;
    margin-right: -12px;
  }
`;

const ProductManagement1 = styled.div`
  ${InterNormalTuna18px}

  .list.list-15  & {
    width: 280px;
    margin-right: -12px;
  }
`;

const LineOA1 = styled.div`
  ${InterNormalTuna18px}

  .list.list-16  & {
    width: 280px;
    margin-right: -12px;
  }
`;

const FacebookMessenger1 = styled.div`
  ${InterNormalTuna18px}

  .list.list-17  & {
    width: 280px;
    margin-right: -12px;
  }
`;

const Chatbot1 = styled.div`
  ${InterNormalTuna18px}

  .list.list-18  & {
    width: 280px;
    margin-right: -12px;
  }
`;

const AutomationFlow1 = styled.div`
  ${InterNormalTuna18px}

  .list.list-19  & {
    width: 280px;
    margin-right: -12px;
  }
`;

const Report1 = styled.div`
  ${InterNormalTuna18px}

  .list.list-20  & {
    width: 280px;
    margin-right: -12px;
  }
`;

const Dashboard1 = styled.div`
  ${InterNormalTuna18px}

  .list.list-21  & {
    width: 280px;
    margin-right: -12px;
  }
`;

const OnboardingRolloutSupport1 = styled.div`
  ${InterNormalTuna18px}

  .list.list-22  & {
    width: 280px;
    margin-right: -12px;
  }
`;

const SLASupport1 = styled.div`
  ${InterNormalTuna18px}

  .list.list-23  & {
    width: 280px;
    margin-right: -12px;
  }
`;

const OneTimeSetupFeesTHB1 = styled.div`
  ${InterNormalTuna18px}

  .list.list-24  & {
    width: 280px;
    margin-right: -12px;
  }
`;

const PerCompanyMonthTHB1 = styled.p`
  ${InterNormalTuna18px}

  .list.list-25  & {
    width: 280px;
    margin-right: -12px;
  }
`;

const LineOAWebCommerce2 = styled.div`
  ${InterNormalTuna18px}

  .list.list-26  & {
    width: 280px;
    margin-right: -12px;
  }
`;

const ProductManagement2 = styled.div`
  ${InterNormalTuna18px}

  .list.list-27  & {
    width: 280px;
    margin-right: -12px;
  }
`;

const LineOA2 = styled.div`
  ${InterNormalTuna18px}

  .list.list-28  & {
    width: 280px;
    margin-right: -12px;
  }
`;

const FacebookMessenger2 = styled.div`
  ${InterNormalTuna18px}

  .list.list-29  & {
    width: 280px;
    margin-right: -12px;
  }
`;

const Chatbot2 = styled.div`
  ${InterNormalTuna18px}

  .list.list-30  & {
    width: 280px;
    margin-right: -12px;
  }
`;

const AutomationFlow2 = styled.div`
  ${InterNormalTuna18px}

  .list.list-31  & {
    width: 280px;
    margin-right: -12px;
  }
`;

const Report2 = styled.div`
  ${InterNormalTuna18px}

  .list.list-32  & {
    width: 280px;
    margin-right: -12px;
  }
`;

const Dashboard2 = styled.div`
  ${InterNormalTuna18px}

  .list.list-33  & {
    width: 280px;
    margin-right: -12px;
  }
`;

const OnboardingRolloutSupport2 = styled.div`
  ${InterNormalTuna18px}

  .list.list-34  & {
    width: 280px;
    margin-right: -12px;
  }
`;

const SLASupport2 = styled.div`
  ${InterNormalTuna18px}

  .list.list-35  & {
    width: 280px;
    margin-right: -12px;
  }
`;

const OneTimeSetupFeesTHB2 = styled.div`
  ${InterNormalTuna18px}

  .list.list-36  & {
    width: 280px;
    margin-right: -12px;
  }
`;

const PerCompanyMonthTHB2 = styled.p`
  ${InterNormalTuna18px}

  .list.list-37  & {
    width: 280px;
    margin-right: -12px;
  }
`;

export default List;
