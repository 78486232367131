import React from "react";
import styled from "styled-components";
import { InterSemiBoldWhite22px } from "../../styledMixins";


function PrimaryButton2(props) {
  const { className } = props;

  return (
    <PrimaryButton className={`primary-button-1 ${className || ""}`}>
      <ButtonLabel className="button-label-1">Choose</ButtonLabel>
    </PrimaryButton>
  );
}

const PrimaryButton = styled.div`
  margin-left: 11px;
  margin-right: 9px;
  flex: 1;
  max-height: 64px;
  margin-top: 11px;
  display: flex;
  background-color: var(--primaryorange);
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 12px 32px #ff1d8980;

  &.primary-button-1.primary-button-2 {
    width: 280px;
    height: 64px;
    margin-right: 1px;
    margin-left: unset;
    flex: unset;
    max-height: unset;
  }

  &.primary-button-1.primary-button-3 {
    width: 280px;
    height: 64px;
    margin-right: 1px;
    margin-left: unset;
    flex: unset;
    max-height: unset;
  }
`;

const ButtonLabel = styled.div`
  ${InterSemiBoldWhite22px}
  margin-top: 12px;
  width: 280px;
  height: 40px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
`;

export default PrimaryButton2;
