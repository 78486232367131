import React from "react";
import List52 from "../List52";
import styled from "styled-components";
import { InterNormalWhite22px } from "../../styledMixins";


function ListOfItems9(props) {
  const { className } = props;

  return (
    <ListOfItems className={`list-of-items-43 ${className || ""}`}>
      <Text className="text-6"></Text>
      <List52 />
      <List52 />
      <List52 />
    </ListOfItems>
  );
}

const ListOfItems = styled.div`
  display: flex;
  margin-left: 6px;
  width: 268px;
  height: 165px;
  position: relative;
  margin-top: 12px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  &.list-of-items-43.list-of-items-44 {
    margin-top: 33px;
  }

  &.list-of-items-43.list-of-items-45 {
    margin-top: 33px;
  }
`;

const Text = styled.div`
  ${InterNormalWhite22px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
`;

export default ListOfItems9;
