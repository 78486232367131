import React from "react";
import styled from "styled-components";
import { InterNormalTuna18px, InterNormalWhite22px } from "../../styledMixins";


function ListOfItems8(props) {
  const { className } = props;

  return (
    <ListOfItems className={`list-of-items-40 ${className || ""}`}>
      <UserLicense className="user-license-3">User License</UserLicense>
      <Unlimited className="unlimited-2">Unlimited</Unlimited>
    </ListOfItems>
  );
}

const ListOfItems = styled.div`
  display: inline-flex;
  margin-left: 73px;
  width: 135px;
  height: 73px;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  &.list-of-items-40.list-of-items-41 {
    margin-top: 3146px;
  }

  &.list-of-items-40.list-of-items-42 {
    margin-top: 2741px;
  }
`;

const UserLicense = styled.div`
  ${InterNormalWhite22px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const Unlimited = styled.div`
  ${InterNormalTuna18px}
  position: relative;
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
`;

export default ListOfItems8;
