import React from "react";
import styled from "styled-components";
import { InterNormalTuna18px } from "../../styledMixins";


function List2() {
  return (
    <List>
      <Address>1000 messages</Address>
    </List>
  );
}

const List = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
`;

const Address = styled.div`
  ${InterNormalTuna18px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const List1 = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
`;

const Address1 = styled.div`
  ${InterNormalTuna18px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const List3 = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
`;

const Address2 = styled.div`
  ${InterNormalTuna18px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

export default List2;
