import React from "react";
import Frame6617 from "../Frame6617";
import styled from "styled-components";


function Brandverse(props) {
  const { brandverseLogo1, brandverseLogo2, group46599, group46589, frame6617Props } = props;

  return (
    <Brandverse1>
      <OverlapGroup>
        <BrandverseLogo src={brandverseLogo1} alt="Brandverse Logo" />
        <Frame6621>
          <BrandverseLogo1 src={brandverseLogo2} alt="Brandverse Logo" />
          <Frame6617 brandverse={frame6617Props.brandverse} />
        </Frame6621>
      </OverlapGroup>
      <Group46599 style={{ backgroundImage: `url(${group46599})` }}>
        <Group46589 src={group46589} alt="Group 46589" />
      </Group46599>
    </Brandverse1>
  );
}

const Brandverse1 = styled.div`
  position: relative;
  width: 624px;
  height: 308px;
  background-color: var(--secondarywhite01);
  border-radius: 24px;
  overflow: hidden;
  border: 1px solid;
  border-color: var(--wild-sand);
`;

const OverlapGroup = styled.div`
  position: absolute;
  width: 515px;
  height: 308px;
  top: 0;
  left: 0;
`;

const BrandverseLogo = styled.img`
  position: absolute;
  width: 307px;
  height: 308px;
  top: 0;
  left: 0;
`;

const Frame6621 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  position: absolute;
  top: 53px;
  left: 40px;
`;

const BrandverseLogo1 = styled.img`
  position: relative;
  width: 60px;
  height: 60px;
`;

const Group46599 = styled.div`
  position: absolute;
  height: 683px;
  top: -2245px;
  left: 17px;
  display: flex;
  align-items: flex-start;
  min-width: 406px;
  background-size: cover;
  background-position: 50% 50%;
`;

const Group46589 = styled.img`
  width: 264px;
  height: 575px;
  margin-left: -756px;
  margin-top: -2190.71px;
`;

export default Brandverse;
