import React from "react";
import List from "../List";
import styled from "styled-components";
import { InterNormalShadyLady22px, InterNormalTuna18px } from "../../styledMixins";


function ListOfItems(props) {
  const { supportedChannel, orderManagement, className, list1Props, list2Props } = props;

  return (
    <ListOfItems1 className={`list-of-items-4 ${className || ""}`}>
      <SupportedChannel className="supported-channel">{supportedChannel}</SupportedChannel>
      <List userLicense={list1Props.userLicense} className={list1Props.className} />
      <List userLicense={list2Props.userLicense} className={list2Props.className} />
      <List1 className="list-38">
        <BxsCheckCircle21
          className="bxs-check-circle-2-1-1"
          src="/img/bxs-check-circle--2--1@2x.png"
          alt="bxs-check-circle (2) 1"
        />
        <OrderManagement className="order-management">{orderManagement}</OrderManagement>
      </List1>
    </ListOfItems1>
  );
}

const ListOfItems1 = styled.div`
  display: flex;
  position: relative;
  margin-top: 20px;
  margin-left: 8px;
  flex-direction: column;
  width: 300px;
  align-items: flex-start;
  gap: 24px;
`;

const SupportedChannel = styled.div`
  ${InterNormalShadyLady22px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const List1 = styled.div`
  display: flex;
  width: 280px;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
`;

const BxsCheckCircle21 = styled.img`
  position: relative;
  width: 24px;
  height: 24px;
`;

const OrderManagement = styled.div`
  ${InterNormalTuna18px}
  position: relative;
  width: 280px;
  margin-top: -1px;
  margin-right: -32px;
  letter-spacing: 0;
  line-height: normal;
`;

const ChatLog = styled.div`
  ${InterNormalTuna18px}

  .list-of-items-4.list-of-items-5  & {
    width: fit-content;
    margin-right: unset;
  }
`;

const CustomerTag = styled.div`
  ${InterNormalTuna18px}

  .list-of-items-4.list-of-items-6  & {
    width: fit-content;
    margin-right: unset;
  }
`;

const ChatLog1 = styled.div`
  ${InterNormalTuna18px}

  .list-of-items-4.list-of-items-8  & {
    width: fit-content;
    margin-right: unset;
  }
`;

const CustomerTag1 = styled.div`
  ${InterNormalTuna18px}

  .list-of-items-4.list-of-items-9  & {
    width: fit-content;
    margin-right: unset;
  }
`;

const ChatLog2 = styled.div`
  ${InterNormalTuna18px}

  .list-of-items-4.list-of-items-10  & {
    width: fit-content;
    margin-right: unset;
  }
`;

const CustomerTag2 = styled.div`
  ${InterNormalTuna18px}

  .list-of-items-4.list-of-items-11  & {
    width: fit-content;
    margin-right: unset;
  }
`;

export default ListOfItems;
