import React from "react";
import styled from "styled-components";
import { InterNormalTuna12px } from "../../styledMixins";


function List9(props) {
  const { children, className } = props;

  return (
    <List className={`list-59 ${className || ""}`}>
      <Address className="address-7">{children}</Address>
    </List>
  );
}

const List = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;

  &.list-59.list-60 {
    justify-content: unset;
  }

  &.list-59.list-61 {
    justify-content: unset;
  }

  &.list-59.list-62 {
    justify-content: unset;
  }
`;

const Address = styled.div`
  ${InterNormalTuna12px}
  position: relative;
  flex: 1;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
`;

export default List9;
