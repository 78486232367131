import React from "react";
import List4 from "../List4";
import styled from "styled-components";
import { InterNormalWhite22px } from "../../styledMixins";


function ListOfItems5() {
  return (
    <ListOfItems>
      <Text></Text>
      <List4 />
      <List4 />
      <List4 />
    </ListOfItems>
  );
}

const ListOfItems = styled.div`
  display: flex;
  width: 268px;
  height: 165px;
  position: relative;
  margin-top: 12px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

const Text = styled.div`
  ${InterNormalWhite22px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
`;

const ListOfItems1 = styled.div`
  display: flex;
  width: 268px;
  height: 165px;
  position: relative;
  margin-top: 12px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

const Text1 = styled.div`
  ${InterNormalWhite22px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
`;

const ListOfItems2 = styled.div`
  display: flex;
  width: 268px;
  height: 165px;
  position: relative;
  margin-top: 12px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

const Text2 = styled.div`
  ${InterNormalWhite22px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
`;

export default ListOfItems5;
