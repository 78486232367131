import React from "react";
import styled from "styled-components";
import {
  PoppinsBoldBlueRibbon24px,
  PoppinsBoldZircon24px,
  ValignTextMiddle,
  PoppinsLightZircon16px,
} from "../../styledMixins";


function Frame66052() {
  return (
    <Frame6605>
      <ClipboardCheck src="/img/clipboard-check@2x.png" alt="clipboard check" />
      <Frame6606>
        <Frame46671>
          <Number>04</Number>
          <ChatHub>ChatHub</ChatHub>
        </Frame46671>
        <RealTimeCustomerC>
          Real-time customer communication, automated responses, and comprehensive interaction logs for seamless support
          and insights
        </RealTimeCustomerC>
      </Frame6606>
    </Frame6605>
  );
}

const Frame6605 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
`;

const ClipboardCheck = styled.img`
  position: relative;
  width: 48px;
  height: 48px;
`;

const Frame6606 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  flex: 0 0 auto;
`;

const Frame46671 = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
`;

const Number = styled.div`
  ${ValignTextMiddle}
  ${PoppinsBoldBlueRibbon24px}
            position: relative;
  width: 33px;
  height: 52px;
  margin-top: -2px;
  margin-left: -1px;
  -webkit-text-stroke: 1px var(--primaryghost-white);
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const ChatHub = styled.div`
  ${ValignTextMiddle}
  ${PoppinsBoldZircon24px}
            position: relative;
  width: 322px;
  height: 52px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const RealTimeCustomerC = styled.p`
  ${PoppinsLightZircon16px}
  position: relative;
  width: 405px;
  letter-spacing: 0;
  line-height: 24px;
`;

const Frame66051 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
`;

const ClipboardCheck1 = styled.img`
  position: relative;
  width: 48px;
  height: 48px;
`;

const Frame66061 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  flex: 0 0 auto;
`;

const Frame466711 = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
`;

const Number1 = styled.div`
  ${ValignTextMiddle}
  ${PoppinsBoldBlueRibbon24px}
            position: relative;
  width: 33px;
  height: 52px;
  margin-top: -2px;
  margin-left: -1px;
  -webkit-text-stroke: 1px var(--primaryghost-white);
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const ChatHub1 = styled.div`
  ${ValignTextMiddle}
  ${PoppinsBoldZircon24px}
            position: relative;
  width: 322px;
  height: 52px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const RealTimeCustomerC1 = styled.p`
  ${PoppinsLightZircon16px}
  position: relative;
  width: 405px;
  letter-spacing: 0;
  line-height: 24px;
`;

export default Frame66052;
