import React from "react";
import Frame46793 from "../Frame46793";
import styled from "styled-components";


function Frame46799(props) {
  const { frame467931Props, frame467932Props } = props;

  return (
    <Frame467991>
      <Frame46793 discount={frame467931Props.discount} frame46791Props={frame467931Props.frame46791Props} />
      <Frame46793 discount={frame467932Props.discount} frame46791Props={frame467932Props.frame46791Props} />
    </Frame467991>
  );
}

const Frame467991 = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  flex: 0 0 auto;
`;

export default Frame46799;
