import React from "react";
import styled from "styled-components";
import { InterNormalTuna18px } from "../../styledMixins";


function ListOfItems12(props) {
  const { className } = props;

  return (
    <ListOfItems className={`list-of-items-52 ${className || ""}`}>
      <Customizable className="customizable-3">Customizable</Customizable>
      <Customizable1 className="customizable-4">Customizable</Customizable1>
    </ListOfItems>
  );
}

const ListOfItems = styled.div`
  ${InterNormalTuna18px}
  display: flex;
  margin-left: 6px;
  width: 268px;
  height: 68px;
  position: relative;
  margin-top: 71px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  &.list-of-items-52.list-of-items-53 {
    margin-top: 104px;
  }

  &.list-of-items-52.list-of-items-54 {
    margin-top: 104px;
  }
`;

const Customizable = styled.div`
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const Customizable1 = styled.div`
  position: relative;
  width: fit-content;
  letter-spacing: 0;
  line-height: normal;
`;

export default ListOfItems12;
