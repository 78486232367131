import React from "react";
import Frame46641 from "../Frame46641";
import Frame46791 from "../Frame46791";
import Frame46793 from "../Frame46793";
import Frame46799 from "../Frame46799";
import Group46600 from "../Group46600";
import Frame46675 from "../Frame46675";
import Frame4672332 from "../Frame4672332";
import Frame6617 from "../Frame6617";
import Frame6621 from "../Frame6621";
import OURCUSTOMERS from "../OURCUSTOMERS";
import Group46649 from "../Group46649";
import Frame46682 from "../Frame46682";
import PricingCard4 from "../PricingCard4";
import PricingCard10 from "../PricingCard10";
import ListOfItems5 from "../ListOfItems5";
import List4 from "../List4";
import List5 from "../List5";
import PrimaryButton2 from "../PrimaryButton2";
import ListOfItems8 from "../ListOfItems8";
import ListOfItems9 from "../ListOfItems9";
import ListOfItems10 from "../ListOfItems10";
import ListOfItems11 from "../ListOfItems11";
import ListOfItems12 from "../ListOfItems12";
import List6 from "../List6";
import PrimaryButton from "../PrimaryButton";
import BigButton from "../BigButton";
import styled from "styled-components";
import {
  InterNormalZircon18px,
  PoppinsNormalZircon16px,
  PoppinsBoldWhite20px,
  InterBoldWoodsmoke32px,
  PoppinsBoldBlazeOrange20px,
  InterBoldWhite32px,
  InterBoldZircon24px,
  InterBoldWoodsmoke24px,
  PoppinsSemiBoldWhite16px,
  PoppinsBoldBlazeOrange16px,
  PoppinsSemiBoldWhite12px,
  PoppinsBoldWhite36px,
  PoppinsNormalMulledWine16px,
  PoppinsBoldBlue36px,
  ValignTextMiddle,
  PoppinsBoldBlueRibbon20px,
  PoppinsNormalZircon20px,
  InterNormalWhite22px,
} from "../../styledMixins";
import "./TabletSmall.css";

function TabletSmall(props) {
  const {
    image107,
    image108,
    image110,
    image109,
    cloud1,
    image123,
    oneAppToDoAll,
    forYourCustomerLove,
    seamlessCommerceE,
    bg,
    mobile,
    whatIs,
    jenosizeMarketingCloud,
    spanText1,
    spanText2,
    image1201,
    cloud2,
    spanText3,
    spanText4,
    spanText5,
    spanText6,
    spanText7,
    group46631,
    image126,
    enjoyASeamlessCha,
    experienceIntegrate,
    cloud3,
    spanText8,
    spanText9,
    spanText10,
    image114,
    image117,
    spanText11,
    spanText12,
    spanText13,
    spanText14,
    spanText15,
    image115,
    brandverseLogo1,
    brandverseLogo2,
    group465991,
    group465891,
    group46609,
    group46610,
    group465992,
    group465892,
    image1202,
    image121,
    cloud4,
    copyright2023JenosizeTechnology,
    planPricing,
    pricingBuiltForB,
    title1,
    listOfItems1,
    userLicense,
    bxsCheckCircle21,
    address,
    divider1,
    text1,
    listOfItems2,
    listOfItems3,
    listOfItems4,
    divider2,
    divider3,
    text2,
    listOfItems5,
    listOfItems6,
    divider4,
    divider5,
    listOfItems7,
    divider6,
    pricing1,
    pricing2,
    title2,
    divider7,
    divider8,
    divider9,
    divider10,
    divider11,
    divider12,
    listOfItems8,
    pricing3,
    pricing4,
    experienceInActiN,
    image116,
    withToolsToMakeE,
    frame46791Props,
    frame46793Props,
    frame467991Props,
    frame467992Props,
    group46600Props,
    frame4672331Props,
    frame4672332Props,
    frame4672333Props,
    frame4672334Props,
    frame6617Props,
    frame6621Props,
    frame6621Props2,
    group46649Props,
    frame46682Props,
    pricingCard4Props,
    pricingCard10Props,
    primaryButton2Props,
    listOfItems8Props,
    listOfItems9Props,
    listOfItems10Props,
    listOfItems11Props,
    listOfItems12Props,
    bigButtonProps,
    frame46641Props,
  } = props;

  return (
    <div className="tablet-small screen">
      <Frame46641 />
      <X1>
        <Group46669>
          <ImageContainer>
            <Image107 src={image107} alt="image 107" />
            <Image108 src={image108} alt="image 108" />
            <Image110 src={image110} alt="image 110" />
            <Image109 src={image109} alt="image 109" />
          </ImageContainer>
        </Group46669>
        <Cloud src={cloud1} alt="Cloud" />
        <BG></BG>
        <Image123 src={image123} alt="image 123" />
        <Frame46785>
          <Frame46784>
            <OneAppToDoAll>{oneAppToDoAll}</OneAppToDoAll>
            <Frame46783>
              <FORYOURCustomerLove>{forYourCustomerLove}</FORYOURCustomerLove>
            </Frame46783>
          </Frame46784>
          <SeamlessCommerceE>{seamlessCommerceE}</SeamlessCommerceE>
        </Frame46785>
      </X1>
      <X2>
        <BG1 src={bg} alt="BG" />
        <Mobile src={mobile} alt="Mobile" />
        <Frame46670>
          <Frame46784>
            <WhatIs>{whatIs}</WhatIs>
            <JenosizeMarketingCloud>{jenosizeMarketingCloud}</JenosizeMarketingCloud>
          </Frame46784>
          <JenosizeMarketingC>
            <span className="poppins-semi-bold-blaze-orange-12px">{spanText1}</span>
            <span className="poppins-normal-black-rock-12px">{spanText2}</span>
          </JenosizeMarketingC>
        </Frame46670>
        <Image120 src={image1201} alt="image 120" />
      </X2>
      <X3>
        <Cloud1 src={cloud2} alt="Cloud" />
        <Frame46822>
          <Frame46646>
            <WhyJenosizeMarketingCloud>
              <span>
                <span className="poppins-bold-blaze-orange-20px">{spanText3}</span>
                <span className="poppins-bold-zircon-20px">{spanText4}</span>
              </span>
            </WhyJenosizeMarketingCloud>
            <ShopChatAndSucce>
              <span className="poppins-bold-zircon-36px">{spanText5}</span>
              <span className="poppins-bold-blaze-orange-36px">{spanText6}</span>
              <span className="poppins-bold-zircon-36px">{spanText7}</span>
            </ShopChatAndSucce>
          </Frame46646>
          <Frame46801>
            <Frame46798>
              <Frame46792>
                <Group46631 src={group46631} alt="Group 46631" />
                <Frame46791
                  number={frame46791Props.number}
                  administrativeControlHub={frame46791Props.administrativeControlHub}
                  efficientlyManageU={frame46791Props.efficientlyManageU}
                />
              </Frame46792>
              <Frame46793 discount={frame46793Props.discount} frame46791Props={frame46793Props.frame46791Props} />
            </Frame46798>
            <Frame46799
              frame467931Props={frame467991Props.frame467931Props}
              frame467932Props={frame467991Props.frame467932Props}
            />
            <Frame46799
              frame467931Props={frame467992Props.frame467931Props}
              frame467932Props={frame467992Props.frame467932Props}
            />
          </Frame46801>
        </Frame46822>
      </X3>
      <X4>
        <Screen>
          <Image126 src={image126} alt="image 126" />
        </Screen>
        <Group46600 className={group46600Props.className} />
        <Frame46808>
          <Frame46803>
            <Frame46675 />
            <EnjoyASeamlessCha>{enjoyASeamlessCha}</EnjoyASeamlessCha>
          </Frame46803>
          <Frame46807>
            <Frame4672332 simplifyShoppingExperienceWithChat={frame4672331Props.simplifyShoppingExperienceWithChat} />
            <Frame4672332 simplifyShoppingExperienceWithChat={frame4672332Props.simplifyShoppingExperienceWithChat} />
            <Frame4672332 simplifyShoppingExperienceWithChat={frame4672333Props.simplifyShoppingExperienceWithChat} />
            <Frame46727>
              <ExperienceIntegrate>{experienceIntegrate}</ExperienceIntegrate>
            </Frame46727>
            <Frame4672332 simplifyShoppingExperienceWithChat={frame4672334Props.simplifyShoppingExperienceWithChat} />
          </Frame46807>
        </Frame46808>
      </X4>
      <X5>
        <Cloud2 src={cloud3} alt="Cloud" />
        <Frame46824>
          <Frame46823>
            <Group46685>
              <OverlapGroup>
                <WhatWeProvideToT>
                  <span className="poppins-bold-eerie-black-36px">{spanText8}</span>
                  <span className="poppins-bold-blaze-orange-36px">{spanText9}</span>
                  <span className="poppins-bold-eerie-black-36px">{spanText10}</span>
                </WhatWeProvideToT>
                <Image114 src={image114} alt="image 114" />
                <Image117 src={image117} alt="image 117" />
              </OverlapGroup>
            </Group46685>
            <Group46686>
              <OverlapGroup1>
                <JenosizeTechnology>
                  <span className="poppins-semi-bold-blue-ribbon-16px">{spanText11}</span>
                  <span className="poppins-light-mulled-wine-16px">{spanText12}</span>
                  <span className="poppins-semi-bold-blaze-orange-16px">{spanText13}</span>
                  <span className="poppins-light-mulled-wine-16px">{spanText14}</span>
                  <span className="poppins-semi-bold-blaze-orange-16px">{spanText15}</span>
                </JenosizeTechnology>
                <Image115 src={image115} alt="image 115" />
              </OverlapGroup1>
            </Group46686>
          </Frame46823>
          <Brandverse>
            <OverlapGroup2>
              <BrandverseLogo src={brandverseLogo1} alt="Brandverse Logo" />
              <Frame66211>
                <BrandverseLogo1 src={brandverseLogo2} alt="Brandverse Logo" />
                <Frame6617 brandverse={frame6617Props.brandverse} />
              </Frame66211>
            </OverlapGroup2>
            <Group46599 style={{ backgroundImage: `url(${group465991})` }}>
              <Group46589 src={group465891} alt="Group 46589" />
            </Group46599>
          </Brandverse>
          <Brandverse>
            <OverlapGroup2>
              <Frame6621 group46609={frame6621Props.group46609} frame6617Props={frame6621Props.frame6617Props} />
              <Group46609 src={group46609} alt="Group 46609" />
              <Group46610 src={group46610} alt="Group 46610" />
            </OverlapGroup2>
            <Group465991 style={{ backgroundImage: `url(${group465992})` }}>
              <Group465891 src={group465892} alt="Group 46589" />
            </Group465991>
          </Brandverse>
        </Frame46824>
      </X5>
      <X6>
        <OURCUSTOMERS className={frame6621Props2.className} />
        <Image1201 src={image1202} alt="image 120" />
        <Image121 src={image121} alt="image 121" />
      </X6>
      <X7>
        <Cloud3 src={cloud4} alt="Cloud" />
        <Frame46825>
          <Group46649 className={group46649Props.className} />
          <Frame46682
            className={frame46682Props.className}
            frame466781Props={frame46682Props.frame466781Props}
            frame466782Props={frame46682Props.frame466782Props}
            frame466841Props={frame46682Props.frame466841Props}
            frame466842Props={frame46682Props.frame466842Props}
          />
        </Frame46825>
        <Frame46625>
          <Copyright2023JenosizeTechnology>{copyright2023JenosizeTechnology}</Copyright2023JenosizeTechnology>
        </Frame46625>
      </X7>
      <X8>
        <Shadow></Shadow>
        <Shadow1>
          <PLANPRICING>{planPricing}</PLANPRICING>
          <PricingBuiltForB>{pricingBuiltForB}</PricingBuiltForB>
        </Shadow1>
        <Content>
          <Frame46769>
            <PricingCard4 {...pricingCard4Props} />
            <PricingCard10 group46668Props={pricingCard10Props.group46668Props} />
            <PricingCard>
              <FlexCol>
                <Title>{title1}</Title>
                <Group46667>
                  <ListOfItems src={listOfItems1} alt="List of Items" />
                  <ListOfItems1>
                    <UserLicense>{userLicense}</UserLicense>
                    <List>
                      <BxsCheckCircle21 src={bxsCheckCircle21} alt="bxs-check-circle (2) 1" />
                      <Address>{address}</Address>
                    </List>
                  </ListOfItems1>
                  <Divider src={divider1} alt="Divider" />
                  <ListOfItems2>
                    <Text>{text1}</Text>
                    <ListOfItems3 src={listOfItems2} alt="List of Items" />
                    <ListOfItems4 src={listOfItems3} alt="List of Items" />
                    <ListOfItems6 src={listOfItems4} alt="List of Items" />
                  </ListOfItems2>
                  <Divider1 src={divider2} alt="Divider" />
                  <ListOfItems5 />
                  <Divider2 src={divider3} alt="Divider" />
                  <ListOfItems7>
                    <Text>{text2}</Text>
                    <ListOfItems13 src={listOfItems5} alt="List of Items" />
                    <List4 />
                    <ListOfItems14 src={listOfItems6} alt="List of Items" />
                  </ListOfItems7>
                  <Divider3 src={divider4} alt="Divider" />
                  <Divider4 src={divider5} alt="Divider" />
                  <ListOfItems15>
                    <ListOfItems16 src={listOfItems7} alt="List of Items" />
                    <List5 />
                  </ListOfItems15>
                  <Divider5 src={divider6} alt="Divider" />
                  <Pricing>{pricing1}</Pricing>
                  <Pricing1>{pricing2}</Pricing1>
                </Group46667>
                <PrimaryButton2 className={primaryButton2Props.className} />
              </FlexCol>
            </PricingCard>
            <PricingCard11>
              <Title1>{title2}</Title1>
              <Group46668>
                <Group466671>
                  <Divider6 src={divider7} alt="Divider" />
                  <Divider7 src={divider8} alt="Divider" />
                  <Divider7 src={divider9} alt="Divider" />
                  <Divider7 src={divider10} alt="Divider" />
                  <Divider8 src={divider11} alt="Divider" />
                  <Divider9 src={divider12} alt="Divider" />
                  <ListOfItems8 className={listOfItems8Props.className} />
                  <ListOfItems9 className={listOfItems9Props.className} />
                  <ListOfItems10 className={listOfItems10Props.className} />
                  <ListOfItems11 className={listOfItems11Props.className} />
                  <ListOfItems12 className={listOfItems12Props.className} />
                  <ListOfItems17>
                    <ListOfItems18 src={listOfItems8} alt="List of Items" />
                    <List6 />
                  </ListOfItems17>
                  <Pricing2>{pricing3}</Pricing2>
                  <Pricing3>{pricing4}</Pricing3>
                </Group466671>
                <PrimaryButton />
              </Group46668>
            </PricingCard11>
          </Frame46769>
        </Content>
        <Group46682>
          <Frame46771></Frame46771>
        </Group46682>
        <Frame46810>
          <Frame46809>
            <Group46687>
              <OverlapGroup3>
                <ExperienceInActiN>{experienceInActiN}</ExperienceInActiN>
                <Image116 src={image116} alt="image 116" />
              </OverlapGroup3>
            </Group46687>
            <WithToolsToMakeE>{withToolsToMakeE}</WithToolsToMakeE>
          </Frame46809>
          <BigButton>{bigButtonProps.children}</BigButton>
        </Frame46810>
        <Frame46712>
          <Ellipse49></Ellipse49>
        </Frame46712>
      </X8>
      <Frame46641 className={frame46641Props.className} />
    </div>
  );
}

const X1 = styled.div`
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 752px;
  background-color: var(--primaryblue-ryb);
  overflow: hidden;
`;

const Group46669 = styled.div`
  position: absolute;
  width: 727px;
  height: 140px;
  top: 612px;
  left: 9px;
`;

const ImageContainer = styled.div`
  position: relative;
  height: 140px;
`;

const Image107 = styled.img`
  position: absolute;
  width: 189px;
  height: 105px;
  top: 35px;
  left: 0;
  object-fit: cover;
`;

const Image108 = styled.img`
  position: absolute;
  width: 251px;
  height: 140px;
  top: 0;
  left: 113px;
  object-fit: cover;
`;

const Image110 = styled.img`
  position: absolute;
  width: 189px;
  height: 105px;
  top: 35px;
  left: 538px;
  object-fit: cover;
`;

const Image109 = styled.img`
  position: absolute;
  width: 251px;
  height: 140px;
  top: 0;
  left: 363px;
  object-fit: cover;
`;

const Cloud = styled.img`
  position: absolute;
  width: 744px;
  height: 152px;
  top: 600px;
  left: 0;
  object-fit: cover;
`;

const BG = styled.div`
  position: absolute;
  width: 744px;
  height: 744px;
  top: 380px;
  left: 0;
  border-radius: 372px;
  transform: rotate(-180deg);
  background: linear-gradient(
    180deg,
    rgb(244.0000006556511, 246.0000005364418, 255) 0%,
    rgba(244.0000006556511, 246.0000005364418, 255, 0) 100%
  );
  opacity: 0.3;
`;

const Image123 = styled.img`
  position: absolute;
  width: 420px;
  height: 372px;
  top: 380px;
  left: 162px;
  object-fit: cover;
`;

const Frame46785 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  position: absolute;
  top: 120px;
  left: 16px;
`;

const Frame46784 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 0 0 auto;
`;

const OneAppToDoAll = styled.p`
  position: relative;
  width: 712px;
  margin-top: -1px;
  font-family: var(--font-family-poppins);
  font-weight: 700;
  color: var(--primaryghost-white);
  font-size: 40px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
`;

const Frame46783 = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 24px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--secondarywhite01);
  border-radius: 12px;
`;

const FORYOURCustomerLove = styled.p`
  position: relative;
  width: fit-content;
  margin-top: -1px;
  font-family: var(--font-family-poppins);
  font-weight: 700;
  color: var(--primaryorange);
  font-size: 40px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
`;

const SeamlessCommerceE = styled.p`
  ${PoppinsNormalZircon20px}
  position: relative;
  width: 712px;
  text-align: center;
  letter-spacing: 0;
  line-height: 32px;
`;

const X2 = styled.div`
  display: flex;
  height: 562px;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  align-self: stretch;
  width: 100%;
  background-color: var(--secondarywhite01);
`;

const BG1 = styled.img`
  position: relative;
  flex: 1;
  flex-grow: 1;
  height: 562px;
`;

const Mobile = styled.img`
  position: absolute;
  top: calc(50% - 281px);
  left: calc(50% - 210px);
  width: 420px;
  height: 336px;
  object-fit: cover;
`;

const Frame46670 = styled.div`
  display: flex;
  flex-direction: column;
  width: 712px;
  align-items: center;
  gap: 12px;
  position: absolute;
  top: calc(50% + 55px);
  left: calc(50% - 356px);
`;

const WhatIs = styled.div`
  ${ValignTextMiddle}
  ${PoppinsBoldBlazeOrange16px}
            position: relative;
  width: 85px;
  height: 18px;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const JenosizeMarketingCloud = styled.div`
  ${ValignTextMiddle}
  ${PoppinsBoldBlueRibbon20px}
            position: relative;
  width: 543px;
  height: 28px;
  text-align: center;
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const JenosizeMarketingC = styled.p`
  ${PoppinsSemiBoldWhite12px}
  position: relative;
  align-self: stretch;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
`;

const Image120 = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 200px;
  height: 69px;
  object-fit: cover;
`;

const X3 = styled.div`
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 773px;
  background-color: var(--primaryblue-ryb);
`;

const Cloud1 = styled.img`
  position: absolute;
  width: 744px;
  height: 152px;
  top: 621px;
  left: 0;
  object-fit: cover;
`;

const Frame46822 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  position: absolute;
  top: 33px;
  left: 16px;
`;

const Frame46646 = styled.div`
  display: flex;
  flex-direction: column;
  width: 712px;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
`;

const WhyJenosizeMarketingCloud = styled.p`
  ${ValignTextMiddle}
  ${PoppinsBoldWhite20px}
            position: relative;
  align-self: stretch;
  height: 28px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const ShopChatAndSucce = styled.p`
  ${PoppinsBoldWhite36px}
  position: relative;
  align-self: stretch;
  letter-spacing: 0;
  line-height: 50px;
`;

const Frame46801 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  position: relative;
  flex: 0 0 auto;
`;

const Frame46798 = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 16px;
  position: relative;
  flex: 0 0 auto;
`;

const Frame46792 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  position: relative;
  flex: 0 0 auto;
`;

const Group46631 = styled.img`
  position: relative;
  width: 57.83203125px;
  height: 48px;
`;

const X4 = styled.div`
  display: flex;
  flex-direction: column;
  height: 969px;
  align-items: center;
  position: relative;
  align-self: stretch;
  width: 100%;
  background-color: var(--secondarywhite01);
`;

const Screen = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
`;

const Image126 = styled.img`
  position: relative;
  width: 604px;
  height: 492px;
  object-fit: cover;
`;

const Frame46808 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  position: absolute;
  top: calc(50% + 60px);
  left: calc(50% - 356px);
`;

const Frame46803 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
`;

const EnjoyASeamlessCha = styled.p`
  ${PoppinsNormalMulledWine16px}
  position: relative;
  width: 712px;
  letter-spacing: 0;
  line-height: 22.5px;
`;

const Frame46807 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  flex: 0 0 auto;
`;

const Frame46727 = styled.div`
  display: flex;
  width: 712px;
  align-items: flex-start;
  gap: 10px;
  padding: 12px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--secondarywhite01);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: var(--black-2);
`;

const ExperienceIntegrate = styled.p`
  ${PoppinsNormalMulledWine16px}
  position: relative;
  flex: 1;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 22.5px;
`;

const X5 = styled.div`
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 969px;
  background-color: var(--secondarywhite01);
`;

const Cloud2 = styled.img`
  position: absolute;
  width: 744px;
  height: 152px;
  top: 817px;
  left: 0;
  object-fit: cover;
`;

const Frame46824 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 15px;
  left: 16px;
`;

const Frame46823 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
`;

const Group46685 = styled.div`
  position: relative;
  width: 714px;
  height: 93px;
  margin-right: -2px;
`;

const OverlapGroup = styled.div`
  position: relative;
  width: 712px;
  height: 93px;
`;

const WhatWeProvideToT = styled.p`
  ${PoppinsBoldWhite36px}
  position: absolute;
  width: 712px;
  top: 0;
  left: 0;
  letter-spacing: 0;
  line-height: 50px;
`;

const Image114 = styled.img`
  position: absolute;
  width: 49px;
  height: 20px;
  top: 68px;
  left: 312px;
  object-fit: cover;
`;

const Image117 = styled.img`
  position: absolute;
  width: 50px;
  height: 5px;
  top: 83px;
  left: 662px;
  object-fit: cover;
`;

const Group46686 = styled.div`
  position: relative;
  width: 714px;
  height: 153px;
  margin-right: -2px;
`;

const OverlapGroup1 = styled.div`
  position: relative;
  width: 712px;
  height: 153px;
`;

const JenosizeTechnology = styled.p`
  ${PoppinsSemiBoldWhite16px}
  position: absolute;
  width: 712px;
  top: 0;
  left: 0;
  letter-spacing: 0;
  line-height: 25.6px;
`;

const Image115 = styled.img`
  position: absolute;
  width: 43px;
  height: 9px;
  top: 136px;
  left: 181px;
  object-fit: cover;
`;

const Brandverse = styled.div`
  position: relative;
  width: 712px;
  height: 308px;
  background-color: var(--secondarywhite01);
  border-radius: 24px;
  overflow: hidden;
  border: 1px solid;
  border-color: var(--wild-sand);
`;

const OverlapGroup2 = styled.div`
  position: absolute;
  width: 515px;
  height: 308px;
  top: 0;
  left: 0;
`;

const BrandverseLogo = styled.img`
  position: absolute;
  width: 307px;
  height: 308px;
  top: 0;
  left: 0;
`;

const Frame66211 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  position: absolute;
  top: 53px;
  left: 40px;
`;

const BrandverseLogo1 = styled.img`
  position: relative;
  width: 60px;
  height: 60px;
`;

const Group46599 = styled.div`
  position: absolute;
  height: 683px;
  top: -2026px;
  left: -2701px;
  display: flex;
  align-items: flex-start;
  min-width: 406px;
  background-size: cover;
  background-position: 50% 50%;
`;

const Group46589 = styled.img`
  width: 264px;
  height: 575px;
  margin-left: -3562px;
  margin-top: -1971.71px;
`;

const Group46609 = styled.img`
  position: absolute;
  width: 466px;
  height: 274px;
  top: 18px;
  left: 0;
`;

const Group46610 = styled.img`
  position: absolute;
  width: 293px;
  height: 308px;
  top: 0;
  left: 0;
`;

const Group465991 = styled.div`
  position: absolute;
  height: 683px;
  top: -2342px;
  left: -2701px;
  display: flex;
  align-items: flex-start;
  min-width: 406px;
  background-size: cover;
  background-position: 50% 50%;
`;

const Group465891 = styled.img`
  width: 264px;
  height: 575px;
  margin-left: -3562px;
  margin-top: -2287.71px;
`;

const X6 = styled.div`
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 355px;
  background-color: var(--secondarywhite01);
`;

const Image1201 = styled.img`
  position: absolute;
  width: 200px;
  height: 69px;
  top: 286px;
  left: 0;
  object-fit: cover;
`;

const Image121 = styled.img`
  position: absolute;
  width: 200px;
  height: 69px;
  top: 286px;
  left: 544px;
  object-fit: cover;
`;

const X7 = styled.div`
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 504px;
  background-color: var(--primaryblue-ryb);
  overflow: hidden;
`;

const Cloud3 = styled.img`
  position: absolute;
  width: 744px;
  height: 152px;
  top: 352px;
  left: 0;
  object-fit: cover;
`;

const Frame46825 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 16px;
  position: absolute;
  top: 26px;
  left: 16px;
`;

const Frame46625 = styled.div`
  display: flex;
  width: 744px;
  height: 80px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0px 10px;
  position: absolute;
  top: 371px;
  left: 0;
`;

const Copyright2023JenosizeTechnology = styled.div`
  ${PoppinsNormalZircon16px}
  position: relative;
  width: fit-content;
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const X8 = styled.div`
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 2182px;
  background-color: var(--secondarywhite01);
`;

const Shadow = styled.div`
  position: absolute;
  width: 712px;
  height: 1380px;
  top: 210px;
  left: 16px;
  background-color: var(--blue-zodiac);
  filter: blur(200px);
  opacity: 0.05;
`;

const Shadow1 = styled.div`
  display: flex;
  flex-direction: column;
  width: 699px;
  align-items: center;
  gap: 8px;
  position: absolute;
  top: 37px;
  left: 22px;
`;

const PLANPRICING = styled.div`
  ${ValignTextMiddle}
  ${PoppinsBoldBlazeOrange20px}
            position: relative;
  width: 160px;
  height: 36px;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const PricingBuiltForB = styled.p`
  ${ValignTextMiddle}
  ${PoppinsBoldBlue36px}
            position: relative;
  width: 712px;
  height: 92px;
  margin-left: -6.5px;
  margin-right: -6.5px;
  text-align: center;
  letter-spacing: 0;
  line-height: 58px;
`;

const Content = styled.div`
  position: absolute;
  width: 712px;
  height: 1380px;
  top: 210px;
  left: 16px;
  display: flex;
  overflow: hidden;
`;

const Frame46769 = styled.div`
  display: inline-flex;
  width: 1336px;
  height: 1380px;
  position: relative;
  align-items: flex-start;
  gap: 12px;
`;

const PricingCard = styled.div`
  position: relative;
  width: 320px;
  height: 1380px;
  background-color: var(--primaryblue-ryb);
  border-radius: 20px;
  overflow: hidden;
`;

const FlexCol = styled.div`
  position: relative;
  width: 295px;
  top: 23px;
  left: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 1337px;
`;

const Title = styled.div`
  ${InterBoldWhite32px}
  width: 292px;
  margin-right: 3px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
`;

const Group46667 = styled.div`
  ${InterBoldZircon24px}
  width: 291px;
  height: 1189px;
  position: relative;
  margin-top: 33px;
  margin-left: 4px;
  display: flex;
  flex-direction: column;
`;

const ListOfItems = styled.img`
  margin-left: -4305px;
  width: 268px;
  height: 72px;
  position: relative;
  margin-top: -2992px;
`;

const ListOfItems1 = styled.div`
  display: inline-flex;
  margin-left: 8px;
  width: 248px;
  height: 75px;
  position: relative;
  margin-top: 2920px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

const UserLicense = styled.div`
  ${InterNormalWhite22px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const Address = styled.div`
  ${InterNormalZircon18px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const List = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
`;

const BxsCheckCircle21 = styled.img`
  position: relative;
  width: 3px;
  height: 24px;
`;

const Divider = styled.img`
  margin-left: 7px;
  width: 4px;
  height: 1px;
  margin-top: 18px;
  object-fit: cover;
`;

const ListOfItems2 = styled.div`
  display: flex;
  margin-left: 12px;
  width: 268px;
  height: 171px;
  position: relative;
  margin-top: 12px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

const Text = styled.div`
  ${InterNormalWhite22px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
`;

const ListOfItems3 = styled.img`
  position: relative;
  flex: 0 0 auto;
  margin-top: -3938px;
  margin-left: -4224px;
`;

const ListOfItems4 = styled.img`
  position: relative;
  flex: 0 0 auto;
  margin-top: -3890px;
  margin-left: -4224px;
`;

const ListOfItems6 = styled.img`
  position: relative;
  flex: 0 0 auto;
  margin-top: -3842px;
  margin-left: -4224px;
`;

const Divider1 = styled.img`
  margin-left: 6px;
  width: 5px;
  height: 1px;
  margin-top: 26px;
  object-fit: cover;
`;

const Divider2 = styled.img`
  margin-left: 6px;
  width: 5px;
  height: 1px;
  margin-top: 32px;
  object-fit: cover;
`;

const ListOfItems7 = styled.div`
  display: flex;
  margin-left: 12px;
  width: 268px;
  height: 169px;
  position: relative;
  margin-top: 12px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

const ListOfItems13 = styled.img`
  position: relative;
  flex: 0 0 auto;
  margin-top: -4358px;
  margin-left: -4224px;
`;

const ListOfItems14 = styled.img`
  position: relative;
  flex: 0 0 auto;
  margin-top: -4264px;
  margin-left: -4224px;
`;

const Divider3 = styled.img`
  margin-left: 6px;
  width: 5px;
  height: 1px;
  margin-top: 28px;
  object-fit: cover;
`;

const Divider4 = styled.img`
  margin-left: 6px;
  width: 5px;
  height: 1px;
  margin-top: 160px;
  object-fit: cover;
`;

const ListOfItems15 = styled.div`
  display: flex;
  margin-left: 12px;
  width: 268px;
  height: 70px;
  position: relative;
  margin-top: 70px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

const ListOfItems16 = styled.img`
  position: relative;
  flex: 0 0 auto;
  margin-top: -4838px;
  margin-left: -4224px;
`;

const Divider5 = styled.img`
  margin-left: 6px;
  width: 5px;
  height: 1px;
  margin-top: 19px;
  object-fit: cover;
`;

const Pricing = styled.div`
  margin-left: 8px;
  width: 275px;
  height: 40px;
  margin-top: 59px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
`;

const Pricing1 = styled.div`
  margin-left: 7px;
  width: 275px;
  height: 40px;
  margin-top: 5px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
`;

const PricingCard11 = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 320px;
  height: 1380px;
  background-color: var(--secondarywhite01);
  border-radius: 20px;
  overflow: hidden;
`;

const Title1 = styled.div`
  ${InterBoldWoodsmoke32px}
  margin-left: 24px;
  width: 296px;
  height: 40px;
  margin-top: 24px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
`;

const Group46668 = styled.div`
  margin-left: 18px;
  width: 284px;
  height: 1265px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Group466671 = styled.div`
  ${InterBoldWoodsmoke24px}
  margin-left: 3px;
  width: 285px;
  height: 1189px;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Divider6 = styled.img`
  margin-left: -4648px;
  width: 280px;
  height: 1px;
  margin-top: -3693px;
  object-fit: cover;
`;

const Divider7 = styled.img`
  margin-left: -4649px;
  width: 280px;
  height: 1px;
  margin-top: 209px;
  object-fit: cover;
`;

const Divider8 = styled.img`
  margin-left: -4649px;
  width: 280px;
  height: 1px;
  margin-top: 160px;
  object-fit: cover;
`;

const Divider9 = styled.img`
  margin-left: -4649px;
  width: 280px;
  height: 1px;
  margin-top: 159px;
  object-fit: cover;
`;

const ListOfItems17 = styled.div`
  display: flex;
  margin-left: 6px;
  width: 268px;
  height: 70px;
  position: relative;
  margin-top: 92px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

const ListOfItems18 = styled.img`
  position: relative;
  flex: 0 0 auto;
  margin-top: -4838px;
  margin-left: -4554px;
`;

const Pricing2 = styled.div`
  margin-left: 2px;
  width: 275px;
  height: 40px;
  margin-top: 79px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
`;

const Pricing3 = styled.div`
  margin-left: 1px;
  width: 275px;
  height: 40px;
  margin-top: 5px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
`;

const Group46682 = styled.div`
  position: absolute;
  width: 699px;
  height: 8px;
  top: 1628px;
  left: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bon-jour);
  border-radius: 200px;
`;

const Frame46771 = styled.div`
  height: 8px;
  margin-left: -179px;
  width: 519.952880859375px;
  background-color: var(--primaryblue-ryb);
  border-radius: 200px;
`;

const Frame46810 = styled.div`
  display: flex;
  flex-direction: column;
  width: 698px;
  align-items: center;
  gap: 24px;
  position: absolute;
  top: 1765px;
  left: 23px;
`;

const Frame46809 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
  margin-left: -6.5px;
  margin-right: -6.5px;
`;

const Group46687 = styled.div`
  position: relative;
  width: 553px;
  height: 80px;
`;

const OverlapGroup3 = styled.div`
  position: relative;
  width: 551px;
  height: 80px;
`;

const ExperienceInActiN = styled.h1`
  position: absolute;
  top: 0;
  left: 0;
  font-family: var(--font-family-poppins);
  font-weight: 700;
  color: var(--primaryblue-ryb);
  font-size: 48px;
  text-align: center;
  letter-spacing: 0;
  line-height: 80px;
  white-space: nowrap;
`;

const Image116 = styled.img`
  position: absolute;
  width: 65px;
  height: 27px;
  top: 30px;
  left: 437px;
  object-fit: cover;
`;

const WithToolsToMakeE = styled.p`
  position: relative;
  width: 711px;
  font-family: var(--font-family-poppins);
  font-weight: 400;
  color: var(--secondaryoxford-black02);
  font-size: var(--font-size-m);
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
`;

const Frame46712 = styled.div`
  position: absolute;
  width: 744px;
  height: 68px;
  top: 2114px;
  left: 0;
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

const Ellipse49 = styled.div`
  margin-top: 19px;
  width: 18857px;
  height: 18857px;
  margin-left: -1px;
  background-color: var(--primaryblue-ryb);
  border-radius: 9428.5px;
`;

export default TabletSmall;
