import React from "react";
import styled from "styled-components";
import { PoppinsLightBlueRibbon12px, PoppinsBoldBlueRibbon20px } from "../../styledMixins";


function Frame66172(props) {
  const { brandverse } = props;

  return (
    <Frame6617>
      <Brandverse>{brandverse}</Brandverse>
      <LoremIpsumDolorSi>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna
      </LoremIpsumDolorSi>
    </Frame6617>
  );
}

const Frame6617 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
`;

const Brandverse = styled.div`
  ${PoppinsBoldBlueRibbon20px}
  position: relative;
  align-self: stretch;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const LoremIpsumDolorSi = styled.p`
  ${PoppinsLightBlueRibbon12px}
  position: relative;
  align-self: stretch;
  letter-spacing: 0;
  line-height: 16.9px;
`;

export default Frame66172;
