import React from "react";
import List4 from "../List4";
import styled from "styled-components";
import { InterNormalWhite22px } from "../../styledMixins";


function ListOfItems6() {
  return (
    <ListOfItems>
      <Text></Text>
      <ListOfItems1 src="/img/bxs-check-circle--2--1-17@2x.png" alt="List of Items" />
      <List4 />
      <ListOfItems1 src="/img/bxs-check-circle--2--1-17@2x.png" alt="List of Items" />
    </ListOfItems>
  );
}

const ListOfItems = styled.div`
  display: flex;
  margin-left: 12px;
  width: 268px;
  height: 169px;
  position: relative;
  margin-top: 12px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

const Text = styled.div`
  ${InterNormalWhite22px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
`;

const ListOfItems1 = styled.img`
  position: relative;
  flex: 0 0 auto;
`;

const ListOfItems2 = styled.div`
  display: flex;
  margin-left: 12px;
  width: 268px;
  height: 169px;
  position: relative;
  margin-top: 12px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

const Text1 = styled.div`
  ${InterNormalWhite22px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
`;

const ListOfItems3 = styled.img`
  position: relative;
  flex: 0 0 auto;
`;

export default ListOfItems6;
