import React from "react";
import styled from "styled-components";
import { InterSemiBoldBlueRibbon16px } from "../../styledMixins";


function PrimaryButton3(props) {
  const { className } = props;

  return (
    <PrimaryButton className={`primary-button-4 hidden ${className || ""}`}>
      <ButtonLabel className="button-label-2">Choose</ButtonLabel>
    </PrimaryButton>
  );
}

const PrimaryButton = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  width: 100%;
  height: 48px;
  background-color: var(--secondaryvivid-sky-blue04);
  border-radius: 12px;
  overflow: hidden;
  opacity: 0;

  &.primary-button-4.primary-button-5 {
    opacity: unset;
  }
`;

const ButtonLabel = styled.div`
  ${InterSemiBoldBlueRibbon16px}
  height: 40px;
  width: 60px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
  white-space: nowrap;
`;

export default PrimaryButton3;
