import React from "react";
import styled from "styled-components";
import {
  PoppinsBoldBlueRibbon24px,
  ValignTextMiddle,
  PoppinsLightZircon16px,
  PoppinsBoldZircon24px,
} from "../../styledMixins";


function Frame6607(props) {
  const { group46630, className } = props;

  return (
    <Frame66071 className={`frame-6607 ${className || ""}`}>
      <Group46630 className="group-46630" style={{ backgroundImage: `url(${group46630})` }}></Group46630>
      <Frame6606 className="frame-6606-4">
        <Frame46671 className="frame-46671-3">
          <Number className="number-3">05</Number>
          <OmniChannelCommerce className="omni-channel-commerce">Omni Channel Commerce</OmniChannelCommerce>
        </Frame46671>
        <OmniChannelCommerc className="omni-channel-commerc">
          Omni-channel commerce, integrated LINE OA, and streamlined management for web commerce, products, orders, and
          coupons.
        </OmniChannelCommerc>
      </Frame6606>
    </Frame66071>
  );
}

const Frame66071 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
`;

const Group46630 = styled.div`
  position: relative;
  width: 48px;
  height: 48px;
  background-size: 100% 100%;
`;

const Frame6606 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  flex: 0 0 auto;
`;

const Frame46671 = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
`;

const Number = styled.div`
  ${ValignTextMiddle}
  ${PoppinsBoldBlueRibbon24px}
            position: relative;
  width: 33px;
  height: 52px;
  margin-top: -2px;
  margin-left: -1px;
  -webkit-text-stroke: 1px var(--primaryghost-white);
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const OmniChannelCommerce = styled.div`
  ${ValignTextMiddle}
  ${PoppinsBoldZircon24px}
            position: absolute;
  top: -4px;
  left: 37px;
  height: 64px;
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const OmniChannelCommerc = styled.p`
  ${PoppinsLightZircon16px}
  position: relative;
  width: 405px;
  letter-spacing: 0;
  line-height: 24px;
`;

export default Frame6607;
