import React from "react";
import styled from "styled-components";
import { PoppinsBoldBlueRibbon64px } from "../../styledMixins";


function Group46672() {
  return (
    <Group466721>
      <OverlapGroup>
        <ExperienceInActiN>Experience in acti&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; n.</ExperienceInActiN>
        <Image116 src="/img/image-116@2x.png" alt="image 116" />
      </OverlapGroup>
    </Group466721>
  );
}

const Group466721 = styled.div`
  position: relative;
  width: 737px;
  height: 80px;
`;

const OverlapGroup = styled.div`
  position: relative;
  width: 735px;
  height: 80px;
`;

const ExperienceInActiN = styled.p`
  ${PoppinsBoldBlueRibbon64px}
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  letter-spacing: 0;
  line-height: 80px;
  white-space: nowrap;
`;

const Image116 = styled.img`
  position: absolute;
  width: 87px;
  height: 36px;
  top: 26px;
  left: 584px;
  object-fit: cover;
`;

const Group466722 = styled.div`
  position: relative;
  width: 737px;
  height: 80px;
`;

const OverlapGroup1 = styled.div`
  position: relative;
  width: 735px;
  height: 80px;
`;

const ExperienceInActiN1 = styled.p`
  ${PoppinsBoldBlueRibbon64px}
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  letter-spacing: 0;
  line-height: 80px;
  white-space: nowrap;
`;

const Image1161 = styled.img`
  position: absolute;
  width: 87px;
  height: 36px;
  top: 26px;
  left: 584px;
  object-fit: cover;
`;

export default Group46672;
