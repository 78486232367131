import React from "react";
import Frame46644 from "../Frame46644";
import Frame46813 from "../Frame46813";
import Frame46670 from "../Frame46670";
import Frame46646 from "../Frame46646";
import Frame6605 from "../Frame6605";
import Frame6606 from "../Frame6606";
import Frame66052 from "../Frame66052";
import Frame6607 from "../Frame6607";
import Group46600 from "../Group46600";
import Frame46675 from "../Frame46675";
import Group46663 from "../Group46663";
import Frame46703 from "../Frame46703";
import Brandverse from "../Brandverse";
import HappyWork from "../HappyWork";
import Frame6622 from "../Frame6622";
import Frame46707 from "../Frame46707";
import Frame66222 from "../Frame66222";
import PricingCard4 from "../PricingCard4";
import Group46668 from "../Group46668";
import ListOfItems4 from "../ListOfItems4";
import ListOfItems3 from "../ListOfItems3";
import ListOfItems5 from "../ListOfItems5";
import ListOfItems6 from "../ListOfItems6";
import ListOfItems7 from "../ListOfItems7";
import PrimaryButton2 from "../PrimaryButton2";
import ListOfItems8 from "../ListOfItems8";
import ListOfItems9 from "../ListOfItems9";
import ListOfItems10 from "../ListOfItems10";
import ListOfItems11 from "../ListOfItems11";
import ListOfItems12 from "../ListOfItems12";
import List6 from "../List6";
import PrimaryButton from "../PrimaryButton";
import Group46672 from "../Group46672";
import BigButton from "../BigButton";
import styled from "styled-components";
import {
  PoppinsBoldWhite64px,
  PoppinsNormalZircon16px,
  InterBoldWoodsmoke32px,
  PoppinsNormalMulledWine16px,
  InterBoldWhite32px,
  InterBoldZircon24px,
  InterBoldWoodsmoke24px,
  PoppinsSemiBoldWhite16px,
  PoppinsNormalEbonyClay20px,
} from "../../styledMixins";
import "./Desktop.css";

function Desktop(props) {
  const {
    jnzMktCloudLogo,
    image107,
    image108,
    image110,
    image109,
    cloud1,
    overlapGroup1,
    group465891,
    overlapGroup,
    group465892,
    x02_F3_14,
    x03_M1_31,
    image1201,
    bg,
    cloud2,
    vector11,
    vector12,
    vector13,
    vector14,
    enjoyASeamlessCha,
    experienceIntegrate,
    image119,
    image105,
    group,
    spanText1,
    spanText2,
    spanText3,
    image114,
    spanText4,
    spanText5,
    spanText6,
    spanText7,
    spanText8,
    image115,
    maskGroup1,
    maskGroup2,
    frame46635,
    image121,
    image1202,
    copyright2023JenosizeTechnology,
    vector15,
    vector16,
    vector17,
    vector18,
    cloud3,
    title1,
    title2,
    divider1,
    divider2,
    divider3,
    divider4,
    listOfItems1,
    divider5,
    divider6,
    pricing1,
    pricing2,
    title3,
    divider7,
    divider8,
    divider9,
    divider10,
    divider11,
    listOfItems2,
    divider12,
    pricing3,
    pricing4,
    withToolsToMakeE,
    image122,
    frame6605Props,
    frame66061Props,
    frame66062Props,
    frame6607Props,
    frame66063Props,
    group466631Props,
    group466632Props,
    group466633Props,
    group466634Props,
    brandverseProps,
    happyWorkProps,
    frame46707Props,
    pricingCard4Props,
    group46668Props,
    listOfItems3Props,
    bigButtonProps,
  } = props;

  return (
    <div className="desktop screen">
      <Frame46641>
        <Frame46645>
          <JnzMktCloudLogo src={jnzMktCloudLogo} alt="jnz-mkt-cloud-logo" />
          <Frame46644 />
        </Frame46645>
      </Frame46641>
      <X1>
        <Group46669>
          <ImageContainer>
            <Image107 src={image107} alt="image 107" />
            <Image108 src={image108} alt="image 108" />
            <Image110 src={image110} alt="image 110" />
            <Image109 src={image109} alt="image 109" />
          </ImageContainer>
        </Group46669>
        <Cloud src={cloud1} alt="Cloud" />
        <Ellipse48></Ellipse48>
        <Mobile>
          <OverlapGroup1 style={{ backgroundImage: `url(${overlapGroup1})` }}>
            <Group46589 src={group465891} alt="Group 46589" />
          </OverlapGroup1>
        </Mobile>
        <Frame46813 />
      </X1>
      <X2>
        <Content>
          <Mobile1>
            <OverlapGroup style={{ backgroundImage: `url(${overlapGroup})` }}>
              <Group465891 src={group465892} alt="Group 46589" />
            </OverlapGroup>
          </Mobile1>
          <Frame46670 />
          <Frame46816>
            <X02F314 src={x02_F3_14} alt="02_F3_1 4" />
            <X02F314 src={x03_M1_31} alt="03_M1_3 1" />
          </Frame46816>
          <Image120 src={image1201} alt="image 120" />
          <BG src={bg} alt="BG" />
        </Content>
      </X2>
      <X3>
        <Content1>
          <Frame46694>
            <Frame46646 />
            <Frame46693>
              <Frame46691>
                <Frame6605 group46631={frame6605Props.group46631} />
                <Frame6606
                  discount={frame66061Props.discount}
                  number={frame66061Props.number}
                  crm={frame66061Props.crm}
                  enhanceSalesEffici={frame66061Props.enhanceSalesEffici}
                />
                <Frame6606
                  discount={frame66062Props.discount}
                  number={frame66062Props.number}
                  crm={frame66062Props.crm}
                  enhanceSalesEffici={frame66062Props.enhanceSalesEffici}
                />
              </Frame46691>
              <Frame46691>
                <Frame66052 />
                <Frame6607 group46630={frame6607Props.group46630} />
                <Frame6606
                  discount={frame66063Props.discount}
                  number={frame66063Props.number}
                  crm={frame66063Props.crm}
                  enhanceSalesEffici={frame66063Props.enhanceSalesEffici}
                />
              </Frame46691>
            </Frame46693>
          </Frame46694>
        </Content1>
        <Cloud1 src={cloud2} alt="Cloud" />
        <BG1>
          <Group46670>
            <Group46617>
              <OverlapGroup2>
                <Vector1 src={vector11} alt="Vector 1" />
                <Ellipse28></Ellipse28>
              </OverlapGroup2>
            </Group46617>
            <Group46620>
              <OverlapGroup2>
                <Vector1 src={vector12} alt="Vector 1" />
                <Ellipse281></Ellipse281>
              </OverlapGroup2>
            </Group46620>
            <Group46620>
              <OverlapGroup2>
                <Vector1 src={vector13} alt="Vector 1" />
                <Ellipse282></Ellipse282>
              </OverlapGroup2>
            </Group46620>
            <Group46618>
              <OverlapGroup2>
                <Vector1 src={vector14} alt="Vector 1" />
                <Ellipse29></Ellipse29>
              </OverlapGroup2>
            </Group46618>
          </Group46670>
        </BG1>
      </X3>
      <X4>
        <Content2>
          <Group46600 />
          <Frame46819>
            <Frame46818>
              <Frame46675 />
              <EnjoyASeamlessCha>{enjoyASeamlessCha}</EnjoyASeamlessCha>
            </Frame46818>
            <Frame46817>
              <Group46657>
                <Group46663
                  simplifyShoppingExperienceWithChat={group466631Props.simplifyShoppingExperienceWithChat}
                  text1={group466631Props.text1}
                />
              </Group46657>
              <Group46663
                simplifyShoppingExperienceWithChat={group466632Props.simplifyShoppingExperienceWithChat}
                text1={group466632Props.text1}
                className={group466632Props.className}
              />
              <Group46663
                simplifyShoppingExperienceWithChat={group466633Props.simplifyShoppingExperienceWithChat}
                text1={group466633Props.text1}
                className={group466633Props.className}
              />
              <Group46665>
                <OverlapGroup3>
                  <Rectangle13317></Rectangle13317>
                  <ExperienceIntegrate>{experienceIntegrate}</ExperienceIntegrate>
                </OverlapGroup3>
              </Group46665>
              <Group46663
                simplifyShoppingExperienceWithChat={group466634Props.simplifyShoppingExperienceWithChat}
                text1={group466634Props.text1}
                className={group466634Props.className}
              />
            </Frame46817>
          </Frame46819>
        </Content2>
        <Screen>
          <Screen1>
            <Group46675>
              <ImageContainer1>
                <Image119 src={image119} alt="image 119" />
                <Image105 src={image105} alt="image 105" />
              </ImageContainer1>
            </Group46675>
          </Screen1>
        </Screen>
      </X4>
      <X4>
        <Content>
          <FlexCol>
            <Group src={group} alt="Group" />
            <Frame46706>
              <Frame46693>
                <Frame46704>
                  <Group466701>
                    <OverlapGroup4>
                      <WhatWeProvideToT>
                        <span className="poppins-bold-eerie-black-64px">{spanText1}</span>
                        <span className="poppins-bold-blaze-orange-64px">{spanText2}</span>
                        <span className="poppins-bold-eerie-black-64px">{spanText3}</span>
                      </WhatWeProvideToT>
                      <Image114 src={image114} alt="image 114" />
                    </OverlapGroup4>
                  </Group466701>
                  <Frame46703 />
                </Frame46704>
                <Group46671>
                  <OverlapGroup11>
                    <JenosizeTechnology>
                      <span className="poppins-semi-bold-blue-ribbon-16px">{spanText4}</span>
                      <span className="poppins-light-mulled-wine-16px">{spanText5}</span>
                      <span className="poppins-semi-bold-blaze-orange-16px">{spanText6}</span>
                      <span className="poppins-light-mulled-wine-16px">{spanText7}</span>
                      <span className="poppins-semi-bold-blaze-orange-16px">{spanText8}</span>
                    </JenosizeTechnology>
                    <Image115 src={image115} alt="image 115" />
                  </OverlapGroup11>
                </Group46671>
              </Frame46693>
              <Frame46691>
                <Brandverse
                  brandverseLogo1={brandverseProps.brandverseLogo1}
                  brandverseLogo2={brandverseProps.brandverseLogo2}
                  group46599={brandverseProps.group46599}
                  group46589={brandverseProps.group46589}
                  frame6617Props={brandverseProps.frame6617Props}
                />
                <HappyWork
                  group46609={happyWorkProps.group46609}
                  group46610={happyWorkProps.group46610}
                  group46599={happyWorkProps.group46599}
                  group46589={happyWorkProps.group46589}
                  frame6621Props={happyWorkProps.frame6621Props}
                />
              </Frame46691>
            </Frame46706>
            <Frame46702>
              <Group46596>
                <OverlapGroup5>
                  <MaskGroup src={maskGroup1} alt="Mask group" />
                  <MaskGroup1 src={maskGroup2} alt="Mask group" />
                  <Ellipse25></Ellipse25>
                </OverlapGroup5>
              </Group46596>
            </Frame46702>
          </FlexCol>
        </Content>
      </X4>
      <X6>
        <Content>
          <OURCUSTOMERS>
            <Frame6622 />
            <Frame46635 src={frame46635} alt="Frame 46635" />
          </OURCUSTOMERS>
          <ImageContainer2>
            <Image121 src={image121} alt="image 121" />
            <Image1201 src={image1202} alt="image 120" />
          </ImageContainer2>
        </Content>
      </X6>
      <X6>
        <X7>
          <OverlapGroup7>
            <Frame46708>
              <Frame46707 frame466781Props={frame46707Props.frame466781Props} />
              <Frame46625>
                <Copyright2023JenosizeTechnology>{copyright2023JenosizeTechnology}</Copyright2023JenosizeTechnology>
              </Frame46625>
            </Frame46708>
            <Group466171>
              <OverlapGroup41>
                <Vector11 src={vector15} alt="Vector 1" />
                <Ellipse283></Ellipse283>
              </OverlapGroup41>
              <Ellipse291></Ellipse291>
            </Group466171>
            <OverlapGroup51>
              <Vector11 src={vector16} alt="Vector 1" />
              <Ellipse29></Ellipse29>
            </OverlapGroup51>
            <OverlapGroup6>
              <Vector11 src={vector17} alt="Vector 1" />
              <Ellipse282></Ellipse282>
            </OverlapGroup6>
            <Group466201>
              <Vector12 src={vector18} alt="Vector 1" />
              <Ellipse284></Ellipse284>
            </Group466201>
            <Cloud2 src={cloud3} alt="Cloud" />
          </OverlapGroup7>
        </X7>
      </X6>
      <X8>
        <X81>
          <FlexCol1>
            <Frame66222 />
            <OverlapGroup12>
              <Shadow></Shadow>
              <Shadow1></Shadow1>
              <Frame46710>
                <PricingCard4 {...pricingCard4Props} />
                <PricingCard01>
                  <Title>{title1}</Title>
                  <Group46668 listOfItems3Props={group46668Props.listOfItems3Props} />
                </PricingCard01>
                <PricingCard03>
                  <CardContainer>
                    <ShadowCard></ShadowCard>
                    <PricingCard>
                      <Title1>{title2}</Title1>
                      <Group46667>
                        <ListOfItems4 />
                        <Divider src={divider1} alt="Divider" />
                        <ListOfItems3
                          listOfItems1={listOfItems3Props.listOfItems1}
                          listOfItems2={listOfItems3Props.listOfItems2}
                          listOfItems3={listOfItems3Props.listOfItems3}
                          className={listOfItems3Props.className}
                        />
                        <Divider1 src={divider2} alt="Divider" />
                        <ListOfItems5 />
                        <Divider2 src={divider3} alt="Divider" />
                        <ListOfItems6 />
                        <Divider3 src={divider4} alt="Divider" />
                        <ListOfItems src={listOfItems1} alt="List of Items" />
                        <Divider4 src={divider5} alt="Divider" />
                        <ListOfItems7 />
                        <Divider5 src={divider6} alt="Divider" />
                        <Pricing>{pricing1}</Pricing>
                        <Pricing1>{pricing2}</Pricing1>
                      </Group46667>
                      <PrimaryButton2 />
                    </PricingCard>
                  </CardContainer>
                </PricingCard03>
                <PricingCard02>
                  <Title2>{title3}</Title2>
                  <Group466681>
                    <Group466671>
                      <ListOfItems8 />
                      <Divider6 src={divider7} alt="Divider" />
                      <ListOfItems9 />
                      <Divider7 src={divider8} alt="Divider" />
                      <ListOfItems10 />
                      <Divider7 src={divider9} alt="Divider" />
                      <ListOfItems11 />
                      <Divider7 src={divider10} alt="Divider" />
                      <ListOfItems12 />
                      <Divider8 src={divider11} alt="Divider" />
                      <ListOfItems1>
                        <Frame46635 src={listOfItems2} alt="List of Items" />
                        <List6 />
                      </ListOfItems1>
                      <Divider9 src={divider12} alt="Divider" />
                      <Pricing2>{pricing3}</Pricing2>
                      <Pricing3>{pricing4}</Pricing3>
                    </Group466671>
                    <PrimaryButton />
                  </Group466681>
                </PricingCard02>
              </Frame46710>
            </OverlapGroup12>
            <Frame46714>
              <Frame46713>
                <Group46672 />
                <WithToolsToMakeE>{withToolsToMakeE}</WithToolsToMakeE>
              </Frame46713>
              <BigButton>{bigButtonProps.children}</BigButton>
            </Frame46714>
            <Image122 src={image122} alt="image 122" />
          </FlexCol1>
        </X81>
      </X8>
    </div>
  );
}

const Frame46641 = styled.div`
  display: flex;
  width: 1440px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 24px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--primaryblue-ryb);
`;

const Frame46645 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  flex: 1;
  flex-grow: 1;
`;

const JnzMktCloudLogo = styled.img`
  position: relative;
  width: 150.30303955078125px;
  height: 40px;
`;

const X1 = styled.div`
  display: flex;
  height: 800px;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  align-self: stretch;
  width: 100%;
  background-color: var(--primaryblue-ryb);
  overflow: hidden;
`;

const Group46669 = styled.div`
  position: absolute;
  top: calc(50% + 141px);
  left: calc(50% - 671px);
  width: 1343px;
  height: 259px;
`;

const ImageContainer = styled.div`
  position: relative;
  height: 259px;
`;

const Image107 = styled.img`
  position: absolute;
  width: 349px;
  height: 195px;
  top: 64px;
  left: 0;
  object-fit: cover;
`;

const Image108 = styled.img`
  position: absolute;
  width: 463px;
  height: 259px;
  top: 0;
  left: 209px;
  object-fit: cover;
`;

const Image110 = styled.img`
  position: absolute;
  width: 349px;
  height: 195px;
  top: 64px;
  left: 994px;
  object-fit: cover;
`;

const Image109 = styled.img`
  position: absolute;
  width: 463px;
  height: 259px;
  top: 0;
  left: 670px;
  object-fit: cover;
`;

const Cloud = styled.img`
  position: absolute;
  width: 100%;
  height: 24%;
  top: 76%;
  left: 0;
  object-fit: cover;
`;

const Ellipse48 = styled.div`
  position: relative;
  width: 1365.0001220703125px;
  height: 1364.9998779296875px;
  margin-bottom: -565px;
  border-radius: 682.5px;
  transform: rotate(-180deg);
  background: linear-gradient(
    180deg,
    rgb(244.0000006556511, 246.0000005364418, 255) 0%,
    rgba(244.0000006556511, 246.0000005364418, 255, 0) 100%
  );
  opacity: 0.3;
`;

const Mobile = styled.div`
  position: absolute;
  top: calc(50% - 19px);
  left: calc(50% - 249px);
  width: 498px;
  height: 838px;
`;

const OverlapGroup1 = styled.div`
  height: 419px;
  display: flex;
  padding: 0 86.7px;
  justify-content: flex-end;
  align-items: flex-end;
  min-width: 498px;
  background-size: cover;
  background-position: 50% 50%;
`;

const Group46589 = styled.img`
  width: 324px;
  height: 352px;
`;

const X2 = styled.div`
  display: flex;
  height: 800px;
  align-items: flex-start;
  position: relative;
  align-self: stretch;
  width: 100%;
  background-color: var(--secondarywhite01);
`;

const Content = styled.div`
  position: relative;
  flex: 1;
  align-self: stretch;
  flex-grow: 1;
  background-color: var(--secondarywhite01);
`;

const Mobile1 = styled.div`
  position: absolute;
  width: 500px;
  height: 840px;
  top: -420px;
  left: 470px;
`;

const OverlapGroup = styled.div`
  position: relative;
  height: 419px;
  top: 420px;
  left: 1px;
  display: flex;
  padding: 0 86.7px;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 498px;
  background-size: cover;
  background-position: 50% 50%;
`;

const Group465891 = styled.img`
  width: 324px;
  height: 354px;
`;

const Frame46816 = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 64px;
  position: absolute;
  top: 25px;
  left: 299px;
`;

const X02F314 = styled.img`
  position: relative;
  width: 400px;
  height: 400px;
  object-fit: cover;
`;

const Image120 = styled.img`
  position: absolute;
  width: 316px;
  height: 109px;
  top: 691px;
  left: 0;
  object-fit: cover;
`;

const BG = styled.img`
  position: absolute;
  width: 1440px;
  height: 800px;
  top: 0;
  left: 0;
`;

const X3 = styled.div`
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 800px;
  background-color: var(--primaryblue-ryb);
`;

const Content1 = styled.div`
  position: absolute;
  width: 1440px;
  height: 553px;
  top: 92px;
  left: 0;
  display: flex;
  justify-content: center;
`;

const Frame46694 = styled.div`
  display: flex;
  width: 1279px;
  height: 553px;
  margin-left: -1px;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

const Frame46693 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  position: relative;
  flex: 0 0 auto;
`;

const Frame46691 = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 32px;
  position: relative;
  flex: 0 0 auto;
`;

const Cloud1 = styled.img`
  position: absolute;
  width: 1440px;
  height: 140px;
  top: 660px;
  left: 0;
  object-fit: cover;
`;

const BG1 = styled.div`
  position: absolute;
  width: 1440px;
  height: 800px;
  top: 0;
  left: 0;
  overflow: hidden;
`;

const Group46670 = styled.div`
  position: relative;
  width: 1295px;
  left: 73px;
  display: flex;
`;

const Group46617 = styled.div`
  height: 932px;
  display: flex;
  align-items: flex-start;
  min-width: 8px;
`;

const OverlapGroup2 = styled.div`
  width: 8px;
  height: 800px;
  position: relative;
`;

const Vector1 = styled.img`
  position: absolute;
  width: 1px;
  height: 800px;
  top: 0;
  left: 4px;
`;

const Ellipse28 = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  top: 84px;
  left: 0;
  background-color: var(--secondarywhite01);
  border-radius: 4px;
  box-shadow: 0px 0px 12px #ffffff;
`;

const Group46620 = styled.div`
  height: 932px;
  margin-left: 429px;
  display: flex;
  align-items: flex-start;
  min-width: 8px;
`;

const Ellipse281 = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  top: 730px;
  left: 0;
  background-color: var(--secondarywhite01);
  border-radius: 4px;
  box-shadow: 0px 0px 12px #ffffff;
`;

const Ellipse282 = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  top: 256px;
  left: 0;
  background-color: var(--secondarywhite01);
  border-radius: 4px;
  box-shadow: 0px 0px 12px #ffffff;
`;

const Group46618 = styled.div`
  height: 932px;
  margin-left: 405px;
  display: flex;
  align-items: flex-start;
  min-width: 8px;
`;

const Ellipse29 = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  top: 578px;
  left: 0;
  background-color: var(--secondarywhite01);
  border-radius: 4px;
  box-shadow: 0px 0px 12px #ffffff;
`;

const X4 = styled.div`
  display: flex;
  height: 800px;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  align-self: stretch;
  width: 100%;
  background-color: var(--secondarywhite01);
`;

const Content2 = styled.div`
  position: relative;
  display: flex;
  gap: 99px;
  flex: 1;
  align-self: stretch;
  flex-grow: 1;
`;

const Frame46819 = styled.div`
  display: flex;
  height: 562px;
  width: 511px;
  align-self: center;
  position: relative;
  margin-right: 30px;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

const Frame46818 = styled.div`
  display: flex;
  flex-direction: column;
  width: 485px;
  align-items: flex-start;
  gap: 20px;
  position: relative;
  flex: 0 0 auto;
`;

const EnjoyASeamlessCha = styled.p`
  ${PoppinsNormalMulledWine16px}
  position: relative;
  width: 475px;
  letter-spacing: 0;
  line-height: 22.5px;
`;

const Frame46817 = styled.div`
  display: flex;
  flex-direction: column;
  width: 475px;
  align-items: flex-start;
  position: relative;
  flex: 0 0 auto;
`;

const Group46657 = styled.div`
  position: relative;
  display: flex;
  width: 475px;
  height: 60px;
`;

const Group46665 = styled.div`
  position: relative;
  width: 487px;
  height: 120px;
  margin-right: -12px;
`;

const OverlapGroup3 = styled.div`
  position: relative;
  width: 485px;
  height: 120px;
`;

const Rectangle13317 = styled.div`
  position: absolute;
  width: 475px;
  height: 120px;
  top: 0;
  left: 0;
  background-color: var(--secondarywhite01);
  border-top-width: 1px;
  border-top-style: solid;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: var(--black-3);
`;

const ExperienceIntegrate = styled.p`
  ${PoppinsNormalMulledWine16px}
  position: absolute;
  width: 475px;
  top: 27px;
  left: 10px;
  letter-spacing: 0;
  line-height: 22.5px;
`;

const Screen = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  flex: 1;
  align-self: stretch;
  flex-grow: 1;
`;

const Screen1 = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 627px;
  height: 800px;
  overflow: hidden;
`;

const Group46675 = styled.div`
  margin-right: -421px;
  display: flex;
  align-items: flex-start;
  min-width: 1048px;
`;

const ImageContainer1 = styled.div`
  width: 627px;
  height: 800px;
  position: relative;
`;

const Image119 = styled.img`
  position: absolute;
  width: 528px;
  height: 800px;
  top: 0;
  left: 99px;
  object-fit: cover;
`;

const Image105 = styled.img`
  position: absolute;
  width: 627px;
  height: 549px;
  top: 127px;
  left: 0;
  object-fit: cover;
`;

const FlexCol = styled.div`
  position: relative;
  width: 1440px;
  top: -2208px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 3008px;
`;

const Group = styled.img`
  width: 199px;
  height: 210px;
  margin-left: 25.6px;
`;

const Frame46706 = styled.div`
  display: inline-flex;
  position: relative;
  align-self: center;
  margin-top: 2012px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

const Frame46704 = styled.div`
  display: inline-flex;
  align-items: flex-end;
  gap: 362px;
  position: relative;
  flex: 0 0 auto;
`;

const Group466701 = styled.div`
  position: relative;
  width: 843px;
  height: 222px;
`;

const OverlapGroup4 = styled.div`
  position: relative;
  width: 841px;
  height: 222px;
`;

const WhatWeProvideToT = styled.h1`
  ${PoppinsBoldWhite64px}
  position: absolute;
  width: 841px;
  top: 0;
  left: 0;
  letter-spacing: 0;
  line-height: 80px;
`;

const Image114 = styled.img`
  position: absolute;
  width: 87px;
  height: 36px;
  top: 186px;
  left: 182px;
  object-fit: cover;
`;

const Group46671 = styled.div`
  position: relative;
  width: 1282px;
  height: 68px;
  margin-right: -2px;
`;

const OverlapGroup11 = styled.div`
  position: relative;
  width: 1280px;
  height: 68px;
`;

const JenosizeTechnology = styled.p`
  ${PoppinsSemiBoldWhite16px}
  position: absolute;
  width: 1280px;
  top: 0;
  left: 0;
  letter-spacing: 0;
  line-height: 25.6px;
`;

const Image115 = styled.img`
  position: absolute;
  width: 43px;
  height: 9px;
  top: 59px;
  left: 869px;
  object-fit: cover;
`;

const Frame46702 = styled.div`
  width: 1440px;
  height: 140px;
  display: flex;
  overflow: hidden;
  background: linear-gradient(
    180deg,
    rgba(0, 66.00000366568565, 237.0000010728836, 0) 0%,
    rgb(0, 66.00000366568565, 237.0000010728836) 100%
  );
`;

const Group46596 = styled.div`
  display: flex;
  align-items: flex-start;
  min-width: 1440px;
`;

const OverlapGroup5 = styled.div`
  width: 1440px;
  height: 195px;
  position: relative;
`;

const MaskGroup = styled.img`
  position: absolute;
  width: 704px;
  height: 140px;
  top: 0;
  left: 0;
`;

const MaskGroup1 = styled.img`
  position: absolute;
  width: 704px;
  height: 140px;
  top: 0;
  left: 736px;
`;

const Ellipse25 = styled.div`
  position: absolute;
  width: 67px;
  height: 67px;
  top: 128px;
  left: 686px;
  background-color: var(--secondarywhite01);
  border-radius: 33.5px;
`;

const X6 = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  background-color: var(--secondarywhite01);
`;

const OURCUSTOMERS = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  position: absolute;
  top: 40px;
  left: 189px;
`;

const Frame46635 = styled.img`
  position: relative;
  flex: 0 0 auto;
`;

const ImageContainer2 = styled.div`
  position: absolute;
  height: 109px;
  top: 295px;
  left: 0;
  display: flex;
  align-items: flex-start;
  min-width: 1440px;
  gap: 857px;
`;

const Image121 = styled.img`
  width: 267px;
  height: 109px;
  object-fit: cover;
`;

const Image1201 = styled.img`
  width: 316px;
  height: 109px;
  object-fit: cover;
`;

const X7 = styled.div`
  position: relative;
  flex: 1;
  align-self: stretch;
  flex-grow: 1;
  background-color: var(--primaryblue-ryb);
  overflow: hidden;
`;

const OverlapGroup7 = styled.div`
  position: relative;
  width: 2237px;
  height: 932px;
`;

const Frame46708 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  top: 86px;
  left: 81px;
`;

const Frame46625 = styled.div`
  display: flex;
  width: 1286px;
  height: 80px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0px 10px;
  position: relative;
`;

const Copyright2023JenosizeTechnology = styled.div`
  ${PoppinsNormalZircon16px}
  position: relative;
  width: fit-content;
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const Group466171 = styled.div`
  position: absolute;
  height: 932px;
  top: 0;
  left: 73px;
  display: flex;
  align-items: flex-start;
  min-width: 1303px;
  gap: 1287px;
`;

const OverlapGroup41 = styled.div`
  width: 8px;
  height: 606px;
  position: relative;
`;

const Vector11 = styled.img`
  position: absolute;
  width: 1px;
  height: 606px;
  top: 0;
  left: 4px;
`;

const Ellipse283 = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  top: 57px;
  left: 0;
  background-color: var(--secondarywhite01);
  border-radius: 4px;
  box-shadow: 0px 0px 12px #ffffff;
`;

const Ellipse291 = styled.div`
  width: 8px;
  height: 8px;
  margin-top: 157px;
  background-color: var(--secondarywhite01);
  border-radius: 4px;
  box-shadow: 0px 0px 12px #ffffff;
`;

const OverlapGroup51 = styled.div`
  position: absolute;
  width: 8px;
  height: 606px;
  top: 0;
  left: 1360px;
`;

const OverlapGroup6 = styled.div`
  position: absolute;
  width: 8px;
  height: 606px;
  top: 0;
  left: 947px;
`;

const Group466201 = styled.div`
  position: absolute;
  width: 8px;
  height: 932px;
  top: 0;
  left: 510px;
  display: flex;
  flex-direction: column;
  gap: 124px;
`;

const Vector12 = styled.img`
  margin-left: 3.5px;
  width: 1px;
  height: 606px;
`;

const Ellipse284 = styled.div`
  height: 8px;
  background-color: var(--secondarywhite01);
  border-radius: 4px;
  box-shadow: 0px 0px 12px #ffffff;
`;

const Cloud2 = styled.img`
  position: absolute;
  width: 1440px;
  height: 140px;
  top: 466px;
  left: 0;
  object-fit: cover;
`;

const X8 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  background-color: var(--secondarywhite01);
`;

const X81 = styled.div`
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 2348px;
  background-color: var(--secondarywhite01);
`;

const FlexCol1 = styled.div`
  position: relative;
  width: 1440px;
  top: 93px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 2255px;
`;

const OverlapGroup12 = styled.div`
  width: 1339px;
  height: 1406px;
  position: relative;
  margin-top: 80px;
  margin-left: 1px;
`;

const Shadow = styled.div`
  position: absolute;
  width: 1231px;
  height: 1380px;
  top: 0;
  left: 54px;
  background-color: var(--blue-zodiac);
  filter: blur(200px);
  opacity: 0.05;
`;

const Shadow1 = styled.div`
  position: absolute;
  width: 1081px;
  height: 91px;
  top: 1315px;
  left: 129px;
  background-color: var(--blue-zodiac);
  filter: blur(200px);
  opacity: 0.1;
`;

const Frame46710 = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 13px;
  position: absolute;
  top: 0;
  left: 0;
`;

const PricingCard01 = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 320px;
  height: 1380px;
  background-color: var(--secondarywhite01);
`;

const Title = styled.div`
  ${InterBoldWoodsmoke32px}
  margin-left: 15px;
  width: 281px;
  height: 40px;
  margin-top: 24px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
`;

const PricingCard03 = styled.div`
  position: relative;
  width: 320px;
  height: 1380px;
  background-color: var(--secondarywhite01);
`;

const CardContainer = styled.div`
  position: relative;
  width: 374px;
  height: 1380px;
  top: -24px;
  left: 10px;
`;

const ShadowCard = styled.div`
  position: absolute;
  width: 336px;
  height: 27px;
  top: 481px;
  left: 38px;
  background-color: #19135d;
  filter: blur(200px); ;
`;

const PricingCard = styled.div`
  position: absolute;
  width: 300px;
  height: 1380px;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--primaryblue-ryb);
  border-radius: 20px;
  overflow: hidden;
`;

const Title1 = styled.div`
  ${InterBoldWhite32px}
  margin-left: 13px;
  margin-right: 15px;
  flex: 1;
  max-height: 40px;
  margin-top: 24px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
`;

const Group46667 = styled.div`
  margin-left: 5px;
  width: 291px;
  height: 1189px;
  position: relative;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
`;

const Divider = styled.img`
  margin-left: 7px;
  width: 280px;
  height: 1px;
  margin-top: 18px;
  object-fit: cover;
`;

const Divider1 = styled.img`
  margin-left: 6px;
  width: 280px;
  height: 1px;
  margin-top: 26px;
  object-fit: cover;
`;

const Divider2 = styled.img`
  margin-left: 6px;
  width: 280px;
  height: 1px;
  margin-top: 32px;
  object-fit: cover;
`;

const Divider3 = styled.img`
  margin-left: 6px;
  width: 280px;
  height: 1px;
  margin-top: 28px;
  object-fit: cover;
`;

const ListOfItems = styled.img`
  margin-left: 12px;
  width: 268px;
  height: 72px;
  position: relative;
  margin-top: 71px;
`;

const Divider4 = styled.img`
  margin-left: 6px;
  width: 280px;
  height: 1px;
  margin-top: 17px;
  object-fit: cover;
`;

const Divider5 = styled.img`
  margin-left: 6px;
  width: 280px;
  height: 1px;
  margin-top: 19px;
  object-fit: cover;
`;

const Pricing = styled.div`
  ${InterBoldZircon24px}
  margin-left: 8px;
  width: 275px;
  height: 40px;
  margin-top: 59px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
`;

const Pricing1 = styled.div`
  ${InterBoldZircon24px}
  margin-left: 7px;
  width: 275px;
  height: 40px;
  margin-top: 5px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
`;

const PricingCard02 = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 320px;
  height: 1380px;
  background-color: var(--secondarywhite01);
  border-radius: 0px 20px 20px 0px;
  overflow: hidden;
`;

const Title2 = styled.div`
  ${InterBoldWoodsmoke32px}
  margin-left: 24px;
  width: 296px;
  height: 40px;
  margin-top: 24px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
`;

const Group466681 = styled.div`
  margin-left: 24px;
  width: 284px;
  height: 1265px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Group466671 = styled.div`
  ${InterBoldWoodsmoke24px}
  margin-left: 3px;
  width: 285px;
  height: 1189px;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Divider6 = styled.img`
  margin-left: 1px;
  width: 280px;
  height: 1px;
  margin-top: 20px;
  object-fit: cover;
`;

const Divider7 = styled.img`
  width: 280px;
  height: 1px;
  margin-top: 32px;
  object-fit: cover;
`;

const Divider8 = styled.img`
  width: 280px;
  height: 1px;
  margin-top: 21px;
  object-fit: cover;
`;

const ListOfItems1 = styled.div`
  display: flex;
  margin-left: 6px;
  width: 268px;
  height: 70px;
  position: relative;
  margin-top: 70px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

const Divider9 = styled.img`
  width: 280px;
  height: 1px;
  margin-top: 19px;
  object-fit: cover;
`;

const Pricing2 = styled.div`
  margin-left: 2px;
  width: 275px;
  height: 40px;
  margin-top: 59px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
`;

const Pricing3 = styled.div`
  margin-left: 1px;
  width: 275px;
  height: 40px;
  margin-top: 5px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
`;

const Frame46714 = styled.div`
  display: inline-flex;
  position: relative;
  margin-top: 160px;
  margin-left: 1px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const Frame46713 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
`;

const WithToolsToMakeE = styled.p`
  ${PoppinsNormalEbonyClay20px}
  position: relative;
  width: 1061px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
`;

const Image122 = styled.img`
  width: 1440px;
  height: 146px;
  margin-top: 160px;
  object-fit: cover;
`;

export default Desktop;
