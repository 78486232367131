import { css } from "styled-components";

export const ValignTextMiddle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const InterNormalTuna18px = css`
  color: var(--tuna);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
`;

export const InterNormalWhite22px = css`
  font-family: var(--font-family-inter);
  font-size: 22px;
  font-weight: 400;
  font-style: normal;
`;

export const InterNormalTuna12px = css`
  color: var(--tuna);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
`;

export const InterNormalShadyLady22px = css`
  color: var(--shady-lady);
  font-family: var(--font-family-inter);
  font-size: 22px;
  font-weight: 400;
  font-style: normal;
`;

export const PoppinsBoldBlazeOrange20px = css`
  color: var(--primaryorange);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-weight: 700;
  font-style: normal;
`;

export const InterNormalZircon18px = css`
  color: var(--primaryghost-white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
`;

export const Fontawesome5proRegularNormalBlueRib = css`
  color: var(--primaryblue-ryb);
  font-family: var(--font-family-font_awesome_5_pro-regular);
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
`;

export const PoppinsBoldZircon16px = css`
  color: var(--primaryghost-white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
`;

export const InterNormalWoodsmoke16px = css`
  color: var(--woodsmoke);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
`;

export const PoppinsBoldBlueRibbon24px = css`
  color: var(--primaryblue-ryb);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-weight: 700;
  font-style: normal;
`;

export const PoppinsBoldZircon24px = css`
  color: var(--primaryghost-white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxl);
  font-weight: 700;
  font-style: normal;
`;

export const PoppinsLightZircon16px = css`
  color: var(--primaryghost-white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-weight: 300;
  font-style: normal;
`;

export const PoppinsNormalBlueRibbon18px = css`
  color: var(--primaryblue-ryb);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
`;

export const PoppinsMediumMulledWine16px = css`
  color: var(--secondaryoxford-black03);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-weight: 500;
  font-style: normal;
`;

export const PoppinsSemiBoldEbonyClay20px = css`
  color: var(--secondaryoxford-black02);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-weight: 600;
  font-style: normal;
`;

export const InterBoldWoodsmoke24px = css`
  color: var(--woodsmoke-2);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xxl);
  font-weight: 700;
  font-style: normal;
`;

export const PoppinsBoldBlueRibbon16px = css`
  color: var(--primaryblue-ryb);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
`;

export const PoppinsLightZircon12px = css`
  color: var(--primaryghost-white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  font-weight: 300;
  font-style: normal;
`;

export const PoppinsBoldBlueRibbon36px = css`
  color: var(--primaryblue-ryb);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxxl);
  font-weight: 700;
  font-style: normal;
`;

export const PoppinsBoldWhite36px = css`
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxxl);
  font-weight: 700;
  font-style: normal;
`;

export const PoppinsBoldBlazeOrange36px = css`
  color: var(--primaryorange);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxxl);
  font-weight: 700;
  font-style: normal;
`;

export const PoppinsBoldWhite20px = css`
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-weight: 700;
  font-style: normal;
`;

export const PoppinsBoldZircon36px = css`
  color: var(--primaryghost-white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxxl);
  font-weight: 700;
  font-style: normal;
`;

export const InterBoldWoodsmoke32px = css`
  color: var(--woodsmoke);
  font-family: var(--font-family-inter);
  font-size: 32px;
  font-weight: 700;
  font-style: normal;
`;

export const PoppinsSemiBoldBlazeOrange16px = css`
  color: var(--primaryorange);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
`;

export const PoppinsBoldZircon20px = css`
  color: var(--primaryghost-white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-weight: 700;
  font-style: normal;
`;

export const PoppinsNormalMulledWine16px = css`
  color: var(--secondaryoxford-black03);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
`;

export const PoppinsLightMulledWine16px = css`
  color: var(--secondaryoxford-black03);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-weight: 300;
  font-style: normal;
`;

export const PoppinsLightBlueRibbon16px = css`
  color: var(--primaryblue-ryb);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-weight: 300;
  font-style: normal;
`;

export const PoppinsBoldEbonyClay36px = css`
  color: var(--secondaryoxford-black02);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxxl);
  font-weight: 700;
  font-style: normal;
`;

export const InterSemiBoldBlueRibbon22px = css`
  color: var(--primaryblue-ryb);
  font-family: var(--font-family-inter);
  font-size: 22px;
  font-weight: 600;
  font-style: normal;
`;

export const InterBoldZircon24px = css`
  color: var(--primaryghost-white);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xxl);
  font-weight: 700;
  font-style: normal;
`;

export const PoppinsBoldBlueRibbon20px = css`
  color: var(--primaryblue-ryb);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-weight: 700;
  font-style: normal;
`;

export const PoppinsSemiBoldWhite16px = css`
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
`;

export const PoppinsBoldBlazeOrange16px = css`
  color: var(--primaryorange);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
`;

export const PoppinsNormalEbonyClay12px = css`
  color: var(--secondaryoxford-black02);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
`;

export const PoppinsSemiBoldWhite16px2 = css`
  color: var(--secondarywhite01);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
`;

export const PoppinsBoldEerieBlack64px = css`
  color: var(--eerie-black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxxxl);
  font-weight: 700;
  font-style: normal;
`;

export const PoppinsSemiBoldBlazeOrange12px = css`
  color: var(--primaryorange);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  font-weight: 600;
  font-style: normal;
`;

export const PoppinsNormalBlueRibbon12px = css`
  color: var(--primaryblue-ryb);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
`;

export const PoppinsBoldEerieBlack20px = css`
  color: var(--eerie-black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-weight: 700;
  font-style: normal;
`;

export const PoppinsSemiBoldEbonyClay12px = css`
  color: var(--secondaryoxford-black02);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  font-weight: 600;
  font-style: normal;
`;

export const PoppinsNormalZircon20px = css`
  color: var(--primaryghost-white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-weight: 400;
  font-style: normal;
`;

export const PoppinsSemiBoldBlueRibbon16px = css`
  color: var(--primaryblue-ryb);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
`;

export const PoppinsNormalZircon16px = css`
  color: var(--primaryghost-white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
`;

export const PoppinsBoldBlue36px = css`
  color: var(--secondaryblue01);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxxl);
  font-weight: 700;
  font-style: normal;
`;

export const InterBoldWhite32px = css`
  color: var(--secondarywhite01);
  font-family: var(--font-family-inter);
  font-size: 32px;
  font-weight: 700;
  font-style: normal;
`;

export const InterSemiBoldWhite22px = css`
  color: var(--secondarywhite01);
  font-family: var(--font-family-inter);
  font-size: 22px;
  font-weight: 600;
  font-style: normal;
`;

export const PoppinsSemiBoldWhite20px = css`
  color: var(--secondarywhite01);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-weight: 600;
  font-style: normal;
`;

export const PoppinsSemiBoldWhite12px = css`
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  font-weight: 600;
  font-style: normal;
`;

export const PoppinsBoldZircon50px = css`
  color: var(--primaryghost-white);
  font-family: var(--font-family-poppins);
  font-size: 50px;
  font-weight: 700;
  font-style: normal;
`;

export const PoppinsBoldBlazeOrange50px = css`
  color: var(--primaryorange);
  font-family: var(--font-family-poppins);
  font-size: 50px;
  font-weight: 700;
  font-style: normal;
`;

export const PoppinsNormalBlackRock16px = css`
  color: var(--black-rock);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
`;

export const PoppinsBoldWhite64px = css`
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxxxl);
  font-weight: 700;
  font-style: normal;
`;

export const PoppinsBoldBlazeOrange64px = css`
  color: var(--primaryorange);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxxxl);
  font-weight: 700;
  font-style: normal;
`;

export const PoppinsBoldBlueRibbon64px = css`
  color: var(--primaryblue-ryb);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxxxl);
  font-weight: 700;
  font-style: normal;
`;

export const PoppinsNormalEbonyClay20px = css`
  color: var(--secondaryoxford-black02);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xl);
  font-weight: 400;
  font-style: normal;
`;

export const PoppinsSemiBoldWhite12px2 = css`
  color: var(--secondarywhite01);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  font-weight: 600;
  font-style: normal;
`;

export const PoppinsNormalZircon12px = css`
  color: var(--primaryghost-white);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
`;

export const PoppinsBoldBlazeOrange12px = css`
  color: var(--primaryorange);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  font-weight: 700;
  font-style: normal;
`;

export const PoppinsNormalBlackRock12px = css`
  color: var(--black-rock);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
`;

export const PoppinsBoldWhite16px = css`
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-m);
  font-weight: 700;
  font-style: normal;
`;

export const PoppinsNormalMulledWine12px = css`
  color: var(--secondaryoxford-black03);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
`;

export const PoppinsLightMulledWine12px = css`
  color: var(--secondaryoxford-black03);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  font-weight: 300;
  font-style: normal;
`;

export const PoppinsLightBlueRibbon12px = css`
  color: var(--primaryblue-ryb);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-s);
  font-weight: 300;
  font-style: normal;
`;

export const InterBoldWoodsmoke20px = css`
  color: var(--woodsmoke);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-xl);
  font-weight: 700;
  font-style: normal;
`;

export const InterSemiBoldBlueRibbon16px = css`
  color: var(--primaryblue-ryb);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-m);
  font-weight: 600;
  font-style: normal;
`;

export const InterBoldBlueRibbon12px = css`
  color: var(--primaryblue-ryb);
  font-family: var(--font-family-inter);
  font-size: var(--font-size-s);
  font-weight: 700;
  font-style: normal;
`;

export const PoppinsBoldEerieBlack36px = css`
  color: var(--eerie-black);
  font-family: var(--font-family-poppins);
  font-size: var(--font-size-xxxl);
  font-weight: 700;
  font-style: normal;
`;
