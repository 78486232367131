import React from "react";
import styled from "styled-components";
import { PoppinsNormalZircon20px, PoppinsBoldZircon50px, PoppinsBoldBlazeOrange50px } from "../../styledMixins";


function Frame46813(props) {
  const { className } = props;

  return (
    <Frame468131 className={`frame-46813 ${className || ""}`}>
      <Frame46812 className="frame-46812">
        <OneAppToDoAll className="one-app-to-do-all">ONE APP TO DO ALL</OneAppToDoAll>
        <Frame46811 className="frame-46811">
          <FORYOURCustomerLove className="for-your-customer-love">
            “FOR YOUR&nbsp;&nbsp;CUSTOMER LOVE”
          </FORYOURCustomerLove>
        </Frame46811>
      </Frame46812>
      <SeamlessCommerceE className="seamless-commerce-e">
        Seamless commerce, elevated CX with our Omni-Channel MarTech. Experience our powerhouse Chat Engine and Data
        Collection. Empower your success
        <br />
        with CRM + Commerce integration.
      </SeamlessCommerceE>
    </Frame468131>
  );
}

const Frame468131 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  position: absolute;
  top: 83px;
  left: calc(50% - 403px);

  &.frame-46813.frame-46813-1 {
    top: 92px;
    left: 109px;
  }
`;

const Frame46812 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  position: relative;
  flex: 0 0 auto;
`;

const OneAppToDoAll = styled.p`
  ${PoppinsBoldZircon50px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
`;

const FORYOURCustomerLove = styled.p`
  ${PoppinsBoldBlazeOrange50px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
`;

const Frame46811 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 32px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--secondarywhite01);
  border-radius: 16px;
`;

const SeamlessCommerceE = styled.p`
  ${PoppinsNormalZircon20px}
  position: relative;
  width: 806px;
  text-align: center;
  letter-spacing: 0;
  line-height: 32px;
`;

export default Frame46813;
