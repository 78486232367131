import React from "react";
import List from "../List";
import styled from "styled-components";
import { InterNormalShadyLady22px } from "../../styledMixins";


function ListOfItems2(props) {
  const { analytic, className, list1Props, list2Props } = props;

  return (
    <ListOfItems className={`list-of-items-12 ${className || ""}`}>
      <Analytic className="analytic">{analytic}</Analytic>
      <List userLicense={list1Props.userLicense} className={list1Props.className} />
      <List userLicense={list2Props.userLicense} className={list2Props.className} />
    </ListOfItems>
  );
}

const ListOfItems = styled.div`
  display: flex;
  position: relative;
  margin-top: 20px;
  margin-left: 8px;
  flex-direction: column;
  width: 300px;
  align-items: flex-start;
  gap: 24px;

  &.list-of-items-12.list-of-items-13 {
    margin-top: 19px;
  }

  &.list-of-items-12.list-of-items-15 {
    margin-top: 19px;
  }

  &.list-of-items-12.list-of-items-16 {
    margin-top: 19px;
  }
`;

const Analytic = styled.div`
  ${InterNormalShadyLady22px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

export default ListOfItems2;
