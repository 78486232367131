import React from "react";
import ListOfItems3 from "../ListOfItems3";
import List2 from "../List2";
import List3 from "../List3";
import PrimaryButton from "../PrimaryButton";
import styled from "styled-components";
import { InterBoldWoodsmoke24px, InterNormalTuna18px, InterNormalWhite22px } from "../../styledMixins";


function Group46668(props) {
  const { className, listOfItems3Props } = props;

  return (
    <Group466681 className={`group-46668-1 ${className || ""}`}>
      <Group46667 className="group-46667-2">
        <ListOfItems className="list-of-items-17">
          <UserLicense className="user-license-1">User License</UserLicense>
          <List className="list-39">
            <BxsCheckCircle21
              className="bxs-check-circle-2-1-2"
              src="/img/bxs-check-circle--2--1@2x.png"
              alt="bxs-check-circle (2) 1"
            />
            <Address className="address">2 admin/5 staff/1 branch</Address>
          </List>
        </ListOfItems>
        <Divider className="divider-18" src="/img/divider-6@2x.png" alt="Divider" />
        <ListOfItems3
          listOfItems1={listOfItems3Props.listOfItems1}
          listOfItems2={listOfItems3Props.listOfItems2}
          listOfItems3={listOfItems3Props.listOfItems3}
        />
        <Divider1 className="divider-19" src="/img/divider-6@2x.png" alt="Divider" />
        <ListOfItems1 className="list-of-items-18">
          <Text className="text"></Text>
          <List2 />
          <List2 />
          <List3 />
        </ListOfItems1>
        <Divider2 className="divider-20" src="/img/divider-6@2x.png" alt="Divider" />
        <ListOfItems2 className="list-of-items-19">
          <Text className="text-2"></Text>
          <ListOfItems4 className="list-of-items-20" src="/img/bxs-check-circle--2--1@2x.png" alt="List of Items" />
          <List className="list-40">
            <Address className="address-1">10 flows</Address>
          </List>
          <ListOfItems4 className="list-of-items-21" src="/img/bxs-check-circle--2--1@2x.png" alt="List of Items" />
        </ListOfItems2>
        <Divider3 className="divider-21" src="/img/divider-6@2x.png" alt="Divider" />
        <ListOfItems5 className="list-of-items-22" src="/img/list-of-items@2x.png" alt="List of Items" />
        <Divider4 className="divider-22" src="/img/divider-6@2x.png" alt="Divider" />
        <ListOfItems6 className="list-of-items-23">
          <ListOfItems4 className="list-of-items-24" src="/img/bxs-check-circle--2--1@2x.png" alt="List of Items" />
          <List className="list-41">
            <Address className="x3-5-business-days">3-5 business days</Address>
          </List>
        </ListOfItems6>
        <Divider5 className="divider-23" src="/img/divider-6@2x.png" alt="Divider" />
        <Pricing className="pricing-4">100,000</Pricing>
        <Pricing1 className="pricing-5">10,000</Pricing1>
      </Group46667>
      <PrimaryButton />
    </Group466681>
  );
}

const Group466681 = styled.div`
  margin-left: 19px;
  width: 284px;
  height: 1265px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &.group-46668-1.group-46668-2 {
    margin-left: 18px;
  }

  &.group-46668-1.group-46668-3 {
    margin-left: 18px;
  }
`;

const Group46667 = styled.div`
  margin-left: 3px;
  width: 285px;
  height: 1189px;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const ListOfItems = styled.div`
  display: inline-flex;
  margin-left: 2px;
  width: 240px;
  height: 75px;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

const UserLicense = styled.div`
  ${InterNormalWhite22px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const Address = styled.div`
  ${InterNormalTuna18px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const List = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
`;

const BxsCheckCircle21 = styled.img`
  position: relative;
  width: 24px;
  height: 24px;
`;

const Divider = styled.img`
  margin-left: 1px;
  width: 280px;
  height: 1px;
  margin-top: 18px;
  object-fit: cover;
`;

const Divider1 = styled.img`
  width: 280px;
  height: 1px;
  margin-top: 26px;
  object-fit: cover;
`;

const ListOfItems1 = styled.div`
  display: flex;
  margin-left: 6px;
  width: 268px;
  height: 165px;
  position: relative;
  margin-top: 12px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

const Text = styled.div`
  ${InterNormalWhite22px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
`;

const Divider2 = styled.img`
  width: 280px;
  height: 1px;
  margin-top: 32px;
  object-fit: cover;
`;

const ListOfItems2 = styled.div`
  display: flex;
  margin-left: 6px;
  width: 268px;
  height: 169px;
  position: relative;
  margin-top: 12px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

const ListOfItems4 = styled.img`
  position: relative;
  flex: 0 0 auto;
`;

const Divider3 = styled.img`
  width: 280px;
  height: 1px;
  margin-top: 28px;
  object-fit: cover;
`;

const ListOfItems5 = styled.img`
  margin-left: 6px;
  width: 268px;
  height: 72px;
  position: relative;
  margin-top: 71px;
`;

const Divider4 = styled.img`
  width: 280px;
  height: 1px;
  margin-top: 17px;
  object-fit: cover;
`;

const ListOfItems6 = styled.div`
  display: flex;
  margin-left: 6px;
  width: 268px;
  height: 70px;
  position: relative;
  margin-top: 70px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

const Divider5 = styled.img`
  width: 280px;
  height: 1px;
  margin-top: 19px;
  object-fit: cover;
`;

const Pricing = styled.div`
  ${InterBoldWoodsmoke24px}
  margin-left: 2px;
  width: 275px;
  height: 40px;
  margin-top: 59px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
`;

const Pricing1 = styled.div`
  ${InterBoldWoodsmoke24px}
  margin-left: 1px;
  width: 275px;
  height: 40px;
  margin-top: 5px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
`;

export default Group46668;
