import React from "react";
import styled from "styled-components";
import {
  ValignTextMiddle,
  PoppinsBoldBlueRibbon36px,
  PoppinsBoldBlazeOrange20px,
  PoppinsSemiBoldWhite16px,
} from "../../styledMixins";


function Frame46670(props) {
  const { className } = props;

  return (
    <Frame466701 className={`frame-46670 ${className || ""}`}>
      <Frame46669 className="frame-46669">
        <WhatIs className="what-is">WHAT IS</WhatIs>
        <JenosizeMarketingCloud className="jenosize-marketing-cloud">JENOSIZE MARKETING CLOUD?</JenosizeMarketingCloud>
      </Frame46669>
      <JenosizeMarketingC className="jenosize-marketing-c">
        <span className="span0-2 poppins-semi-bold-blaze-orange-16px">Jenosize Marketing Cloud</span>
        <span className="span1-2 poppins-normal-black-rock-16px">
          {" "}
          is a cloud-based customer experience solution platform that provides businesses with a suite of tools and
          services to manage their sales, marketing, customer service, and other business operations. It offers a wide
          range of features and functionalities, including single customer profile IDs, multi-channel interactions,
          Omni-channel commerce, CRM, helpdesk, marketing and loyalty.
        </span>
      </JenosizeMarketingC>
    </Frame466701>
  );
}

const Frame466701 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  position: absolute;
  top: 449px;
  left: 299px;

  &.frame-46670.frame-46670-1 {
    top: calc(50% + 52px);
    left: calc(50% - 421px);
  }
`;

const Frame46669 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
`;

const WhatIs = styled.div`
  ${ValignTextMiddle}
  ${PoppinsBoldBlazeOrange20px}
            position: relative;
  width: 85px;
  height: 18px;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const JenosizeMarketingCloud = styled.div`
  ${ValignTextMiddle}
  ${PoppinsBoldBlueRibbon36px}
            position: relative;
  width: 543px;
  height: 34px;
  text-align: center;
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const JenosizeMarketingC = styled.p`
  ${PoppinsSemiBoldWhite16px}
  position: relative;
  width: 842px;
  text-align: center;
  letter-spacing: 0;
  line-height: 32px;
`;

export default Frame46670;
