import React from "react";
import List3 from "../List3";
import styled from "styled-components";
import { InterNormalWhite22px } from "../../styledMixins";


function ListOfItems10(props) {
  const { className } = props;

  return (
    <ListOfItems className={`list-of-items-46 ${className || ""}`}>
      <Text className="text-7"></Text>
      <List3 />
      <List3 />
      <List3 />
    </ListOfItems>
  );
}

const ListOfItems = styled.div`
  display: flex;
  margin-left: 6px;
  width: 268px;
  height: 165px;
  position: relative;
  margin-top: 12px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  &.list-of-items-46.list-of-items-47 {
    margin-top: 45px;
  }

  &.list-of-items-46.list-of-items-48 {
    margin-top: 45px;
  }
`;

const Text = styled.div`
  ${InterNormalWhite22px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
`;

export default ListOfItems10;
