import React from "react";
import Frame46644 from "../Frame46644";
import Frame46813 from "../Frame46813";
import Frame46670 from "../Frame46670";
import Frame46646 from "../Frame46646";
import Frame6605 from "../Frame6605";
import Frame6606 from "../Frame6606";
import Frame66052 from "../Frame66052";
import Frame6607 from "../Frame6607";
import Frame46675 from "../Frame46675";
import Frame467234 from "../Frame467234";
import Frame46703 from "../Frame46703";
import Frame6617 from "../Frame6617";
import Frame6621 from "../Frame6621";
import OURCUSTOMERS from "../OURCUSTOMERS";
import Frame46707 from "../Frame46707";
import Frame66222 from "../Frame66222";
import PricingCard4 from "../PricingCard4";
import PricingCard10 from "../PricingCard10";
import ListOfItems4 from "../ListOfItems4";
import ListOfItems3 from "../ListOfItems3";
import ListOfItems5 from "../ListOfItems5";
import ListOfItems6 from "../ListOfItems6";
import ListOfItems7 from "../ListOfItems7";
import PrimaryButton2 from "../PrimaryButton2";
import ListOfItems8 from "../ListOfItems8";
import ListOfItems9 from "../ListOfItems9";
import ListOfItems10 from "../ListOfItems10";
import ListOfItems11 from "../ListOfItems11";
import ListOfItems12 from "../ListOfItems12";
import List6 from "../List6";
import PrimaryButton from "../PrimaryButton";
import Group46672 from "../Group46672";
import BigButton from "../BigButton";
import styled from "styled-components";
import {
  PoppinsBoldWhite64px,
  PoppinsNormalZircon16px,
  InterBoldWoodsmoke32px,
  PoppinsNormalMulledWine16px,
  InterBoldWhite32px,
  InterBoldZircon24px,
  InterBoldWoodsmoke24px,
  PoppinsSemiBoldWhite16px,
  PoppinsNormalEbonyClay20px,
} from "../../styledMixins";
import "./TabletLarge.css";

function TabletLarge(props) {
  const {
    jnzMktCloudLogo,
    image107,
    image108,
    image110,
    image109,
    cloud1,
    image123,
    bg,
    mobile,
    image1201,
    image125,
    cloud2,
    maskGroup,
    image105,
    enjoyASeamlessCha,
    experienceIntegrate,
    spanText1,
    spanText2,
    spanText3,
    image114,
    spanText4,
    spanText5,
    spanText6,
    spanText7,
    spanText8,
    image115,
    brandverseLogo1,
    brandverseLogo2,
    group465991,
    group465891,
    group46609,
    group46610,
    group465992,
    group465892,
    cloud3,
    image1202,
    image121,
    cloud4,
    copyright2023JenosizeTechnology,
    title1,
    divider1,
    divider2,
    divider3,
    divider4,
    listOfItems1,
    divider5,
    divider6,
    pricing1,
    pricing2,
    title2,
    divider7,
    divider8,
    divider9,
    divider10,
    divider11,
    divider12,
    listOfItems2,
    pricing3,
    pricing4,
    withToolsToMakeE,
    frame46813Props,
    frame46670Props,
    frame6605Props,
    frame66061Props,
    frame66062Props,
    frame6607Props,
    frame66063Props,
    frame4672341Props,
    frame4672342Props,
    frame4672343Props,
    frame4672344Props,
    frame46703Props,
    frame6617Props,
    frame6621Props,
    frame46707Props,
    frame66222Props2,
    pricingCard4Props,
    pricingCard10Props,
    listOfItems4Props,
    listOfItems3Props,
    primaryButton2Props,
    listOfItems8Props,
    listOfItems9Props,
    listOfItems10Props,
    listOfItems11Props,
    listOfItems12Props,
    bigButtonProps,
  } = props;

  return (
    <div className="tablet-large screen">
      <Frame46641>
        <Frame46645>
          <JnzMktCloudLogo src={jnzMktCloudLogo} alt="jnz-mkt-cloud-logo" />
          <Frame46644 />
        </Frame46645>
      </Frame46641>
      <X1>
        <Group46669>
          <ImageContainer>
            <Image107 src={image107} alt="image 107" />
            <Image108 src={image108} alt="image 108" />
            <Image110 src={image110} alt="image 110" />
            <Image109 src={image109} alt="image 109" />
          </ImageContainer>
        </Group46669>
        <Cloud src={cloud1} alt="Cloud" />
        <BG></BG>
        <Frame46813 className={frame46813Props.className} />
        <Image123 src={image123} alt="image 123" />
      </X1>
      <X2>
        <BG1 src={bg} alt="BG" />
        <Mobile src={mobile} alt="Mobile" />
        <Image120 src={image1201} alt="image 120" />
        <Frame46670 className={frame46670Props.className} />
        <Image125 src={image125} alt="image 125" />
      </X2>
      <X3>
        <Cloud1 src={cloud2} alt="Cloud" />
        <Frame46820>
          <Frame46646 />
          <Frame46754>
            <Frame46751>
              <Frame6605 group46631={frame6605Props.group46631} className={frame6605Props.className} />
              <Frame6606
                discount={frame66061Props.discount}
                number={frame66061Props.number}
                crm={frame66061Props.crm}
                enhanceSalesEffici={frame66061Props.enhanceSalesEffici}
              />
            </Frame46751>
            <Frame46751>
              <Frame6606
                discount={frame66062Props.discount}
                number={frame66062Props.number}
                crm={frame66062Props.crm}
                enhanceSalesEffici={frame66062Props.enhanceSalesEffici}
              />
              <Frame66052 />
            </Frame46751>
            <Frame46751>
              <Frame6607 group46630={frame6607Props.group46630} className={frame6607Props.className} />
              <Frame6606
                discount={frame66063Props.discount}
                number={frame66063Props.number}
                crm={frame66063Props.crm}
                enhanceSalesEffici={frame66063Props.enhanceSalesEffici}
              />
            </Frame46751>
          </Frame46754>
        </Frame46820>
      </X3>
      <X4>
        <Frame46729>
          <OverlapGroup>
            <MaskGroup src={maskGroup} alt="Mask group" />
            <Image105 src={image105} alt="image 105" />
          </OverlapGroup>
        </Frame46729>
        <Frame46821>
          <Frame46722>
            <Frame46675 />
            <EnjoyASeamlessCha>{enjoyASeamlessCha}</EnjoyASeamlessCha>
          </Frame46722>
          <Frame46728>
            <Frame467234 simplifyShoppingExperienceWithChat={frame4672341Props.simplifyShoppingExperienceWithChat} />
            <Frame467234 simplifyShoppingExperienceWithChat={frame4672342Props.simplifyShoppingExperienceWithChat} />
            <Frame467234 simplifyShoppingExperienceWithChat={frame4672343Props.simplifyShoppingExperienceWithChat} />
            <Frame46727>
              <ExperienceIntegrate>{experienceIntegrate}</ExperienceIntegrate>
            </Frame46727>
            <Frame467234 simplifyShoppingExperienceWithChat={frame4672344Props.simplifyShoppingExperienceWithChat} />
          </Frame46728>
        </Frame46821>
      </X4>
      <X5>
        <Frame46730>
          <Group46675>
            <OverlapGroup1>
              <OverlapGroup2>
                <WhatWeProvideToT>
                  <span className="poppins-bold-eerie-black-64px">{spanText1}</span>
                  <span className="poppins-bold-blaze-orange-64px">{spanText2}</span>
                  <span className="poppins-bold-eerie-black-64px">{spanText3}</span>
                </WhatWeProvideToT>
                <Image114 src={image114} alt="image 114" />
              </OverlapGroup2>
              <Frame46703 className={frame46703Props.className} />
            </OverlapGroup1>
          </Group46675>
          <Group46676>
            <OverlapGroup21>
              <JenosizeTechnology>
                <span className="poppins-semi-bold-blue-ribbon-16px">{spanText4}</span>
                <span className="poppins-light-mulled-wine-16px">{spanText5}</span>
                <span className="poppins-semi-bold-blaze-orange-16px">{spanText6}</span>
                <span className="poppins-light-mulled-wine-16px">{spanText7}</span>
                <span className="poppins-semi-bold-blaze-orange-16px">{spanText8}</span>
              </JenosizeTechnology>
              <Image115 src={image115} alt="image 115" />
            </OverlapGroup21>
          </Group46676>
        </Frame46730>
        <Frame46731>
          <Brandverse>
            <OverlapGroup3>
              <BrandverseLogo src={brandverseLogo1} alt="Brandverse Logo" />
              <Frame66211>
                <BrandverseLogo1 src={brandverseLogo2} alt="Brandverse Logo" />
                <Frame6617 brandverse={frame6617Props.brandverse} />
              </Frame66211>
            </OverlapGroup3>
            <Group46599 style={{ backgroundImage: `url(${group465991})` }}>
              <Group46589 src={group465891} alt="Group 46589" />
            </Group46599>
          </Brandverse>
          <Brandverse>
            <OverlapGroup11>
              <Frame6621 group46609={frame6621Props.group46609} frame6617Props={frame6621Props.frame6617Props} />
              <Group46609 src={group46609} alt="Group 46609" />
              <Group46610 src={group46610} alt="Group 46610" />
            </OverlapGroup11>
            <Group465991 style={{ backgroundImage: `url(${group465992})` }}>
              <Group465891 src={group465892} alt="Group 46589" />
            </Group465991>
          </Brandverse>
        </Frame46731>
        <Cloud2 src={cloud3} alt="Cloud" />
      </X5>
      <X6>
        <Image1201 src={image1202} alt="image 120" />
        <OURCUSTOMERS />
        <Image121 src={image121} alt="image 121" />
      </X6>
      <X7>
        <Frame46707 className={frame46707Props.className} frame466781Props={frame46707Props.frame466781Props} />
        <Cloud3 src={cloud4} alt="Cloud" />
        <Frame46625>
          <Copyright2023JenosizeTechnology>{copyright2023JenosizeTechnology}</Copyright2023JenosizeTechnology>
        </Frame46625>
      </X7>
      <X8>
        <Frame66222 className={frame66222Props2.className} />
        <Group46683>
          <OverlapGroup12>
            <ShadowContainer>
              <Shadow></Shadow>
              <Shadow1></Shadow1>
            </ShadowContainer>
            <Frame46772>
              <Frame46769>
                <PricingCard4 {...pricingCard4Props} />
                <PricingCard10 group46668Props={pricingCard10Props.group46668Props} />
                <PricingCard>
                  <FlexCol>
                    <Title>{title1}</Title>
                    <Group46667>
                      <ListOfItems4 className={listOfItems4Props.className} />
                      <Divider src={divider1} alt="Divider" />
                      <ListOfItems3
                        listOfItems1={listOfItems3Props.listOfItems1}
                        listOfItems2={listOfItems3Props.listOfItems2}
                        listOfItems3={listOfItems3Props.listOfItems3}
                        className={listOfItems3Props.className}
                      />
                      <Divider1 src={divider2} alt="Divider" />
                      <ListOfItems5 />
                      <Divider2 src={divider3} alt="Divider" />
                      <ListOfItems6 />
                      <Divider3 src={divider4} alt="Divider" />
                      <ListOfItems src={listOfItems1} alt="List of Items" />
                      <Divider4 src={divider5} alt="Divider" />
                      <ListOfItems7 />
                      <Divider5 src={divider6} alt="Divider" />
                      <Pricing>{pricing1}</Pricing>
                      <Pricing1>{pricing2}</Pricing1>
                    </Group46667>
                    <PrimaryButton2 className={primaryButton2Props.className} />
                  </FlexCol>
                </PricingCard>
                <PricingCard11>
                  <Title1>{title2}</Title1>
                  <Group46668>
                    <Group466671>
                      <Divider6 src={divider7} alt="Divider" />
                      <Divider7 src={divider8} alt="Divider" />
                      <Divider7 src={divider9} alt="Divider" />
                      <Divider7 src={divider10} alt="Divider" />
                      <Divider8 src={divider11} alt="Divider" />
                      <Divider9 src={divider12} alt="Divider" />
                      <ListOfItems8 className={listOfItems8Props.className} />
                      <ListOfItems9 className={listOfItems9Props.className} />
                      <ListOfItems10 className={listOfItems10Props.className} />
                      <ListOfItems11 className={listOfItems11Props.className} />
                      <ListOfItems12 className={listOfItems12Props.className} />
                      <ListOfItems1>
                        <ListOfItems2 src={listOfItems2} alt="List of Items" />
                        <List6 />
                      </ListOfItems1>
                      <Pricing2>{pricing3}</Pricing2>
                      <Pricing3>{pricing4}</Pricing3>
                    </Group466671>
                    <PrimaryButton />
                  </Group46668>
                </PricingCard11>
              </Frame46769>
              <Group46682>
                <Frame46771></Frame46771>
              </Group46682>
            </Frame46772>
          </OverlapGroup12>
        </Group46683>
        <Frame46714>
          <Frame46713>
            <Group46672 />
            <WithToolsToMakeE>{withToolsToMakeE}</WithToolsToMakeE>
          </Frame46713>
          <BigButton>{bigButtonProps.children}</BigButton>
        </Frame46714>
        <Frame46712>
          <Ellipse49></Ellipse49>
        </Frame46712>
      </X8>
    </div>
  );
}

const Frame46641 = styled.div`
  display: flex;
  width: 1024px;
  align-items: center;
  gap: 10px;
  padding: 24px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--primaryblue-ryb);
`;

const Frame46645 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  flex: 1;
  flex-grow: 1;
`;

const JnzMktCloudLogo = styled.img`
  position: relative;
  width: 150.302978515625px;
  height: 40px;
`;

const X1 = styled.div`
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 832px;
  background-color: var(--primaryblue-ryb);
  overflow: hidden;
`;

const Group46669 = styled.div`
  position: absolute;
  width: 995px;
  height: 192px;
  top: 640px;
  left: 14px;
`;

const ImageContainer = styled.div`
  position: relative;
  height: 192px;
`;

const Image107 = styled.img`
  position: absolute;
  width: 259px;
  height: 145px;
  top: 47px;
  left: 0;
  object-fit: cover;
`;

const Image108 = styled.img`
  position: absolute;
  width: 343px;
  height: 192px;
  top: 0;
  left: 155px;
  object-fit: cover;
`;

const Image110 = styled.img`
  position: absolute;
  width: 258px;
  height: 145px;
  top: 47px;
  left: 737px;
  object-fit: cover;
`;

const Image109 = styled.img`
  position: absolute;
  width: 343px;
  height: 192px;
  top: 0;
  left: 497px;
  object-fit: cover;
`;

const Cloud = styled.img`
  position: absolute;
  width: 1024px;
  height: 152px;
  top: 680px;
  left: 0;
  object-fit: cover;
`;

const BG = styled.div`
  position: absolute;
  width: 1023px;
  height: 1023px;
  top: 321px;
  left: 0;
  border-radius: 511.5px;
  transform: rotate(-180deg);
  background: linear-gradient(
    180deg,
    rgb(244.0000006556511, 246.0000005364418, 255) 0%,
    rgba(244.0000006556511, 246.0000005364418, 255, 0) 100%
  );
  opacity: 0.3;
`;

const Image123 = styled.img`
  position: absolute;
  width: 498px;
  height: 442px;
  top: 390px;
  left: 263px;
  object-fit: cover;
`;

const X2 = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 693px;
  align-items: flex-start;
  justify-content: center;
  gap: 0px 0px;
  position: relative;
  align-self: stretch;
  width: 100%;
  background-color: var(--secondarywhite01);
`;

const BG1 = styled.img`
  position: relative;
  flex: 1;
  flex-grow: 1;
  height: 693px;
`;

const Mobile = styled.img`
  position: absolute;
  top: calc(50% - 346px);
  left: calc(50% - 249px);
  width: 498px;
  height: 398px;
  object-fit: cover;
`;

const Image120 = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 220px;
  height: 76px;
  object-fit: cover;
`;

const Image125 = styled.img`
  position: absolute;
  top: calc(50% - 346px);
  left: calc(50% - 438px);
  width: 876px;
  height: 398px;
  object-fit: cover;
`;

const X3 = styled.div`
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 949px;
  background-color: var(--primaryblue-ryb);
`;

const Cloud1 = styled.img`
  position: absolute;
  width: 1024px;
  height: 152px;
  top: 797px;
  left: 0;
  object-fit: cover;
`;

const Frame46820 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  position: absolute;
  top: 48px;
  left: 67px;
`;

const Frame46754 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  position: relative;
  flex: 0 0 auto;
`;

const Frame46751 = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 80px;
  position: relative;
  flex: 0 0 auto;
`;

const X4 = styled.div`
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 652px;
  background-color: var(--secondarywhite01);
`;

const Frame46729 = styled.div`
  position: absolute;
  width: 500px;
  height: 652px;
  top: 0;
  left: 524px;
  background-color: var(--secondarywhite01);
`;

const OverlapGroup = styled.div`
  position: relative;
  width: 419px;
  height: 652px;
  left: 81px;
`;

const MaskGroup = styled.img`
  position: absolute;
  width: 371px;
  height: 652px;
  top: 0;
  left: 48px;
`;

const Image105 = styled.img`
  position: absolute;
  width: 419px;
  height: 467px;
  top: 93px;
  left: 0;
  object-fit: cover;
`;

const Frame46821 = styled.div`
  display: flex;
  flex-direction: column;
  width: 485px;
  height: 434px;
  align-items: flex-start;
  gap: 12px;
  position: absolute;
  top: 109px;
  left: 39px;
`;

const Frame46722 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
`;

const EnjoyASeamlessCha = styled.p`
  ${PoppinsNormalMulledWine16px}
  position: relative;
  width: 474px;
  letter-spacing: 0;
  line-height: 22.5px;
`;

const Frame46728 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  flex: 0 0 auto;
`;

const Frame46727 = styled.div`
  display: flex;
  width: 435px;
  align-items: flex-start;
  gap: 10px;
  padding: 12px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--secondarywhite01);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: var(--black-2);
`;

const ExperienceIntegrate = styled.p`
  ${PoppinsNormalMulledWine16px}
  position: relative;
  flex: 1;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 22.5px;
`;

const X5 = styled.div`
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 1266px;
  background-color: var(--secondarywhite01);
`;

const Frame46730 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  position: absolute;
  top: 60px;
  left: 67px;
`;

const Group46675 = styled.div`
  position: relative;
  width: 890px;
  height: 222px;
`;

const OverlapGroup1 = styled.div`
  position: relative;
  height: 222px;
`;

const OverlapGroup2 = styled.div`
  position: absolute;
  width: 841px;
  height: 222px;
  top: 0;
  left: 0;
`;

const WhatWeProvideToT = styled.h1`
  ${PoppinsBoldWhite64px}
  position: absolute;
  width: 841px;
  top: 0;
  left: 0;
  letter-spacing: 0;
  line-height: 80px;
`;

const Image114 = styled.img`
  position: absolute;
  width: 87px;
  height: 36px;
  top: 186px;
  left: 182px;
  object-fit: cover;
`;

const Group46676 = styled.div`
  position: relative;
  width: 892px;
  height: 122px;
  margin-right: -2px;
`;

const OverlapGroup21 = styled.div`
  position: relative;
  width: 890px;
  height: 122px;
`;

const JenosizeTechnology = styled.p`
  ${PoppinsSemiBoldWhite16px}
  position: absolute;
  width: 890px;
  top: 0;
  left: 0;
  letter-spacing: 0;
  line-height: 25.6px;
`;

const Image115 = styled.img`
  position: absolute;
  width: 43px;
  height: 9px;
  top: 110px;
  left: 181px;
  object-fit: cover;
`;

const Frame46731 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 33px;
  position: absolute;
  top: 487px;
  left: 67px;
`;

const Brandverse = styled.div`
  position: relative;
  width: 890px;
  height: 308px;
  background-color: var(--secondarywhite01);
  border-radius: 24px;
  overflow: hidden;
  border: 1px solid;
  border-color: var(--wild-sand);
`;

const OverlapGroup3 = styled.div`
  position: absolute;
  width: 515px;
  height: 308px;
  top: 0;
  left: 0;
`;

const BrandverseLogo = styled.img`
  position: absolute;
  width: 307px;
  height: 308px;
  top: 0;
  left: 0;
`;

const Frame66211 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  position: absolute;
  top: 53px;
  left: 40px;
`;

const BrandverseLogo1 = styled.img`
  position: relative;
  width: 60px;
  height: 60px;
`;

const Group46599 = styled.div`
  position: absolute;
  height: 683px;
  top: -2306px;
  left: -1347px;
  display: flex;
  align-items: flex-start;
  min-width: 406px;
  background-size: cover;
  background-position: 50% 50%;
`;

const Group46589 = styled.img`
  width: 264px;
  height: 575px;
  margin-left: -2386px;
  margin-top: -2251.71px;
`;

const OverlapGroup11 = styled.div`
  position: absolute;
  width: 582px;
  height: 308px;
  top: 0;
  left: 0;
`;

const Group46609 = styled.img`
  position: absolute;
  width: 582px;
  height: 274px;
  top: 18px;
  left: 0;
`;

const Group46610 = styled.img`
  position: absolute;
  width: 293px;
  height: 308px;
  top: 0;
  left: 0;
`;

const Group465991 = styled.div`
  position: absolute;
  height: 683px;
  top: -2647px;
  left: -1347px;
  display: flex;
  align-items: flex-start;
  min-width: 406px;
  background-size: cover;
  background-position: 50% 50%;
`;

const Group465891 = styled.img`
  width: 264px;
  height: 575px;
  margin-left: -2386px;
  margin-top: -2592.71px;
`;

const Cloud2 = styled.img`
  position: absolute;
  width: 1024px;
  height: 148px;
  top: 1118px;
  left: 0;
  object-fit: cover;
`;

const X6 = styled.div`
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 404px;
  background-color: var(--secondarywhite01);
`;

const Image1201 = styled.img`
  position: absolute;
  width: 220px;
  height: 76px;
  top: 328px;
  left: 0;
  object-fit: cover;
`;

const Image121 = styled.img`
  position: absolute;
  width: 220px;
  height: 76px;
  top: 328px;
  left: 804px;
  object-fit: cover;
`;

const X7 = styled.div`
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 524px;
  background-color: var(--primaryblue-ryb);
  overflow: hidden;
`;

const Cloud3 = styled.img`
  position: absolute;
  width: 1024px;
  height: 152px;
  top: 372px;
  left: 0;
  object-fit: cover;
`;

const Frame46625 = styled.div`
  display: flex;
  width: 1024px;
  height: 80px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0px 10px;
  position: absolute;
  top: 398px;
  left: 0;
`;

const Copyright2023JenosizeTechnology = styled.div`
  ${PoppinsNormalZircon16px}
  position: relative;
  width: fit-content;
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const X8 = styled.div`
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 2207px;
  background-color: var(--secondarywhite01);
  overflow: hidden;
`;

const Group46683 = styled.div`
  position: absolute;
  width: 1372px;
  height: 1437px;
  top: 170px;
  left: 24px;
`;

const OverlapGroup12 = styled.div`
  position: relative;
  height: 1437px;
`;

const ShadowContainer = styled.div`
  position: absolute;
  width: 977px;
  height: 1406px;
  top: 0;
  left: 0;
`;

const Shadow = styled.div`
  position: absolute;
  width: 977px;
  height: 1380px;
  top: 0;
  left: 0;
  background-color: var(--blue-zodiac);
  filter: blur(200px);
  opacity: 0.05;
`;

const Shadow1 = styled.div`
  position: absolute;
  width: 857px;
  height: 91px;
  top: 1315px;
  left: 60px;
  background-color: var(--blue-zodiac);
  filter: blur(200px);
  opacity: 0.1;
`;

const Frame46772 = styled.div`
  display: flex;
  flex-direction: column;
  width: 1372px;
  align-items: flex-start;
  gap: 40px;
  position: absolute;
  top: 9px;
  left: 0;
`;

const Frame46769 = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 24px;
  position: relative;
  flex: 0 0 auto;
`;

const PricingCard = styled.div`
  position: relative;
  width: 320px;
  height: 1380px;
  background-color: var(--primaryblue-ryb);
  border-radius: 20px;
  overflow: hidden;
`;

const FlexCol = styled.div`
  position: relative;
  width: 295px;
  top: 23px;
  left: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 1337px;
`;

const Title = styled.div`
  ${InterBoldWhite32px}
  width: 292px;
  margin-right: 3px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
`;

const Group46667 = styled.div`
  width: 291px;
  height: 1189px;
  position: relative;
  margin-top: 33px;
  margin-left: 4px;
  display: flex;
  flex-direction: column;
`;

const Divider = styled.img`
  margin-left: 7px;
  width: 268px;
  height: 1px;
  margin-top: 18px;
  object-fit: cover;
`;

const Divider1 = styled.img`
  margin-left: 6px;
  width: 269px;
  height: 1px;
  margin-top: 26px;
  object-fit: cover;
`;

const Divider2 = styled.img`
  margin-left: 6px;
  width: 269px;
  height: 1px;
  margin-top: 32px;
  object-fit: cover;
`;

const Divider3 = styled.img`
  margin-left: 6px;
  width: 269px;
  height: 1px;
  margin-top: 28px;
  object-fit: cover;
`;

const ListOfItems = styled.img`
  margin-left: 12px;
  width: 263px;
  height: 72px;
  position: relative;
  margin-top: 71px;
`;

const Divider4 = styled.img`
  margin-left: 6px;
  width: 269px;
  height: 1px;
  margin-top: 17px;
  object-fit: cover;
`;

const Divider5 = styled.img`
  margin-left: 6px;
  width: 269px;
  height: 1px;
  margin-top: 19px;
  object-fit: cover;
`;

const Pricing = styled.div`
  ${InterBoldZircon24px}
  margin-left: 8px;
  width: 275px;
  height: 40px;
  margin-top: 59px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
`;

const Pricing1 = styled.div`
  ${InterBoldZircon24px}
  margin-left: 7px;
  width: 275px;
  height: 40px;
  margin-top: 5px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
`;

const PricingCard11 = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 320px;
  height: 1380px;
  background-color: var(--secondarywhite01);
  border-radius: 20px;
  overflow: hidden;
`;

const Title1 = styled.div`
  ${InterBoldWoodsmoke32px}
  margin-left: 24px;
  width: 296px;
  height: 40px;
  margin-top: 24px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
`;

const Group46668 = styled.div`
  margin-left: 18px;
  width: 284px;
  height: 1265px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const Group466671 = styled.div`
  ${InterBoldWoodsmoke24px}
  margin-left: 3px;
  width: 285px;
  height: 1189px;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const Divider6 = styled.img`
  margin-left: -3465px;
  width: 280px;
  height: 1px;
  margin-top: -4098px;
  object-fit: cover;
`;

const Divider7 = styled.img`
  margin-left: -3466px;
  width: 280px;
  height: 1px;
  margin-top: 209px;
  object-fit: cover;
`;

const Divider8 = styled.img`
  margin-left: -3466px;
  width: 280px;
  height: 1px;
  margin-top: 160px;
  object-fit: cover;
`;

const Divider9 = styled.img`
  margin-left: -3466px;
  width: 280px;
  height: 1px;
  margin-top: 159px;
  object-fit: cover;
`;

const ListOfItems1 = styled.div`
  display: flex;
  margin-left: 6px;
  width: 268px;
  height: 70px;
  position: relative;
  margin-top: 92px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

const ListOfItems2 = styled.img`
  position: relative;
  flex: 0 0 auto;
  margin-top: -5243px;
  margin-left: -3371px;
`;

const Pricing2 = styled.div`
  margin-left: 2px;
  width: 275px;
  height: 40px;
  margin-top: 79px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
`;

const Pricing3 = styled.div`
  margin-left: 1px;
  width: 275px;
  height: 40px;
  margin-top: 5px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
`;

const Group46682 = styled.div`
  position: relative;
  display: flex;
  width: 976px;
  height: 8px;
  background-color: var(--bon-jour);
  border-radius: 200px;
`;

const Frame46771 = styled.div`
  width: 726px;
  height: 8px;
  background-color: var(--primaryblue-ryb);
  border-radius: 200px;
`;

const Frame46714 = styled.div`
  display: flex;
  flex-direction: column;
  width: 977px;
  align-items: center;
  gap: 16px;
  position: absolute;
  top: 1763px;
  left: 24px;
`;

const Frame46713 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
`;

const WithToolsToMakeE = styled.p`
  ${PoppinsNormalEbonyClay20px}
  position: relative;
  width: 977px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
`;

const Frame46712 = styled.div`
  position: absolute;
  width: 1024px;
  height: 68px;
  top: 2139px;
  left: 0;
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

const Ellipse49 = styled.div`
  margin-top: 19px;
  width: 18857px;
  height: 18857px;
  margin-left: -1px;
  background-color: var(--primaryblue-ryb);
  border-radius: 9428.5px;
`;

export default TabletLarge;
