import React from "react";
import Frame46791 from "../Frame46791";
import styled from "styled-components";


function Frame46793(props) {
  const { discount, frame46791Props } = props;

  return (
    <Frame467931>
      <Discount src={discount} alt="discount" />
      <Frame46791
        number={frame46791Props.number}
        administrativeControlHub={frame46791Props.administrativeControlHub}
        efficientlyManageU={frame46791Props.efficientlyManageU}
      />
    </Frame467931>
  );
}

const Frame467931 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  position: relative;
  flex: 0 0 auto;
`;

const Discount = styled.img`
  position: relative;
  width: 48px;
  height: 48px;
`;

export default Frame46793;
