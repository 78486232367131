import React from "react";
import styled from "styled-components";
import {
  PoppinsBoldBlueRibbon24px,
  ValignTextMiddle,
  PoppinsLightZircon16px,
  PoppinsBoldZircon24px,
} from "../../styledMixins";


function Frame6605(props) {
  const { group46631, className } = props;

  return (
    <Frame66051 className={`frame-6605 ${className || ""}`}>
      <Group46631 className="group-46631" src={group46631} alt="Group 46631" />
      <Frame6606 className="frame-6606">
        <Frame46671 className="frame-46671">
          <Number className="number">01</Number>
          <AdministrativeControlHub className="administrative-control-hub">
            Administrative Control Hub
          </AdministrativeControlHub>
        </Frame46671>
        <EfficientlyManageU className="efficiently-manage-u">
          Efficiently manage user accounts, define roles and permissions, and oversee company and branch operations all
          from a single integrated platform.
        </EfficientlyManageU>
      </Frame6606>
    </Frame66051>
  );
}

const Frame66051 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
`;

const Group46631 = styled.img`
  position: relative;
  width: 57.832275390625px;
  height: 48px;
`;

const Frame6606 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  flex: 0 0 auto;
`;

const Frame46671 = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
`;

const Number = styled.div`
  ${ValignTextMiddle}
  ${PoppinsBoldBlueRibbon24px}
            position: relative;
  width: 25px;
  height: 52px;
  margin-top: -2px;
  margin-left: -1px;
  -webkit-text-stroke: 1px var(--primaryghost-white);
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const AdministrativeControlHub = styled.div`
  ${ValignTextMiddle}
  ${PoppinsBoldZircon24px}
            position: absolute;
  top: -5px;
  left: 31px;
  height: 64px;
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const EfficientlyManageU = styled.p`
  ${PoppinsLightZircon16px}
  position: relative;
  width: 405px;
  letter-spacing: 0;
  line-height: 24px;
`;

const Group466311 = styled.img`
  .frame-6605.frame-6605-1 & {
    width: 57.83251953125px;
  }
`;

export default Frame6605;
