import React from "react";
import styled from "styled-components";


function Group46600(props) {
  const { className } = props;

  return (
    <Group466001 className={`group-46600 ${className || ""}`}>
      <Frame46676 className="frame-46676">
        <Rectangle13286 className="rectangle-13286"></Rectangle13286>
        <Rectangle13285 className="rectangle-13285"></Rectangle13285>
        <Rectangle13285 className="rectangle-13287"></Rectangle13285>
        <Rectangle13285 className="rectangle-13288"></Rectangle13285>
        <Rectangle13285 className="rectangle-13289"></Rectangle13285>
        <Rectangle13285 className="rectangle-13290"></Rectangle13285>
      </Frame46676>
    </Group466001>
  );
}

const Group466001 = styled.div`
  margin-top: 354px;
  width: 4px;
  height: 92px;
  position: relative;
  margin-left: 76px;
  display: flex;

  &.group-46600.group-46600-1 {
    position: absolute;
    top: calc(50% - 27px);
    left: calc(50% - 2px);
    align-items: center;
    justify-content: center;
    transform: rotate(-90deg);
    margin-top: unset;
    margin-left: unset;
  }

  &.group-46600.group-46600-2 {
    position: absolute;
    top: calc(50% - 13px);
    left: calc(50% - 2px);
    align-items: center;
    justify-content: center;
    transform: rotate(-90deg);
    margin-top: unset;
    margin-left: unset;
  }
`;

const Frame46676 = styled.div`
  display: inline-flex;
  width: 4px;
  height: 92px;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

const Rectangle13286 = styled.div`
  position: relative;
  width: 4px;
  height: 12px;
  background-color: var(--primaryblue-ryb);
  border-radius: 12px;
`;

const Rectangle13285 = styled.div`
  position: relative;
  width: 4px;
  height: 12px;
  background-color: var(--alto);
  border-radius: 12px;
`;

const Frame466761 = styled.div`
  .group-46600.group-46600-1 & {
    margin-top: 0;
  }
`;

const Frame466762 = styled.div`
  .group-46600.group-46600-2 & {
    margin-top: 0;
  }
`;

export default Group46600;
