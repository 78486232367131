import React from "react";
import Frame46644 from "../Frame46644";
import styled from "styled-components";


function Frame46641(props) {
  const { className } = props;

  return (
    <Frame46642 className={`frame-46642 ${className || ""}`}>
      <Frame46645 className="frame-46645-2">
        <JnzMktCloudLogo
          className="jnz-mkt-cloud-logo-3"
          src="/img/jnz-mkt-cloud-logo-1@2x.png"
          alt="jnz-mkt-cloud-logo"
        />
        <Frame46644 />
      </Frame46645>
    </Frame46642>
  );
}

const Frame46642 = styled.div`
  display: flex;
  width: 744px;
  align-items: center;
  gap: 10px;
  padding: 24px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--primaryblue-ryb);

  &.frame-46642.frame-46641-2 {
    align-items: flex-start;
    justify-content: center;
    position: fixed;
    top: calc(50% - 1044px);
    left: calc(50% - 372px);
    flex: unset;
    background-color: unset;
  }
`;

const Frame46645 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  flex: 1;
  flex-grow: 1;
`;

const JnzMktCloudLogo = styled.img`
  position: relative;
  width: 150.302978515625px;
  height: 40px;
`;

export default Frame46641;
