import React from "react";
import styled from "styled-components";
import { InterBoldBlueRibbon12px } from "../../styledMixins";


function List10(props) {
  const { children } = props;

  return (
    <List>
      <Text9>{children}</Text9>
    </List>
  );
}

const List = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
`;

const Text9 = styled.div`
  ${InterBoldBlueRibbon12px}
  position: relative;
  flex: 1;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
`;

export default List10;
