import React from "react";
import styled from "styled-components";
import { PoppinsNormalBlueRibbon12px, Fontawesome5proRegularNormalBlueRib } from "../../styledMixins";


function Frame46723(props) {
  const { simplifyShoppingExperienceWithChat } = props;

  return (
    <Frame467231>
      <SimplifyShoppingExperienceWithChat>{simplifyShoppingExperienceWithChat}</SimplifyShoppingExperienceWithChat>
      <Text5></Text5>
    </Frame467231>
  );
}

const Frame467231 = styled.div`
  display: flex;
  width: 343px;
  align-items: center;
  justify-content: space-between;
  padding: 12px 8px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--secondarywhite01);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: var(--black-2);
`;

const SimplifyShoppingExperienceWithChat = styled.p`
  ${PoppinsNormalBlueRibbon12px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const Text5 = styled.div`
  ${Fontawesome5proRegularNormalBlueRib}
  position: relative;
  width: 10.989473342895508px;
  margin-top: -1px;
  text-align: right;
  letter-spacing: 0;
  line-height: normal;
`;

export default Frame46723;
