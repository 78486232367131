import React from "react";
import styled from "styled-components";
import { PoppinsBoldWhite36px, PoppinsBoldBlazeOrange20px } from "../../styledMixins";


function Frame6622() {
  return (
    <Frame66221>
      <OURCUSTOMERS>OUR CUSTOMERS</OURCUSTOMERS>
      <WereTrustedBy250Companies>
        <span className="poppins-bold-ebony-clay-36px">We&#39;re </span>
        <span className="poppins-bold-blue-ribbon-36px">trusted by 250+</span>
        <span className="poppins-bold-ebony-clay-36px"> companies</span>
      </WereTrustedBy250Companies>
    </Frame66221>
  );
}

const Frame66221 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;
  position: relative;
  flex: 0 0 auto;
`;

const OURCUSTOMERS = styled.div`
  ${PoppinsBoldBlazeOrange20px}
  position: relative;
  width: 171px;
  height: 47px;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const WereTrustedBy250Companies = styled.p`
  ${PoppinsBoldWhite36px}
  position: relative;
  width: fit-content;
  text-align: center;
  letter-spacing: 0;
  line-height: 58px;
  white-space: nowrap;
`;

const Frame66222 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;
  position: relative;
  flex: 0 0 auto;
`;

const OURCUSTOMERS1 = styled.div`
  ${PoppinsBoldBlazeOrange20px}
  position: relative;
  width: 171px;
  height: 47px;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const WereTrustedBy250Companies1 = styled.p`
  ${PoppinsBoldWhite36px}
  position: relative;
  width: fit-content;
  text-align: center;
  letter-spacing: 0;
  line-height: 58px;
  white-space: nowrap;
`;

const Frame66223 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 9px;
  position: relative;
  flex: 0 0 auto;
`;

const OURCUSTOMERS2 = styled.div`
  ${PoppinsBoldBlazeOrange20px}
  position: relative;
  width: 171px;
  height: 47px;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const WereTrustedBy250Companies2 = styled.p`
  ${PoppinsBoldWhite36px}
  position: relative;
  width: fit-content;
  text-align: center;
  letter-spacing: 0;
  line-height: 58px;
  white-space: nowrap;
`;

export default Frame6622;
