import React from "react";
import styled from "styled-components";
import { InterSemiBoldBlueRibbon22px } from "../../styledMixins";


function PrimaryButton() {
  return (
    <PrimaryButton1>
      <ButtonLabel>Choose</ButtonLabel>
    </PrimaryButton1>
  );
}

const PrimaryButton1 = styled.div`
  width: 280px;
  height: 64px;
  display: flex;
  background-color: var(--secondaryvivid-sky-blue04);
  border-radius: 12px;
  overflow: hidden;
`;

const ButtonLabel = styled.div`
  ${InterSemiBoldBlueRibbon22px}
  margin-top: 12px;
  width: 280px;
  height: 40px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
`;

const PrimaryButton2 = styled.div`
  width: 280px;
  height: 64px;
  display: flex;
  background-color: var(--secondaryvivid-sky-blue04);
  border-radius: 12px;
  overflow: hidden;
`;

const ButtonLabel1 = styled.div`
  ${InterSemiBoldBlueRibbon22px}
  margin-top: 12px;
  width: 280px;
  height: 40px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
`;

const PrimaryButton3 = styled.div`
  width: 280px;
  height: 64px;
  display: flex;
  background-color: var(--secondaryvivid-sky-blue04);
  border-radius: 12px;
  overflow: hidden;
`;

const ButtonLabel2 = styled.div`
  ${InterSemiBoldBlueRibbon22px}
  margin-top: 12px;
  width: 280px;
  height: 40px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
`;

export default PrimaryButton;
