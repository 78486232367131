import React from "react";
import List5 from "../List5";
import styled from "styled-components";


function ListOfItems7() {
  return (
    <ListOfItems>
      <ListOfItems1 src="/img/bxs-check-circle--2--1-17@2x.png" alt="List of Items" />
      <List5 />
    </ListOfItems>
  );
}

const ListOfItems = styled.div`
  display: flex;
  margin-left: 12px;
  width: 268px;
  height: 70px;
  position: relative;
  margin-top: 70px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

const ListOfItems1 = styled.img`
  position: relative;
  flex: 0 0 auto;
`;

const ListOfItems2 = styled.div`
  display: flex;
  margin-left: 12px;
  width: 268px;
  height: 70px;
  position: relative;
  margin-top: 70px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
`;

const ListOfItems3 = styled.img`
  position: relative;
  flex: 0 0 auto;
`;

export default ListOfItems7;
