import React from "react";
import styled from "styled-components";
import { PoppinsNormalBlueRibbon18px, Fontawesome5proRegularNormalBlueRib } from "../../styledMixins";


function Group46663(props) {
  const { simplifyShoppingExperienceWithChat, text1, className } = props;

  return (
    <Group466631 className={`group-46663 ${className || ""}`}>
      <OverlapGroup className="overlap-group-4">
        <SimplifyShoppingExperienceWithChat className="simplify-shopping-experience-with-chat">
          {simplifyShoppingExperienceWithChat}
        </SimplifyShoppingExperienceWithChat>
        <Text1 className="text-1">{text1}</Text1>
      </OverlapGroup>
    </Group466631>
  );
}

const Group466631 = styled.div`
  display: flex;
  align-items: flex-start;
  min-width: 479px;

  &.group-46663.group-46658 {
    position: relative;
    width: 479px;
    height: 60px;
    margin-right: -4px;
    display: unset;
    align-items: unset;
    min-width: unset;
  }

  &.group-46663.group-46659 {
    position: relative;
    width: 479px;
    height: 60px;
    margin-right: -4px;
    display: unset;
    align-items: unset;
    min-width: unset;
  }

  &.group-46663.group-46660 {
    position: relative;
    width: 479px;
    height: 60px;
    margin-right: -4px;
    display: unset;
    align-items: unset;
    min-width: unset;
  }
`;

const OverlapGroup = styled.div`
  height: 60px;
  display: flex;
  padding: 15px 9px;
  align-items: flex-start;
  min-width: 475px;
  gap: 77px;
  background-color: var(--secondarywhite01);
  border-top-width: 1px;
  border-top-style: solid;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: var(--black-3);
`;

const SimplifyShoppingExperienceWithChat = styled.p`
  ${PoppinsNormalBlueRibbon18px}
  min-height: 27px;
  min-width: 356px;
  letter-spacing: 0;
  line-height: normal;
`;

const Text1 = styled.div`
  ${Fontawesome5proRegularNormalBlueRib}
  min-height: 18px;
  align-self: center;
  margin-bottom: 2px;
  min-width: 12px;
  text-align: right;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

const OverlapGroup1 = styled.div`
  .group-46663.group-46658 & {
    gap: 165px;
  }
`;

const ServeCustomerConvenience = styled.div`
  ${PoppinsNormalBlueRibbon18px}

  .group-46663.group-46658  & {
    min-width: 268px;
  }
`;

const OverlapGroup2 = styled.div`
  .group-46663.group-46659 & {
    gap: 118px;
  }
`;

const IntegratedChatShopExperience = styled.p`
  ${PoppinsNormalBlueRibbon18px}

  .group-46663.group-46659  & {
    min-width: 315px;
  }
`;

const OverlapGroup4 = styled.div`
  .group-46663.group-46660 & {
    gap: 49px;
  }
`;

const InfluencesConsumer = styled.p`
  ${PoppinsNormalBlueRibbon18px}

  .group-46663.group-46660  & {
    min-width: 384px;
  }
`;

export default Group46663;
