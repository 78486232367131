import React from "react";
import List from "../List";
import ListOfItems from "../ListOfItems";
import ListOfItems2 from "../ListOfItems2";
import styled from "styled-components";
import { InterBoldWoodsmoke32px, InterNormalShadyLady22px } from "../../styledMixins";


function PricingCard4(props) {
  const {
    className,
    listProps,
    listOfItems1Props,
    listOfItems2Props,
    listOfItems3Props,
    listOfItems21Props,
    listOfItems22Props,
    listOfItems23Props,
  } = props;

  return (
    <PricingCard41 className={`pricing-card-4 ${className || ""}`}>
      <FlexCol className="flex-col-2">
        <Title className="title-3">Features</Title>
        <ListOfItems1 className="list-of-items-3">
          <Users className="users">Users</Users>
          <List userLicense={listProps.userLicense} />
        </ListOfItems1>
        <Divider className="divider-12" src="/img/divider@2x.png" alt="Divider" />
        <ListOfItems
          supportedChannel={listOfItems1Props.supportedChannel}
          orderManagement={listOfItems1Props.orderManagement}
          list1Props={listOfItems1Props.list1Props}
          list2Props={listOfItems1Props.list2Props}
        />
        <Divider1 className="divider-13" src="/img/divider@2x.png" alt="Divider" />
        <ListOfItems
          supportedChannel={listOfItems2Props.supportedChannel}
          orderManagement={listOfItems2Props.orderManagement}
          className={listOfItems2Props.className}
          list1Props={listOfItems2Props.list1Props}
          list2Props={listOfItems2Props.list2Props}
        />
        <Divider1 className="divider-14" src="/img/divider@2x.png" alt="Divider" />
        <ListOfItems
          supportedChannel={listOfItems3Props.supportedChannel}
          orderManagement={listOfItems3Props.orderManagement}
          className={listOfItems3Props.className}
          list1Props={listOfItems3Props.list1Props}
          list2Props={listOfItems3Props.list2Props}
        />
        <Divider1 className="divider-15" src="/img/divider@2x.png" alt="Divider" />
        <ListOfItems2
          analytic={listOfItems21Props.analytic}
          list1Props={listOfItems21Props.list1Props}
          list2Props={listOfItems21Props.list2Props}
        />
        <Divider2 className="divider-16" src="/img/divider@2x.png" alt="Divider" />
        <ListOfItems2
          analytic={listOfItems22Props.analytic}
          className={listOfItems22Props.className}
          list1Props={listOfItems22Props.list1Props}
          list2Props={listOfItems22Props.list2Props}
        />
        <Divider2 className="divider-17" src="/img/divider@2x.png" alt="Divider" />
        <ListOfItems2
          analytic={listOfItems23Props.analytic}
          list1Props={listOfItems23Props.list1Props}
          list2Props={listOfItems23Props.list2Props}
        />
      </FlexCol>
    </PricingCard41>
  );
}

const PricingCard41 = styled.div`
  position: relative;
  width: 340px;
  height: 1380px;
  background-color: var(--secondarywhite01);
  border-radius: 20px 0px 0px 20px;
  overflow: hidden;
`;

const FlexCol = styled.div`
  position: relative;
  width: 320px;
  top: 23px;
  left: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 1261px;
`;

const Title = styled.div`
  ${InterBoldWoodsmoke32px}
  width: 296px;
  margin-left: 4px;
  letter-spacing: 0;
  line-height: 40px;
`;

const ListOfItems1 = styled.div`
  display: inline-flex;
  position: relative;
  margin-top: 33px;
  margin-left: 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

const Users = styled.div`
  ${InterNormalShadyLady22px}
  position: relative;
  width: 158px;
  height: 32px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const Divider = styled.img`
  width: 320px;
  height: 1px;
  margin-top: 13px;
  object-fit: cover;
`;

const Divider1 = styled.img`
  width: 320px;
  height: 1px;
  margin-top: 18px;
  object-fit: cover;
`;

const Divider2 = styled.img`
  width: 320px;
  height: 1px;
  margin-top: 17px;
  object-fit: cover;
`;

const PricingCard9 = styled.div`
  .pricing-card-4 & {
    border-radius: 20px;
  }
`;

const PricingCard91 = styled.div`
  .pricing-card-4 & {
    border-radius: 20px;
  }
`;

export default PricingCard4;
