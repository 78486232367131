import React from "react";
import styled from "styled-components";
import { ValignTextMiddle, PoppinsMediumMulledWine16px, PoppinsSemiBoldEbonyClay20px } from "../../styledMixins";


function Frame46684(props) {
  const { bigCCallChatShop, surpassing100KActi } = props;

  return (
    <Frame466841>
      <OverlapGroup2>
        <Ellipse48></Ellipse48>
        <BigCCallChatShop>{bigCCallChatShop}</BigCCallChatShop>
        <Surpassing100KActi>{surpassing100KActi}</Surpassing100KActi>
      </OverlapGroup2>
    </Frame466841>
  );
}

const Frame466841 = styled.div`
  position: relative;
  width: 515px;
  height: 242px;
  background-color: var(--secondarywhite01);
  border-radius: 16px;
  overflow: hidden;
`;

const OverlapGroup2 = styled.div`
  position: relative;
  width: 669px;
  height: 669px;
  top: -66px;
  left: -81px;
`;

const Ellipse48 = styled.div`
  position: absolute;
  width: 475px;
  height: 475px;
  top: 97px;
  left: 97px;
  border-radius: 237.37px;
  transform: rotate(129.72deg);
  background: linear-gradient(
    180deg,
    rgb(244.0000006556511, 246.0000005364418, 255) 0%,
    rgba(244.0000006556511, 246.0000005364418, 255, 0) 100%
  );
`;

const BigCCallChatShop = styled.p`
  ${ValignTextMiddle}
  ${PoppinsMediumMulledWine16px}
            position: absolute;
  width: 467px;
  height: 16px;
  top: 267px;
  left: 105px;
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const Surpassing100KActi = styled.p`
  ${PoppinsSemiBoldEbonyClay20px}
  position: absolute;
  width: 467px;
  top: 89px;
  left: 105px;
  letter-spacing: 0;
  line-height: 32px;
`;

const Frame466842 = styled.div`
  position: relative;
  width: 515px;
  height: 242px;
  background-color: var(--secondarywhite01);
  border-radius: 16px;
  overflow: hidden;
`;

const OverlapGroup21 = styled.div`
  position: relative;
  width: 669px;
  height: 669px;
  top: -66px;
  left: -81px;
`;

const Ellipse481 = styled.div`
  position: absolute;
  width: 475px;
  height: 475px;
  top: 97px;
  left: 97px;
  border-radius: 237.37px;
  transform: rotate(129.72deg);
  background: linear-gradient(
    180deg,
    rgb(244.0000006556511, 246.0000005364418, 255) 0%,
    rgba(244.0000006556511, 246.0000005364418, 255, 0) 100%
  );
`;

const BigCCallChatShop1 = styled.p`
  ${ValignTextMiddle}
  ${PoppinsMediumMulledWine16px}
            position: absolute;
  width: 467px;
  height: 16px;
  top: 267px;
  left: 105px;
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const Surpassing100KActi1 = styled.p`
  ${PoppinsSemiBoldEbonyClay20px}
  position: absolute;
  width: 467px;
  top: 89px;
  left: 105px;
  letter-spacing: 0;
  line-height: 32px;
`;

const Frame466843 = styled.div`
  position: relative;
  width: 515px;
  height: 242px;
  background-color: var(--secondarywhite01);
  border-radius: 16px;
  overflow: hidden;
`;

const OverlapGroup22 = styled.div`
  position: relative;
  width: 669px;
  height: 669px;
  top: -66px;
  left: -81px;
`;

const Ellipse482 = styled.div`
  position: absolute;
  width: 475px;
  height: 475px;
  top: 97px;
  left: 97px;
  border-radius: 237.37px;
  transform: rotate(129.72deg);
  background: linear-gradient(
    180deg,
    rgb(244.0000006556511, 246.0000005364418, 255) 0%,
    rgba(244.0000006556511, 246.0000005364418, 255, 0) 100%
  );
`;

const BigCCallChatShop2 = styled.p`
  ${ValignTextMiddle}
  ${PoppinsMediumMulledWine16px}
            position: absolute;
  width: 467px;
  height: 16px;
  top: 267px;
  left: 105px;
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const Surpassing100KActi2 = styled.p`
  ${PoppinsSemiBoldEbonyClay20px}
  position: absolute;
  width: 467px;
  top: 89px;
  left: 105px;
  letter-spacing: 0;
  line-height: 32px;
`;

export default Frame46684;
