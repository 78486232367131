import React from "react";
import Frame46743 from "../Frame46743";
import styled from "styled-components";


function Frame46745(props) {
  const { discount, frame46743Props } = props;

  return (
    <Frame467451>
      <Discount src={discount} alt="discount" />
      <Frame46743
        number={frame46743Props.number}
        crm={frame46743Props.crm}
        enhanceSalesEffici={frame46743Props.enhanceSalesEffici}
      />
    </Frame467451>
  );
}

const Frame467451 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  position: relative;
  flex: 0 0 auto;
`;

const Discount = styled.img`
  position: relative;
  width: 36px;
  height: 36px;
`;

export default Frame46745;
