import React from "react";
import styled from "styled-components";
import { PoppinsNormalBlueRibbon18px, Fontawesome5proRegularNormalBlueRib } from "../../styledMixins";


function Frame467234(props) {
  const { simplifyShoppingExperienceWithChat } = props;

  return (
    <Frame46723>
      <SimplifyShoppingExperienceWithChat>{simplifyShoppingExperienceWithChat}</SimplifyShoppingExperienceWithChat>
      <Text11></Text11>
    </Frame46723>
  );
}

const Frame46723 = styled.div`
  display: flex;
  width: 435px;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--secondarywhite01);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: var(--black-2);
`;

const SimplifyShoppingExperienceWithChat = styled.p`
  ${PoppinsNormalBlueRibbon18px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const Text11 = styled.div`
  ${Fontawesome5proRegularNormalBlueRib}
  position: relative;
  width: 10.989473342895508px;
  text-align: right;
  letter-spacing: 0;
  line-height: normal;
`;

export default Frame467234;
