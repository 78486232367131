import React from "react";
import styled from "styled-components";
import { InterNormalZircon18px } from "../../styledMixins";


function List4() {
  return (
    <List>
      <Unlimited>Unlimited</Unlimited>
    </List>
  );
}

const List = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
`;

const Unlimited = styled.div`
  ${InterNormalZircon18px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const List1 = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
`;

const Unlimited1 = styled.div`
  ${InterNormalZircon18px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const List2 = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
`;

const Unlimited2 = styled.div`
  ${InterNormalZircon18px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

export default List4;
