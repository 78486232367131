import React from "react";
import styled from "styled-components";
import { InterNormalTuna12px } from "../../styledMixins";


function List8(props) {
  const { userLicense } = props;

  return (
    <List>
      <BxsCheckCircle21 src="/img/bxs-check-circle--2--1-18@2x.png" alt="bxs-check-circle (2) 1" />
      <UserLicense>{userLicense}</UserLicense>
    </List>
  );
}

const List = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
`;

const BxsCheckCircle21 = styled.img`
  position: relative;
  width: 15px;
  height: 15px;
`;

const UserLicense = styled.div`
  ${InterNormalTuna12px}
  position: relative;
  flex: 1;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

export default List8;
