import React from "react";
import Frame6617 from "../Frame6617";
import styled from "styled-components";


function Frame6621(props) {
  const { group46609, frame6617Props } = props;

  return (
    <Frame66211>
      <Group46609 src={group46609} alt="Group 46609" />
      <Frame6617 brandverse={frame6617Props.brandverse} />
    </Frame66211>
  );
}

const Frame66211 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  position: absolute;
  top: 53px;
  left: 40px;
`;

const Group46609 = styled.img`
  position: relative;
  width: 57px;
  height: 60px;
`;

const Frame66212 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  position: absolute;
  top: 53px;
  left: 40px;
`;

const Group466091 = styled.img`
  position: relative;
  width: 57px;
  height: 60px;
`;

const Frame66213 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  position: absolute;
  top: 53px;
  left: 40px;
`;

const Group466092 = styled.img`
  position: relative;
  width: 57px;
  height: 60px;
`;

export default Frame6621;
