import React from "react";
import styled from "styled-components";
import { InterNormalZircon18px, InterNormalWhite22px } from "../../styledMixins";


function ListOfItems4(props) {
  const { className } = props;

  return (
    <ListOfItems className={`list-of-items-32 ${className || ""}`}>
      <UserLicense className="user-license-2">User License</UserLicense>
      <List className="list-44">
        <BxsCheckCircle21
          className="bxs-check-circle-2-1-3"
          src="/img/bxs-check-circle--2--1-17@2x.png"
          alt="bxs-check-circle (2) 1"
        />
        <Address className="address-3">5 admin/5 staff/Unlimited</Address>
      </List>
    </ListOfItems>
  );
}

const ListOfItems = styled.div`
  display: inline-flex;
  margin-left: 8px;
  width: 248px;
  height: 75px;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

const UserLicense = styled.div`
  ${InterNormalWhite22px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const Address = styled.div`
  ${InterNormalZircon18px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const List = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
`;

const BxsCheckCircle21 = styled.img`
  position: relative;
  width: 24px;
  height: 24px;
`;

export default ListOfItems4;
