import React from "react";
import Frame46678 from "../Frame46678";
import Frame46684 from "../Frame46684";
import styled from "styled-components";


function Frame46682(props) {
  const { className, frame466781Props, frame466782Props, frame466841Props, frame466842Props } = props;

  return (
    <Frame466821 className={`frame-46682 ${className || ""}`}>
      <Frame46678 trueCorp={frame466781Props.trueCorp} surname={frame466781Props.surname} />
      <Frame46678
        trueCorp={frame466782Props.trueCorp}
        surname={frame466782Props.surname}
        className={frame466782Props.className}
      />
      <Frame46684
        bigCCallChatShop={frame466841Props.bigCCallChatShop}
        surpassing100KActi={frame466841Props.surpassing100KActi}
      />
      <Frame46684
        bigCCallChatShop={frame466842Props.bigCCallChatShop}
        surpassing100KActi={frame466842Props.surpassing100KActi}
      />
    </Frame466821>
  );
}

const Frame466821 = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 32px;
  position: relative;
  flex: 0 0 auto;

  &.frame-46682.frame-46682-2 {
    display: flex;
    width: 2156px;
    height: 242px;
    flex: unset;
  }
`;

export default Frame46682;
