import React from "react";
import styled from "styled-components";
import { PoppinsBoldWhite20px, PoppinsBoldWhite36px } from "../../styledMixins";


function Frame46646() {
  return (
    <Frame466461>
      <WhyJenosizeMarketingCloud>
        <span className="poppins-bold-blaze-orange-20px">Why</span>
        <span className="poppins-bold-zircon-20px">&nbsp;&nbsp;Jenosize Marketing Cloud</span>
      </WhyJenosizeMarketingCloud>
      <ShopChatAndSucce>
        <span className="poppins-bold-zircon-36px">
          Shop, chat and succeed with our <br />
        </span>
        <span className="poppins-bold-blaze-orange-36px">all-in-one</span>
        <span className="poppins-bold-zircon-36px"> solution</span>
      </ShopChatAndSucce>
    </Frame466461>
  );
}

const Frame466461 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
`;

const WhyJenosizeMarketingCloud = styled.p`
  ${PoppinsBoldWhite20px}
  position: relative;
  width: 350px;
  height: 45px;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const ShopChatAndSucce = styled.p`
  ${PoppinsBoldWhite36px}
  position: relative;
  width: fit-content;
  letter-spacing: 0;
  line-height: 50px;
`;

const Frame466462 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
`;

const WhyJenosizeMarketingCloud1 = styled.p`
  ${PoppinsBoldWhite20px}
  position: relative;
  width: 350px;
  height: 45px;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const ShopChatAndSucce1 = styled.p`
  ${PoppinsBoldWhite36px}
  position: relative;
  width: fit-content;
  letter-spacing: 0;
  line-height: 50px;
`;

export default Frame46646;
