import React from "react";
import styled from "styled-components";
import { PoppinsLightBlueRibbon16px, PoppinsBoldBlueRibbon36px } from "../../styledMixins";


function Frame6617(props) {
  const { brandverse } = props;

  return (
    <Frame66171>
      <Brandverse>{brandverse}</Brandverse>
      <LoremIpsumDolorSi>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
        magna
      </LoremIpsumDolorSi>
    </Frame66171>
  );
}

const Frame66171 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
`;

const Brandverse = styled.div`
  ${PoppinsBoldBlueRibbon36px}
  position: relative;
  width: 475px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const LoremIpsumDolorSi = styled.p`
  ${PoppinsLightBlueRibbon16px}
  position: relative;
  width: 470px;
  letter-spacing: 0;
  line-height: 22.5px;
`;

const Frame66172 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
`;

const Brandverse1 = styled.div`
  ${PoppinsBoldBlueRibbon36px}
  position: relative;
  width: 475px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const LoremIpsumDolorSi1 = styled.p`
  ${PoppinsLightBlueRibbon16px}
  position: relative;
  width: 470px;
  letter-spacing: 0;
  line-height: 22.5px;
`;

const Frame66173 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
`;

const Brandverse2 = styled.div`
  ${PoppinsBoldBlueRibbon36px}
  position: relative;
  width: 475px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const LoremIpsumDolorSi2 = styled.p`
  ${PoppinsLightBlueRibbon16px}
  position: relative;
  width: 470px;
  letter-spacing: 0;
  line-height: 22.5px;
`;

export default Frame6617;
