import React from "react";
import Frame46745 from "../Frame46745";
import Frame46743 from "../Frame46743";
import Group46600 from "../Group46600";
import Frame46723 from "../Frame46723";
import Frame66172 from "../Frame66172";
import Frame46768 from "../Frame46768";
import List8 from "../List8";
import Frame46775 from "../Frame46775";
import Frame46778 from "../Frame46778";
import PrimaryButton3 from "../PrimaryButton3";
import List9 from "../List9";
import List10 from "../List10";
import styled from "styled-components";
import {
  PoppinsBoldZircon16px,
  PoppinsNormalMulledWine12px,
  PoppinsBoldWhite20px,
  PoppinsNormalZircon12px,
  PoppinsBoldBlazeOrange20px,
  PoppinsNormalEbonyClay12px,
  PoppinsBoldBlueRibbon24px,
  InterNormalTuna12px,
  PoppinsBoldBlazeOrange12px,
  InterBoldWoodsmoke20px,
  PoppinsSemiBoldWhite12px,
  PoppinsBoldBlazeOrange16px,
  PoppinsBoldWhite16px,
  InterNormalWoodsmoke16px,
  PoppinsSemiBoldWhite12px2,
  ValignTextMiddle,
  PoppinsBoldZircon20px,
  PoppinsBoldBlueRibbon20px,
  PoppinsLightZircon12px,
  PoppinsBoldBlueRibbon16px,
} from "../../styledMixins";
import "./Mobile.css";

function Mobile(props) {
  const {
    jnzMktCloudLogo,
    getStarted,
    image107,
    image108,
    image110,
    image109,
    cloud1,
    image123,
    oneAppToDoAll,
    forYourCustomerLove,
    seamlessCommerceE,
    bg,
    mobile,
    whatIs,
    jenosizeMarketingCloud,
    spanText1,
    spanText2,
    image120,
    spanText3,
    spanText4,
    spanText5,
    spanText6,
    spanText7,
    group46631,
    number,
    administrativeControlHub,
    efficientlyManageU,
    group46630,
    cloud2,
    image127,
    multiChannelInteraction,
    enjoyASeamlessCha,
    experienceIntegrate,
    spanText8,
    spanText9,
    spanText10,
    image114,
    image117,
    spanText11,
    spanText12,
    spanText13,
    spanText14,
    spanText15,
    image115,
    cloud3,
    brandverseLogo1,
    brandverseLogo2,
    group466101,
    group466102,
    ourCustomers,
    spanText16,
    spanText17,
    spanText18,
    frame46635,
    image121,
    image122,
    spanText19,
    spanText20,
    spanText21,
    spanText22,
    cloud4,
    copyright2023JenosizeTechnology,
    planPricing,
    pricingBuiltForB,
    title1,
    title2,
    divider1,
    divider2,
    divider3,
    divider4,
    divider5,
    divider6,
    title3,
    title4,
    bxsCheckCircle21,
    address,
    divider7,
    title5,
    list1,
    list2,
    list3,
    divider8,
    title6,
    divider9,
    title7,
    list4,
    list5,
    divider10,
    title8,
    list6,
    list7,
    divider11,
    title9,
    list8,
    divider12,
    title10,
    image116,
    experienceInActiN,
    withToolsToMakeE,
    getStartedForFree,
    frame467451Props,
    frame467452Props,
    frame467453Props,
    frame46743Props,
    frame467454Props,
    group46600Props,
    frame467231Props,
    frame467232Props,
    frame467233Props,
    frame467234Props,
    frame661721Props,
    frame661722Props,
    frame467681Props,
    frame467682Props,
    frame467683Props,
    frame467684Props,
    list8Props,
    frame467751Props,
    frame467752Props,
    frame467753Props,
    frame467781Props,
    frame467782Props,
    frame467783Props,
    list91Props,
    list92Props,
    list93Props,
    list94Props,
    list95Props,
    list101Props,
    list102Props,
    primaryButton3Props,
  } = props;

  return (
    <div className="mobile screen">
      <Frame46814>
        <JnzMktCloudLogo src={jnzMktCloudLogo} alt="jnz-mkt-cloud-logo" />
        <BigButton>
          <GetStarted>{getStarted}</GetStarted>
        </BigButton>
      </Frame46814>
      <X1>
        <Group46669>
          <ImageContainer>
            <Image107 src={image107} alt="image 107" />
            <Image108 src={image108} alt="image 108" />
            <Image110 src={image110} alt="image 110" />
            <Image109 src={image109} alt="image 109" />
          </ImageContainer>
        </Group46669>
        <Cloud src={cloud1} alt="Cloud" />
        <BG></BG>
        <Image123 src={image123} alt="image 123" />
        <Frame46734>
          <Frame46733>
            <OneAppToDoAll>{oneAppToDoAll}</OneAppToDoAll>
            <Frame46732>
              <FORYOURCustomerLove>{forYourCustomerLove}</FORYOURCustomerLove>
            </Frame46732>
          </Frame46733>
          <SeamlessCommerceE>{seamlessCommerceE}</SeamlessCommerceE>
        </Frame46734>
      </X1>
      <X2>
        <BG1 src={bg} alt="BG" />
        <Mobile1 src={mobile} alt="Mobile" />
        <Frame46738>
          <Frame46737>
            <WhatIs>{whatIs}</WhatIs>
            <JenosizeMarketingCloud>{jenosizeMarketingCloud}</JenosizeMarketingCloud>
          </Frame46737>
          <JenosizeMarketingC>
            <span className="poppins-semi-bold-blaze-orange-12px">{spanText1}</span>
            <span className="poppins-normal-black-rock-12px">{spanText2}</span>
          </JenosizeMarketingC>
        </Frame46738>
        <Image120 src={image120} alt="image 120" />
      </X2>
      <X3>
        <Frame46750>
          <Frame46646>
            <WhyJenosizeMarketingCloud>
              <span>
                <span className="poppins-bold-blaze-orange-12px">{spanText3}</span>
                <Span19>{spanText4}</Span19>
              </span>
            </WhyJenosizeMarketingCloud>
            <ShopChatAndSucce>
              <span className="poppins-bold-zircon-16px">{spanText5}</span>
              <span className="poppins-bold-blaze-orange-16px">{spanText6}</span>
              <span className="poppins-bold-zircon-16px">{spanText7}</span>
            </ShopChatAndSucce>
          </Frame46646>
          <Frame46746>
            <Frame46744>
              <Group46631 src={group46631} alt="Group 46631" />
              <Frame467431>
                <Frame46742>
                  <Number>{number}</Number>
                  <AdministrativeControlHub>{administrativeControlHub}</AdministrativeControlHub>
                </Frame46742>
                <EfficientlyManageU>{efficientlyManageU}</EfficientlyManageU>
              </Frame467431>
            </Frame46744>
            <Frame46745 discount={frame467451Props.discount} frame46743Props={frame467451Props.frame46743Props} />
            <Frame46745 discount={frame467452Props.discount} frame46743Props={frame467452Props.frame46743Props} />
            <Frame46745 discount={frame467453Props.discount} frame46743Props={frame467453Props.frame46743Props} />
            <Frame46744>
              <Group46630 style={{ backgroundImage: `url(${group46630})` }}></Group46630>
              <Frame46743
                number={frame46743Props.number}
                crm={frame46743Props.crm}
                enhanceSalesEffici={frame46743Props.enhanceSalesEffici}
              />
            </Frame46744>
            <Frame46745 discount={frame467454Props.discount} frame46743Props={frame467454Props.frame46743Props} />
          </Frame46746>
        </Frame46750>
        <Cloud1 src={cloud2} alt="Cloud" />
      </X3>
      <X4>
        <Frame46826>
          <Image127 src={image127} alt="image 127" />
        </Frame46826>
        <Group46600 className={group46600Props.className} />
        <Frame46762>
          <Frame467431>
            <Frame467431>
              <MultiChannelInteraction>{multiChannelInteraction}</MultiChannelInteraction>
            </Frame467431>
            <EnjoyASeamlessCha>{enjoyASeamlessCha}</EnjoyASeamlessCha>
          </Frame467431>
          <Frame46761>
            <Frame46723 simplifyShoppingExperienceWithChat={frame467231Props.simplifyShoppingExperienceWithChat} />
            <Frame46723 simplifyShoppingExperienceWithChat={frame467232Props.simplifyShoppingExperienceWithChat} />
            <Frame46761>
              <Frame46723 simplifyShoppingExperienceWithChat={frame467233Props.simplifyShoppingExperienceWithChat} />
              <Frame46728>
                <ExperienceIntegrate>{experienceIntegrate}</ExperienceIntegrate>
              </Frame46728>
            </Frame46761>
            <Frame46723 simplifyShoppingExperienceWithChat={frame467234Props.simplifyShoppingExperienceWithChat} />
          </Frame46761>
        </Frame46762>
      </X4>
      <X5>
        <Frame46763>
          <Group46679>
            <OverlapGroup>
              <WhatWeProvideToT>
                <span>
                  <span className="poppins-bold-eerie-black-20px">{spanText8}</span>
                  <span className="poppins-bold-blaze-orange-20px">{spanText9}</span>
                  <span className="poppins-bold-eerie-black-20px">{spanText10}</span>
                </span>
              </WhatWeProvideToT>
              <Image114 src={image114} alt="image 114" />
            </OverlapGroup>
          </Group46679>
          <Image117 src={image117} alt="image 117" />
          <Group46680>
            <OverlapGroup1>
              <JenosizeTechnology>
                <Span012>{spanText11}</Span012>
                <span className="poppins-light-mulled-wine-12px">{spanText12}</span>
                <span className="poppins-semi-bold-blaze-orange-12px">{spanText13}</span>
                <span className="poppins-light-mulled-wine-12px">{spanText14}</span>
                <span className="poppins-semi-bold-blaze-orange-12px">{spanText15}</span>
              </JenosizeTechnology>
              <Image115 src={image115} alt="image 115" />
            </OverlapGroup1>
          </Group46680>
        </Frame46763>
        <Cloud2 src={cloud3} alt="Cloud" />
        <Frame46764>
          <BrandverseLogo src={brandverseLogo1} alt="Brandverse Logo" />
          <BrandverseLogo1 src={brandverseLogo2} alt="Brandverse Logo" />
          <Frame66172 brandverse={frame661721Props.brandverse} />
        </Frame46764>
        <Frame46765>
          <Group46610 src={group466101} alt="Group 46610" />
          <Frame66172 brandverse={frame661722Props.brandverse} />
          <Group466101 src={group466102} alt="Group 46610" />
        </Frame46765>
      </X5>
      <X6>
        <Frame46766>
          <OURCUSTOMERS>{ourCustomers}</OURCUSTOMERS>
          <WereTrustedBy250Companies>
            <span>
              <span className="poppins-bold-eerie-black-20px">{spanText16}</span>
              <span className="poppins-bold-blue-ribbon-20px">{spanText17}</span>
              <span className="poppins-bold-eerie-black-20px">{spanText18}</span>
            </span>
          </WereTrustedBy250Companies>
        </Frame46766>
        <Frame46635 src={frame46635} alt="Frame 46635" />
        <Image121 src={image121} alt="image 121" />
        <Image122 src={image122} alt="image 122" />
      </X6>
      <X7>
        <Frame467661>
          <WhatOurUsersSay>
            <span>
              <Span014>{spanText19}</Span014>
              <span className="poppins-bold-blaze-orange-16px">{spanText20}</span>
            </span>
          </WhatOurUsersSay>
          <READOurCustomerStories>
            <span>
              <Span015>{spanText21}</Span015>
              <span className="poppins-bold-blaze-orange-20px">{spanText22}</span>
            </span>
          </READOurCustomerStories>
        </Frame467661>
        <Frame46827>
          <Frame46768 surname={frame467681Props.surname} trueCorp={frame467681Props.trueCorp} />
          <Frame46768 surname={frame467682Props.surname} trueCorp={frame467682Props.trueCorp} />
          <Frame46768 surname={frame467683Props.surname} trueCorp={frame467683Props.trueCorp} />
          <Frame46768 surname={frame467684Props.surname} trueCorp={frame467684Props.trueCorp} />
        </Frame46827>
        <Cloud3 src={cloud4} alt="Cloud" />
        <Frame46625>
          <Copyright2023JenosizeTechnology>{copyright2023JenosizeTechnology}</Copyright2023JenosizeTechnology>
        </Frame46625>
      </X7>
      <X8>
        <Shadow></Shadow>
        <Frame467662>
          <PLANPRICING>{planPricing}</PLANPRICING>
          <PricingBuiltForB>{pricingBuiltForB}</PricingBuiltForB>
        </Frame467662>
        <Frame46828>
          <Frame46829>
            <Frame46773>
              <Title>{title1}</Title>
              <Frame46774>
                <Title1>{title2}</Title1>
                <List8 userLicense={list8Props.userLicense} />
              </Frame46774>
              <Divider src={divider1} alt="Divider" />
              <Frame46775
                title={frame467751Props.title}
                list81Props={frame467751Props.list81Props}
                list82Props={frame467751Props.list82Props}
                list83Props={frame467751Props.list83Props}
              />
              <Divider src={divider2} alt="Divider" />
              <Frame46775
                title={frame467752Props.title}
                list81Props={frame467752Props.list81Props}
                list82Props={frame467752Props.list82Props}
                list83Props={frame467752Props.list83Props}
              />
              <Divider src={divider3} alt="Divider" />
              <Frame46775
                title={frame467753Props.title}
                list81Props={frame467753Props.list81Props}
                list82Props={frame467753Props.list82Props}
                list83Props={frame467753Props.list83Props}
              />
              <Divider src={divider4} alt="Divider" />
              <Frame46778
                title={frame467781Props.title}
                list81Props={frame467781Props.list81Props}
                list82Props={frame467781Props.list82Props}
              />
              <Divider src={divider5} alt="Divider" />
              <Frame46778
                title={frame467782Props.title}
                list81Props={frame467782Props.list81Props}
                list82Props={frame467782Props.list82Props}
              />
              <Divider src={divider6} alt="Divider" />
              <Frame46778
                title={frame467783Props.title}
                list81Props={frame467783Props.list81Props}
                list82Props={frame467783Props.list82Props}
              />
              <PrimaryButton3 />
            </Frame46773>
            <Frame46773>
              <Title2>{title3}</Title2>
              <Frame46774>
                <Title3 className="hidden">{title4}</Title3>
                <List>
                  <BxsCheckCircle21 src={bxsCheckCircle21} alt="bxs-check-circle (2) 1" />
                  <Address>{address}</Address>
                </List>
              </Frame46774>
              <Divider src={divider7} alt="Divider" />
              <Frame46774>
                <Title3 className="hidden">{title5}</Title3>
                <List1 src={list1} alt="List" />
                <List1 src={list2} alt="List" />
                <List1 src={list3} alt="List" />
              </Frame46774>
              <Divider src={divider8} alt="Divider" />
              <Frame46774>
                <Title3 className="hidden">{title6}</Title3>
                <List9>{list91Props.children}</List9>
                <List9 className={list92Props.className}>{list92Props.children}</List9>
                <List9 className={list93Props.className}>{list93Props.children}</List9>
              </Frame46774>
              <Divider src={divider9} alt="Divider" />
              <Frame46774>
                <Title3 className="hidden">{title7}</Title3>
                <List1 src={list4} alt="List" />
                <List9 className={list94Props.className}>{list94Props.children}</List9>
                <List1 src={list5} alt="List" />
              </Frame46774>
              <Divider src={divider10} alt="Divider" />
              <Frame46774>
                <Title3 className="hidden">{title8}</Title3>
                <List1 src={list6} alt="List" />
                <List1 src={list7} alt="List" />
              </Frame46774>
              <Divider src={divider11} alt="Divider" />
              <Frame46774>
                <Title3 className="hidden">{title9}</Title3>
                <List1 src={list8} alt="List" />
                <List9>{list95Props.children}</List9>
              </Frame46774>
              <Divider src={divider12} alt="Divider" />
              <Frame46774>
                <Title3 className="hidden">{title10}</Title3>
                <List10>{list101Props.children}</List10>
                <List10>{list102Props.children}</List10>
              </Frame46774>
              <PrimaryButton3 className={primaryButton3Props.className} />
            </Frame46773>
          </Frame46829>
        </Frame46828>
        <Group46682>
          <Frame46771></Frame46771>
        </Group46682>
        <Frame46782>
          <Frame46733>
            <Group46683>
              <OverlapGroup2>
                <Image116 src={image116} alt="image 116" />
                <ExperienceInActiN>{experienceInActiN}</ExperienceInActiN>
              </OverlapGroup2>
            </Group46683>
            <WithToolsToMakeE>{withToolsToMakeE}</WithToolsToMakeE>
          </Frame46733>
          <BigButton1>
            <GetStarted>{getStartedForFree}</GetStarted>
          </BigButton1>
        </Frame46782>
        <Frame46712>
          <Ellipse49></Ellipse49>
        </Frame46712>
      </X8>
    </div>
  );
}

const Frame46814 = styled.div`
  display: flex;
  width: 375px;
  align-items: center;
  justify-content: space-between;
  padding: 12px 8px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--primaryblue-ryb);
`;

const JnzMktCloudLogo = styled.img`
  position: relative;
  width: 100px;
  height: 26px;
`;

const BigButton = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 10px;
  padding: 4px 12px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--primaryorange);
  border-radius: 200px;
`;

const GetStarted = styled.div`
  ${PoppinsSemiBoldWhite12px2}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const X1 = styled.div`
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 501px;
  background-color: var(--primaryblue-ryb);
  overflow: hidden;
`;

const Group46669 = styled.div`
  position: absolute;
  width: 487px;
  height: 94px;
  top: 407px;
  left: -56px;
`;

const ImageContainer = styled.div`
  position: relative;
  width: 375px;
  height: 94px;
  left: 56px;
`;

const Image107 = styled.img`
  position: absolute;
  width: 71px;
  height: 71px;
  top: 23px;
  left: 0;
  object-fit: cover;
`;

const Image108 = styled.img`
  position: absolute;
  width: 168px;
  height: 94px;
  top: 0;
  left: 20px;
  object-fit: cover;
`;

const Image110 = styled.img`
  position: absolute;
  width: 70px;
  height: 71px;
  top: 23px;
  left: 305px;
  object-fit: cover;
`;

const Image109 = styled.img`
  position: absolute;
  width: 168px;
  height: 94px;
  top: 0;
  left: 187px;
  object-fit: cover;
`;

const Cloud = styled.img`
  position: absolute;
  width: 375px;
  height: 102px;
  top: 399px;
  left: 0;
  object-fit: cover;
`;

const BG = styled.div`
  position: absolute;
  width: 375px;
  height: 374px;
  top: 314px;
  left: 0;
  border-radius: 187.5px/187px;
  transform: rotate(-180deg);
  background: linear-gradient(
    180deg,
    rgb(244.0000006556511, 246.0000005364418, 255) 0%,
    rgba(244.0000006556511, 246.0000005364418, 255, 0) 100%
  );
  opacity: 0.3;
`;

const Image123 = styled.img`
  position: absolute;
  width: 270px;
  height: 239px;
  top: 262px;
  left: 52px;
  object-fit: cover;
`;

const Frame46734 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  position: absolute;
  top: 71px;
  left: 23px;
`;

const Frame46733 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
`;

const OneAppToDoAll = styled.p`
  ${PoppinsBoldZircon20px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
`;

const FORYOURCustomerLove = styled.p`
  ${ValignTextMiddle}
  ${PoppinsBoldBlazeOrange20px}
            position: relative;
  width: fit-content;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
`;

const Frame46732 = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 8px 16px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--secondarywhite01);
  border-radius: 8px;
`;

const SeamlessCommerceE = styled.p`
  ${PoppinsNormalZircon12px}
  position: relative;
  width: 329px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
`;

const X2 = styled.div`
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 485px;
  background-color: var(--secondarywhite01);
`;

const BG1 = styled.img`
  position: absolute;
  width: 375px;
  height: 485px;
  top: 0;
  left: 0;
`;

const Mobile1 = styled.img`
  position: absolute;
  width: 270px;
  height: 216px;
  top: 0;
  left: 52px;
  object-fit: cover;
`;

const Frame46738 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  position: absolute;
  top: 225px;
  left: 16px;
`;

const Frame46737 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 0 0 auto;
`;

const WhatIs = styled.div`
  ${ValignTextMiddle}
  ${PoppinsBoldBlazeOrange12px}
            position: relative;
  width: 85px;
  height: 18px;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const JenosizeMarketingCloud = styled.div`
  ${ValignTextMiddle}
  ${PoppinsBoldBlueRibbon20px}
            position: relative;
  width: 343px;
  height: 24px;
  text-align: center;
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const JenosizeMarketingC = styled.p`
  ${PoppinsSemiBoldWhite12px}
  position: relative;
  width: 343px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
`;

const WithToolsToMakeE = styled.p`
  ${PoppinsNormalEbonyClay12px}
  position: relative;
  width: 343px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
`;

const Image120 = styled.img`
  position: absolute;
  width: 162px;
  height: 57px;
  top: 428px;
  left: 0;
  object-fit: cover;
`;

const X3 = styled.div`
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 1117px;
  background-color: var(--primaryblue-ryb);
`;

const Frame46750 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  position: absolute;
  top: 33px;
  left: 14px;
`;

const Frame46646 = styled.div`
  display: flex;
  flex-direction: column;
  width: 343px;
  align-items: flex-start;
  gap: 8px;
  position: relative;
  flex: 0 0 auto;
`;

const WhyJenosizeMarketingCloud = styled.p`
  ${ValignTextMiddle}
  position: relative;
  width: 343px;
  height: 17px;
  margin-top: -1px;
  font-family: var(--font-family-poppins);
  font-weight: 700;
  color: transparent;
  font-size: var(--font-size-s);
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const Span19 = styled.span`
  color: var(--primaryghost-white);
`;

const ShopChatAndSucce = styled.p`
  ${PoppinsBoldWhite16px}
  position: relative;
  width: 343px;
  letter-spacing: 0;
  line-height: normal;
`;

const Frame46746 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  position: relative;
  flex: 0 0 auto;
`;

const Frame46744 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  position: relative;
  flex: 0 0 auto;
`;

const Group46631 = styled.img`
  position: relative;
  width: 43.3740234375px;
  height: 36px;
`;

const Frame467431 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
`;

const Frame46742 = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
`;

const Number = styled.div`
  ${ValignTextMiddle}
  ${PoppinsBoldBlueRibbon16px}
            position: relative;
  width: 18px;
  height: 28px;
  margin-top: -2px;
  margin-left: -1px;
  -webkit-text-stroke: 1px var(--primaryghost-white);
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const AdministrativeControlHub = styled.div`
  ${ValignTextMiddle}
  ${PoppinsBoldZircon16px}
            position: relative;
  width: 321px;
  height: 28px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const EfficientlyManageU = styled.p`
  ${PoppinsLightZircon12px}
  position: relative;
  width: 343px;
  letter-spacing: 0;
  line-height: 18px;
`;

const Group46630 = styled.div`
  position: relative;
  width: 36px;
  height: 36px;
  background-size: 100% 100%;
`;

const Cloud1 = styled.img`
  position: absolute;
  width: 375px;
  height: 102px;
  top: 1015px;
  left: 0;
  object-fit: cover;
`;

const X4 = styled.div`
  display: flex;
  flex-direction: column;
  height: 805px;
  align-items: center;
  position: relative;
  align-self: stretch;
  width: 100%;
  background-color: var(--secondarywhite01);
`;

const Frame46826 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
  background-color: var(--secondarywhite01);
`;

const Image127 = styled.img`
  position: relative;
  width: 375px;
  height: 400px;
  object-fit: cover;
`;

const Frame46762 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  position: absolute;
  top: calc(50% + 42px);
  left: calc(50% - 172px);
`;

const MultiChannelInteraction = styled.div`
  ${PoppinsBoldBlazeOrange20px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const Address = styled.div`
  ${InterNormalTuna12px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const EnjoyASeamlessCha = styled.p`
  ${PoppinsNormalMulledWine12px}
  position: relative;
  width: 343px;
  letter-spacing: 0;
  line-height: 16.9px;
`;

const Frame46761 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  flex: 0 0 auto;
`;

const Frame46728 = styled.div`
  display: flex;
  width: 343px;
  align-items: flex-start;
  gap: 10px;
  padding: 12px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--secondarywhite01);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-color: var(--black-2);
`;

const ExperienceIntegrate = styled.p`
  ${PoppinsNormalMulledWine12px}
  position: relative;
  flex: 1;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 16.9px;
`;

const X5 = styled.div`
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 702px;
  background-color: var(--secondarywhite01);
`;

const Frame46763 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  position: absolute;
  top: 18px;
  left: 16px;
`;

const Group46679 = styled.div`
  position: relative;
  width: 345px;
  height: 86px;
  margin-right: -2px;
`;

const OverlapGroup = styled.div`
  position: relative;
  width: 343px;
  height: 86px;
`;

const WhatWeProvideToT = styled.p`
  ${ValignTextMiddle}
  ${PoppinsBoldWhite20px}
            position: absolute;
  width: 343px;
  height: 86px;
  top: 0;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
`;

const Image114 = styled.img`
  position: absolute;
  width: 27px;
  height: 11px;
  top: 69px;
  left: 19px;
  object-fit: cover;
`;

const Image117 = styled.img`
  position: relative;
  width: 50px;
  height: 5px;
  object-fit: cover;
`;

const Group46680 = styled.div`
  position: relative;
  width: 345px;
  height: 173px;
  margin-right: -2px;
`;

const OverlapGroup1 = styled.div`
  position: relative;
  width: 343px;
  height: 173px;
`;

const JenosizeTechnology = styled.p`
  ${PoppinsSemiBoldWhite12px}
  position: absolute;
  width: 343px;
  top: 0;
  left: 0;
  letter-spacing: 0;
  line-height: 19.2px;
`;

const Span012 = styled.span`
  font-family: var(--font-family-poppins);
  font-weight: 600;
  color: var(--primaryblue-ryb);
  font-size: var(--font-size-s);
`;

const Image115 = styled.img`
  position: absolute;
  width: 31px;
  height: 6px;
  top: 160px;
  left: 136px;
  object-fit: cover;
`;

const Cloud2 = styled.img`
  position: absolute;
  width: 375px;
  height: 102px;
  top: 600px;
  left: 0;
  object-fit: cover;
`;

const Frame46764 = styled.div`
  display: flex;
  flex-direction: column;
  width: 343px;
  align-items: flex-start;
  gap: 10px;
  padding: 12px;
  position: absolute;
  top: 316px;
  left: 16px;
  background-color: var(--secondarywhite01);
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid;
  border-color: var(--wild-sand);
`;

const BrandverseLogo = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 137px;
  height: 155px;
`;

const BrandverseLogo1 = styled.img`
  position: relative;
  width: 40px;
  height: 40px;
`;

const Frame46765 = styled.div`
  display: flex;
  flex-direction: column;
  width: 343px;
  align-items: flex-start;
  gap: 10px;
  padding: 12px;
  position: absolute;
  top: 487px;
  left: 16px;
  background-color: var(--secondarywhite01);
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid;
  border-color: var(--wild-sand);
`;

const Group46610 = styled.img`
  position: relative;
  width: 38px;
  height: 40px;
`;

const Group466101 = styled.img`
  position: absolute;
  width: 47.64%;
  height: 100%;
  top: 0;
  left: 0;
`;

const X6 = styled.div`
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 228px;
  background-color: var(--secondarywhite01);
`;

const Frame46766 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  position: absolute;
  top: 22px;
  left: 16px;
`;

const OURCUSTOMERS = styled.div`
  ${ValignTextMiddle}
  ${PoppinsBoldBlazeOrange16px}
            position: relative;
  width: 343px;
  height: 18px;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

const WereTrustedBy250Companies = styled.p`
  ${ValignTextMiddle}
  ${PoppinsBoldWhite20px}
            position: relative;
  width: 343px;
  height: 59px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
`;

const Frame46635 = styled.img`
  position: absolute;
  width: 343px;
  height: 75px;
  top: 103px;
  left: 16px;
`;

const Image121 = styled.img`
  position: absolute;
  width: 144px;
  height: 50px;
  top: 178px;
  left: 231px;
  object-fit: cover;
`;

const Image122 = styled.img`
  position: absolute;
  width: 144px;
  height: 50px;
  top: 178px;
  left: 0;
  object-fit: cover;
`;

const X7 = styled.div`
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 306px;
  background-color: var(--primaryblue-ryb);
  overflow: hidden;
`;

const Frame467661 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  position: absolute;
  top: 20px;
  left: 16px;
`;

const WhatOurUsersSay = styled.div`
  ${ValignTextMiddle}
  ${PoppinsBoldWhite16px}
            position: relative;
  width: 343px;
  height: 18px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
`;

const Span014 = styled.span`
  font-family: var(--font-family-poppins);
  font-weight: 700;
  color: var(--secondarywhite01);
  font-size: var(--font-size-m);
`;

const READOurCustomerStories = styled.div`
  ${ValignTextMiddle}
  ${PoppinsBoldWhite20px}
            position: relative;
  width: 343px;
  height: 31px;
  letter-spacing: 0;
  line-height: normal;
`;

const Span015 = styled.span`
  font-family: var(--font-family-poppins);
  font-weight: 700;
  color: var(--secondarywhite01);
  font-size: var(--font-size-xl);
`;

const Frame46827 = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 12px;
  position: absolute;
  top: 86px;
  left: 16px;
`;

const Cloud3 = styled.img`
  position: absolute;
  width: 375px;
  height: 102px;
  top: 204px;
  left: 0;
  object-fit: cover;
`;

const Frame46625 = styled.div`
  display: flex;
  width: 375px;
  height: 40px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0px 10px;
  position: absolute;
  top: 246px;
  left: 0;
`;

const Copyright2023JenosizeTechnology = styled.div`
  ${ValignTextMiddle}
  ${PoppinsNormalZircon12px}
            position: relative;
  width: 219px;
  height: 29px;
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const X8 = styled.div`
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 1315px;
  background-color: var(--secondarywhite01);
`;

const Shadow = styled.div`
  position: absolute;
  width: 343px;
  height: 848px;
  top: 118px;
  left: 16px;
  background-color: var(--blue-zodiac);
  filter: blur(200px);
  opacity: 0.05;
`;

const Frame467662 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  position: absolute;
  top: 20px;
  left: 16px;
`;

const PLANPRICING = styled.div`
  ${ValignTextMiddle}
  ${PoppinsBoldBlazeOrange16px}
            position: relative;
  width: 343px;
  height: 22px;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
`;

const PricingBuiltForB = styled.p`
  ${ValignTextMiddle}
  ${PoppinsBoldBlueRibbon20px}
            position: relative;
  width: 343px;
  height: 52px;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
`;

const Frame46828 = styled.div`
  position: absolute;
  width: 343px;
  height: 848px;
  top: 118px;
  left: 16px;
  display: flex;
  overflow: hidden;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 0;
    display: none;
  }
`;

const Frame46829 = styled.div`
  ${InterBoldWoodsmoke20px}
  display: inline-flex;
  width: 520px;
  height: 848px;
  position: relative;
  align-items: flex-start;
  gap: 12px;
`;

const Frame46773 = styled.div`
  display: flex;
  flex-direction: column;
  width: 254px;
  align-items: flex-start;
  gap: 12px;
  padding: 12px;
  position: relative;
  background-color: var(--secondarywhite01);
  border-radius: 12px;
  overflow: hidden;
`;

const Title = styled.div`
  ${ValignTextMiddle}
  position: relative;
  align-self: stretch;
  height: 22px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 40px;
  white-space: nowrap;
`;

const Frame46774 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
`;

const Title1 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalWoodsmoke16px}
            position: relative;
  align-self: stretch;
  height: 22px;
  margin-top: -1px;
  opacity: 0.5;
  letter-spacing: 0;
  line-height: 40px;
  white-space: nowrap;
`;

const Divider = styled.img`
  position: relative;
  align-self: stretch;
  width: 100%;
  height: 1px;
  object-fit: cover;
`;

const Title2 = styled.div`
  ${ValignTextMiddle}
  position: relative;
  align-self: stretch;
  height: 22px;
  margin-top: -1px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
  white-space: nowrap;
`;

const Title3 = styled.div`
  ${ValignTextMiddle}
  ${InterNormalWoodsmoke16px}
            position: relative;
  align-self: stretch;
  height: 22px;
  margin-top: -1px;
  opacity: 0;
  letter-spacing: 0;
  line-height: 40px;
  white-space: nowrap;
`;

const List = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
`;

const BxsCheckCircle21 = styled.img`
  position: relative;
  width: 15px;
  height: 15px;
`;

const List1 = styled.img`
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
`;

const Group46682 = styled.div`
  position: absolute;
  width: 343px;
  height: 8px;
  top: 986px;
  left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bon-jour);
  border-radius: 200px;
`;

const Frame46771 = styled.div`
  height: 8px;
  margin-left: -87.9px;
  width: 255.14138793945312px;
  background-color: var(--primaryblue-ryb);
  border-radius: 200px;
`;

const Frame46782 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 11px;
  position: absolute;
  top: 1047px;
  left: 16px;
`;

const Group46683 = styled.div`
  position: relative;
  width: 278px;
  height: 34px;
`;

const OverlapGroup2 = styled.div`
  position: relative;
  width: 276px;
  height: 34px;
`;

const Image116 = styled.img`
  position: absolute;
  width: 33px;
  height: 14px;
  top: 11px;
  left: 218px;
  object-fit: cover;
`;

const ExperienceInActiN = styled.h1`
  ${ValignTextMiddle}
  ${PoppinsBoldBlueRibbon24px}
            position: absolute;
  width: 276px;
  height: 34px;
  top: 0;
  left: 0;
  text-align: center;
  letter-spacing: 0;
  line-height: 80px;
  white-space: nowrap;
`;

const BigButton1 = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 10px;
  padding: 12px 24px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--primaryorange);
  border-radius: 200px;
`;

const Frame46712 = styled.div`
  position: absolute;
  width: 375px;
  height: 68px;
  top: 1247px;
  left: 0;
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

const Ellipse49 = styled.div`
  margin-top: 19px;
  width: 18857px;
  height: 18857px;
  background-color: var(--primaryblue-ryb);
  border-radius: 9428.5px;
`;

export default Mobile;
