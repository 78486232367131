import React from "react";
import styled from "styled-components";
import { ValignTextMiddle, PoppinsMediumMulledWine16px, PoppinsSemiBoldEbonyClay20px } from "../../styledMixins";


function Frame46678(props) {
  const { trueCorp, surname, className } = props;

  return (
    <Frame466781 className={`frame-46678 ${className || ""}`}>
      <OverlapGroup className="overlap-group-6">
        <Ellipse48 className="ellipse-48-1"></Ellipse48>
        <TrueCorp className="true-corp">{trueCorp}</TrueCorp>
        <Surname className="surname">{surname}</Surname>
      </OverlapGroup>
    </Frame466781>
  );
}

const Frame466781 = styled.div`
  position: relative;
  width: 515px;
  height: 242px;
  background-color: var(--secondarywhite01);
  border-radius: 16px;
  overflow: hidden;
`;

const OverlapGroup = styled.div`
  position: relative;
  width: 669px;
  height: 669px;
  top: -66px;
  left: -81px;
`;

const Ellipse48 = styled.div`
  position: absolute;
  width: 475px;
  height: 475px;
  top: 97px;
  left: 97px;
  border-radius: 237.37px;
  transform: rotate(129.72deg);
  background: linear-gradient(
    180deg,
    rgb(244.0000006556511, 246.0000005364418, 255) 0%,
    rgba(244.0000006556511, 246.0000005364418, 255, 0) 100%
  );
`;

const TrueCorp = styled.div`
  ${ValignTextMiddle}
  ${PoppinsMediumMulledWine16px}
            position: absolute;
  width: 467px;
  height: 16px;
  top: 267px;
  left: 105px;
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const Surname = styled.p`
  ${ValignTextMiddle}
  ${PoppinsSemiBoldEbonyClay20px}
            position: absolute;
  width: 467px;
  height: 64px;
  top: 89px;
  left: 105px;
  letter-spacing: 0;
  line-height: 32px;
`;

const Address = styled.p`
  ${PoppinsSemiBoldEbonyClay20px}

  .frame-46678.frame-46683  & {
    height: unset;
  }
`;

const Address1 = styled.p`
  ${PoppinsSemiBoldEbonyClay20px}

  .frame-46678.frame-46683-1  & {
    height: unset;
  }
`;

const Address2 = styled.p`
  ${PoppinsSemiBoldEbonyClay20px}

  .frame-46678.frame-46683-2  & {
    height: unset;
  }
`;

export default Frame46678;
