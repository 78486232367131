import React from "react";
import styled from "styled-components";
import { PoppinsBoldBlazeOrange36px } from "../../styledMixins";


function Frame46675() {
  return (
    <Frame466751>
      <MultiChannelInteraction>Multi-Channel Interaction</MultiChannelInteraction>
    </Frame466751>
  );
}

const Frame466751 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
`;

const MultiChannelInteraction = styled.div`
  ${PoppinsBoldBlazeOrange36px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const Frame466752 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
`;

const MultiChannelInteraction1 = styled.div`
  ${PoppinsBoldBlazeOrange36px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

const Frame466753 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
`;

const MultiChannelInteraction2 = styled.div`
  ${PoppinsBoldBlazeOrange36px}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: normal;
`;

export default Frame46675;
