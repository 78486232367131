import React from "react";
import List8 from "../List8";
import styled from "styled-components";
import { InterNormalWoodsmoke16px, ValignTextMiddle } from "../../styledMixins";


function Frame46778(props) {
  const { title, list81Props, list82Props } = props;

  return (
    <Frame467781>
      <Title>{title}</Title>
      <List8 userLicense={list81Props.userLicense} />
      <List8 userLicense={list82Props.userLicense} />
    </Frame467781>
  );
}

const Frame467781 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  position: relative;
  align-self: stretch;
  width: 100%;
  flex: 0 0 auto;
`;

const Title = styled.div`
  ${ValignTextMiddle}
  ${InterNormalWoodsmoke16px}
            position: relative;
  align-self: stretch;
  height: 22px;
  margin-top: -1px;
  opacity: 0.5;
  letter-spacing: 0;
  line-height: 40px;
  white-space: nowrap;
`;

export default Frame46778;
