import React from "react";
import styled from "styled-components";
import {
  PoppinsBoldZircon16px,
  PoppinsBoldBlueRibbon16px,
  ValignTextMiddle,
  PoppinsLightZircon12px,
} from "../../styledMixins";


function Frame46743(props) {
  const { number, crm, enhanceSalesEffici } = props;

  return (
    <Frame467431>
      <Frame46742>
        <Number>{number}</Number>
        <CRM>{crm}</CRM>
      </Frame46742>
      <EnhanceSalesEffici>{enhanceSalesEffici}</EnhanceSalesEffici>
    </Frame467431>
  );
}

const Frame467431 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
`;

const Frame46742 = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
`;

const Number = styled.div`
  ${ValignTextMiddle}
  ${PoppinsBoldBlueRibbon16px}
            position: relative;
  width: 22px;
  height: 28px;
  margin-top: -2px;
  margin-left: -1px;
  -webkit-text-stroke: 1px var(--primaryghost-white);
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const CRM = styled.div`
  ${ValignTextMiddle}
  ${PoppinsBoldZircon16px}
            position: relative;
  width: 321px;
  height: 28px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const EnhanceSalesEffici = styled.p`
  ${PoppinsLightZircon12px}
  position: relative;
  width: 343px;
  letter-spacing: 0;
  line-height: 18px;
`;

export default Frame46743;
