import React from "react";
import styled from "styled-components";
import { PoppinsSemiBoldWhite16px2 } from "../../styledMixins";


function Frame46644() {
  return (
    <Frame466441>
      <BigButton>
        <GetStartedForFree>Get Started for Free!</GetStartedForFree>
      </BigButton>
    </Frame466441>
  );
}

const Frame466441 = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 16px;
  position: relative;
  flex: 0 0 auto;
`;

const BigButton = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 10px;
  padding: 12px 20px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--primaryorange);
  border-radius: 200px;
`;

const GetStartedForFree = styled.div`
  ${PoppinsSemiBoldWhite16px2}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Frame466442 = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 16px;
  position: relative;
  flex: 0 0 auto;
`;

const BigButton1 = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 10px;
  padding: 12px 20px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--primaryorange);
  border-radius: 200px;
`;

const GetStartedForFree1 = styled.div`
  ${PoppinsSemiBoldWhite16px2}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

const Frame466443 = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 16px;
  position: relative;
  flex: 0 0 auto;
`;

const BigButton2 = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 10px;
  padding: 12px 20px;
  position: relative;
  flex: 0 0 auto;
  background-color: var(--primaryorange);
  border-radius: 200px;
`;

const GetStartedForFree2 = styled.div`
  ${PoppinsSemiBoldWhite16px2}
  position: relative;
  width: fit-content;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 20px;
  white-space: nowrap;
`;

export default Frame46644;
