import React from "react";
import Group46668 from "../Group46668";
import styled from "styled-components";
import { InterBoldWoodsmoke32px } from "../../styledMixins";


function PricingCard10(props) {
  const { group46668Props } = props;

  return (
    <PricingCard101>
      <Title>BASIC</Title>
      <Group46668 className={group46668Props.className} listOfItems3Props={group46668Props.listOfItems3Props} />
    </PricingCard101>
  );
}

const PricingCard101 = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 320px;
  height: 1380px;
  background-color: var(--secondarywhite01);
  border-radius: 20px;
  overflow: hidden;
`;

const Title = styled.div`
  ${InterBoldWoodsmoke32px}
  margin-left: 15px;
  width: 281px;
  height: 40px;
  margin-top: 24px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
`;

const PricingCard102 = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 320px;
  height: 1380px;
  background-color: var(--secondarywhite01);
  border-radius: 20px;
  overflow: hidden;
`;

const Title1 = styled.div`
  ${InterBoldWoodsmoke32px}
  margin-left: 15px;
  width: 281px;
  height: 40px;
  margin-top: 24px;
  text-align: center;
  letter-spacing: 0;
  line-height: 40px;
`;

export default PricingCard10;
