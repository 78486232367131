import React from "react";
import Frame6621 from "../Frame6621";
import styled from "styled-components";


function HappyWork(props) {
  const { group46609, group46610, group46599, group46589, frame6621Props } = props;

  return (
    <HappyWork1>
      <OverlapGroup1>
        <Frame6621 group46609={frame6621Props.group46609} frame6617Props={frame6621Props.frame6617Props} />
        <Group46609 src={group46609} alt="Group 46609" />
        <Group46610 src={group46610} alt="Group 46610" />
      </OverlapGroup1>
      <Group46599 style={{ backgroundImage: `url(${group46599})` }}>
        <Group46589 src={group46589} alt="Group 46589" />
      </Group46599>
    </HappyWork1>
  );
}

const HappyWork1 = styled.div`
  position: relative;
  width: 624px;
  height: 308px;
  background-color: var(--secondarywhite01);
  border-radius: 24px;
  overflow: hidden;
  border: 1px solid;
  border-color: var(--wild-sand);
`;

const OverlapGroup1 = styled.div`
  position: absolute;
  width: 515px;
  height: 308px;
  top: 0;
  left: 0;
`;

const Group46609 = styled.img`
  position: absolute;
  width: 408px;
  height: 274px;
  top: 18px;
  left: 0;
`;

const Group46610 = styled.img`
  position: absolute;
  width: 293px;
  height: 308px;
  top: 0;
  left: 0;
`;

const Group46599 = styled.div`
  position: absolute;
  height: 683px;
  top: -2245px;
  left: -639px;
  display: flex;
  align-items: flex-start;
  min-width: 406px;
  background-size: cover;
  background-position: 50% 50%;
`;

const Group46589 = styled.img`
  width: 264px;
  height: 575px;
  margin-left: -1412px;
  margin-top: -2190.71px;
`;

export default HappyWork;
