import React from "react";
import styled from "styled-components";
import {
  PoppinsBoldBlueRibbon24px,
  PoppinsBoldZircon24px,
  ValignTextMiddle,
  PoppinsLightZircon16px,
} from "../../styledMixins";


function Frame6606(props) {
  const { discount, number, crm, enhanceSalesEffici } = props;

  return (
    <Frame66061>
      <Discount src={discount} alt="discount" />
      <Frame66062>
        <Frame46671>
          <Number>{number}</Number>
          <CRM>{crm}</CRM>
        </Frame46671>
        <EnhanceSalesEffici>{enhanceSalesEffici}</EnhanceSalesEffici>
      </Frame66062>
    </Frame66061>
  );
}

const Frame66061 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
`;

const Discount = styled.img`
  position: relative;
  width: 48px;
  height: 48px;
`;

const Frame66062 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  flex: 0 0 auto;
`;

const Frame46671 = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
`;

const Number = styled.div`
  ${ValignTextMiddle}
  ${PoppinsBoldBlueRibbon24px}
            position: relative;
  width: 33px;
  height: 52px;
  margin-top: -2px;
  margin-left: -1px;
  -webkit-text-stroke: 1px var(--primaryghost-white);
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const CRM = styled.div`
  ${ValignTextMiddle}
  ${PoppinsBoldZircon24px}
            position: relative;
  width: 296px;
  height: 52px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const EnhanceSalesEffici = styled.p`
  ${PoppinsLightZircon16px}
  position: relative;
  width: 405px;
  letter-spacing: 0;
  line-height: 24px;
`;

const Frame66063 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
`;

const Discount1 = styled.img`
  position: relative;
  width: 48px;
  height: 48px;
`;

const Frame66064 = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  flex: 0 0 auto;
`;

const Frame466711 = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 4px;
  position: relative;
  flex: 0 0 auto;
`;

const Number1 = styled.div`
  ${ValignTextMiddle}
  ${PoppinsBoldBlueRibbon24px}
            position: relative;
  width: 33px;
  height: 52px;
  margin-top: -2px;
  margin-left: -1px;
  -webkit-text-stroke: 1px var(--primaryghost-white);
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const CRM1 = styled.div`
  ${ValignTextMiddle}
  ${PoppinsBoldZircon24px}
            position: relative;
  width: 296px;
  height: 52px;
  margin-top: -1px;
  letter-spacing: 0;
  line-height: 64px;
  white-space: nowrap;
`;

const EnhanceSalesEffici1 = styled.p`
  ${PoppinsLightZircon16px}
  position: relative;
  width: 405px;
  letter-spacing: 0;
  line-height: 24px;
`;

export default Frame6606;
