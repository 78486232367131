
import { css } from "styled-components";
import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Desktop from "./components/Desktop";
import Mobile from "./components/Mobile";
import TabletLarge from "./components/TabletLarge";
import TabletSmall from "./components/TabletSmall";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/:path(|desktop)">
          <Desktop {...desktopData} />
        </Route>
        <Route path="/mobile">
          <Mobile {...mobileData} />
        </Route>
        <Route path="/tablet-large">
          <TabletLarge {...tabletLargeData} />
        </Route>
        <Route path="/tablet-small">
          <TabletSmall {...tabletSmallData} />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
const frame66051Data = {
    group46631: "/img/group-46631@2x.png",
};

const frame66061Data = {
    discount: "/img/discount@2x.png",
    number: "02",
    crm: "CRM",
    enhanceSalesEffici: "Enhance sales efficiency with all-in-one Customer Management, tracking lists, details, activity, orders, and seamless data input.",
};

const frame66062Data = {
    discount: "/img/coin-free@2x.png",
    number: "03",
    crm: "Marketing",
    enhanceSalesEffici: "Efficiently manage Tag & Audience, automate LINE OA flows, and easily handle LINE OA Rich menu and Flex message configurations.",
};

const frame66071Data = {
    group46630: "/img/analytics@2x.png",
};

const frame66063Data = {
    discount: "/img/zoom-in@2x.png",
    number: "06",
    crm: "Reports",
    enhanceSalesEffici: "Insightful Transaction, Leads, Customer Reports & Branch Data Export for smart business analysis and decision-making.",
};

const group466631Data = {
    simplifyShoppingExperienceWithChat: "Simplify shopping experience with Chat",
    text1: "",
};

const group466632Data = {
    simplifyShoppingExperienceWithChat: "Serve Customer Convenience",
    text1: "",
    className: "group-46658",
};

const group466633Data = {
    simplifyShoppingExperienceWithChat: "Integrated Chat & Shop Experience",
    text1: "",
    className: "group-46659",
};

const group466634Data = {
    simplifyShoppingExperienceWithChat: "Influences consumer choices, boosts sales",
    text1: "",
    className: "group-46660",
};

const frame66171Data = {
    brandverse: "Brandverse",
};

const brandverseData = {
    brandverseLogo1: "/img/brandverse-logo@2x.png",
    brandverseLogo2: "/img/brandverse-logo-1@2x.png",
    group46599: "/img/iphone13promaxmockup1fp-copy-1-2.png",
    group46589: "/img/iphone13promaxmockup1fp-copy-1-2.png",
    frame6617Props: frame66171Data,
};

const frame66172Data = {
    brandverse: "HappyWork",
};

const frame66211Data = {
    group46609: "/img/group-46609-1@2x.png",
    frame6617Props: frame66172Data,
};

const happyWorkData = {
    group46609: "/img/group-46609.png",
    group46610: "/img/group-46610@2x.png",
    group46599: "/img/iphone13promaxmockup1fp-copy-1-2.png",
    group46589: "/img/iphone13promaxmockup1fp-copy-1-2.png",
    frame6621Props: frame66211Data,
};

const frame466781Data = {
    trueCorp: "#TRUE CORP.",
    surname: "BEST SOFTWARE I'VE EVER USED, SUPER HIGH QUALITY AND EASY-TO-USE.",
};

const frame466782Data = {
    trueCorp: "#PROPERTY PERFECT O2O CRM",
    surname: "38 Single Houses, 2 Resort Residences, 14 Townhomes, And 6 Condominiums Sold - Fulfilling Diverse Housing Needs With Excellence.",
    className: "frame-46683",
};

const frame466841Data = {
    bigCCallChatShop: "#BIG C CALL CHAT & SHOP",
    surpassing100KActi: "Surpassing 100k Active Users Within 2 Months And Driving Over 10m Baht Revenue From New Channels.",
};

const frame466842Data = {
    bigCCallChatShop: "#7-ELEVEN CHAT & SHOP",
    surpassing100KActi: "Successfully Launched 600 Branches In Phase I And An Additional ~10k Branches In The Subsequent Phase.",
};

const frame466821Data = {
    frame466781Props: frame466781Data,
    frame466782Props: frame466782Data,
    frame466841Props: frame466841Data,
    frame466842Props: frame466842Data,
};

const frame467071Data = {
    frame466781Props: frame466821Data,
};

const list1Data = {
    userLicense: "User License",
};

const list2Data = {
    userLicense: "LineOA Web Commerce",
    className: "list-1",
};

const list3Data = {
    userLicense: "Product Management",
    className: "list-2",
};

const listOfItems1Data = {
    supportedChannel: "Supported Channel",
    orderManagement: "Order Management",
    list1Props: list2Data,
    list2Props: list3Data,
};

const list4Data = {
    userLicense: "Line OA",
    className: "list-3",
};

const list5Data = {
    userLicense: "Facebook Messenger",
    className: "list-4",
};

const listOfItems2Data = {
    supportedChannel: "Chat",
    orderManagement: "Chat Log",
    className: "list-of-items-5",
    list1Props: list4Data,
    list2Props: list5Data,
};

const list6Data = {
    userLicense: "Chatbot",
    className: "list-5",
};

const list7Data = {
    userLicense: "Automation flow",
    className: "list-6",
};

const listOfItems3Data = {
    supportedChannel: "Marketing",
    orderManagement: "Customer tag",
    className: "list-of-items-6",
    list1Props: list6Data,
    list2Props: list7Data,
};

const list8Data = {
    userLicense: "Report",
    className: "list-7",
};

const list9Data = {
    userLicense: "Dashboard",
    className: "list-8",
};

const listOfItems22Data = {
    analytic: "Analytic",
    list1Props: list8Data,
    list2Props: list9Data,
};

const list10Data = {
    userLicense: "Onboarding & rollout support",
    className: "list-9",
};

const list11Data = {
    userLicense: "SLA Support",
    className: "list-10",
};

const listOfItems23Data = {
    analytic: "Services",
    className: "list-of-items-13",
    list1Props: list10Data,
    list2Props: list11Data,
};

const list12Data = {
    userLicense: "One-time Setup Fees (THB)",
    className: "list-11",
};

const list13Data = {
    userLicense: "Per Company / Month (THB)",
    className: "list-12",
};

const listOfItems24Data = {
    analytic: "Investment",
    list1Props: list12Data,
    list2Props: list13Data,
};

const pricingCard41Data = {
    listProps: list1Data,
    listOfItems1Props: listOfItems1Data,
    listOfItems2Props: listOfItems2Data,
    listOfItems3Props: listOfItems3Data,
    listOfItems21Props: listOfItems22Data,
    listOfItems22Props: listOfItems23Data,
    listOfItems23Props: listOfItems24Data,
};

const listOfItems32Data = {
    listOfItems1: "/img/bxs-check-circle--2--1@2x.png",
    listOfItems2: "/img/bxs-check-circle--2--1@2x.png",
    listOfItems3: "/img/bxs-check-circle--2--1@2x.png",
};

const group466681Data = {
    listOfItems3Props: listOfItems32Data,
};

const listOfItems33Data = {
    listOfItems1: "/img/bxs-check-circle--2--1-17@2x.png",
    listOfItems2: "/img/bxs-check-circle--2--1-17@2x.png",
    listOfItems3: "/img/bxs-check-circle--2--1-17@2x.png",
    className: "list-of-items-29",
};

const bigButton1Data = {
    children: "Get Started for Free!",
};

const desktopData = {
    jnzMktCloudLogo: "/img/jnz-mkt-cloud-logo@2x.png",
    image107: "/img/image-107@2x.png",
    image108: "/img/image-108@2x.png",
    image110: "/img/image-110@2x.png",
    image109: "/img/image-109@2x.png",
    cloud1: "/img/cloud.png",
    overlapGroup1: "/img/iphone13promaxmockup1fp-copy-1.png",
    group465891: "/img/group-46589.png",
    overlapGroup: "/img/iphone13promaxmockup1fp-copy-1-1.png",
    group465892: "/img/group-46589-1.png",
    x02_F3_14: "/img/02-f3-1-4@2x.png",
    x03_M1_31: "/img/03-m1-3-1@2x.png",
    image1201: "/img/image-120@2x.png",
    bg: "/img/bg.png",
    cloud2: "/img/cloud-1.png",
    vector11: "/img/vector-1.png",
    vector12: "/img/vector-1.png",
    vector13: "/img/vector-1.png",
    vector14: "/img/vector-1.png",
    enjoyASeamlessCha: "Enjoy a seamless chat-to-shop journey with customer chat options through LINE OA, FB Messenger, or web chat, assisted by chatbots for easy and efficient deal closures.",
    experienceIntegrate: "Experience Integrated Chat & Checkout, Live Chat with Store's Staff, Choose Delivery Types, Date & Time Slots, Payment Methods, and View Online Bills.",
    image119: "/img/image-119.png",
    image105: "/img/image-105.png",
    group: "/img/group@2x.png",
    spanText1: "What we ",
    spanText2: "provide to the community, ",
    spanText3: "nation and the w       rld",
    image114: "/img/image-114@2x.png",
    spanText4: "Jenosize Technology",
    spanText5: " is more than a conventional IT or software company that merely fulfills coding requirements. Our approach goes beyond that. We possess a deep understanding of your business needs and challenges, allowing us to identify opportunities where technology can play a pivotal role in supporting and fostering sustainable growth for your business. Please browse through some of our key ",
    spanText6: "highlight products",
    spanText7: " ",
    spanText8: "bel            w",
    image115: "/img/image-115@2x.png",
    maskGroup1: "/img/mask-group.png",
    maskGroup2: "/img/mask-group-1.png",
    frame46635: "/img/frame-46635.png",
    image121: "/img/image-121@2x.png",
    image1202: "/img/image-120-1@2x.png",
    copyright2023JenosizeTechnology: "Copyright 2023 Jenosize Technology",
    vector15: "/img/vector-1-4.png",
    vector16: "/img/vector-1-4.png",
    vector17: "/img/vector-1-4.png",
    vector18: "/img/vector-1-4.png",
    cloud3: "/img/cloud-1.png",
    title1: "BASIC",
    title2: "PRO",
    divider1: "/img/divider-6@2x.png",
    divider2: "/img/divider-6@2x.png",
    divider3: "/img/divider-6@2x.png",
    divider4: "/img/divider-6@2x.png",
    listOfItems1: "/img/list-of-items-7@2x.png",
    divider5: "/img/divider-6@2x.png",
    divider6: "/img/divider-6@2x.png",
    pricing1: "100,000",
    pricing2: "30,000",
    title3: "ENTERPRISE",
    divider7: "/img/divider-6@2x.png",
    divider8: "/img/divider-6@2x.png",
    divider9: "/img/divider-6@2x.png",
    divider10: "/img/divider-6@2x.png",
    divider11: "/img/divider-6@2x.png",
    listOfItems2: "/img/bxs-check-circle--2--1@2x.png",
    divider12: "/img/divider-6@2x.png",
    pricing3: "Quote",
    pricing4: "Quote",
    withToolsToMakeE: "With tools to make every part of your process more human and a support team excited to help you, getting started has never been easier",
    image122: "/img/image-122.png",
    frame6605Props: frame66051Data,
    frame66061Props: frame66061Data,
    frame66062Props: frame66062Data,
    frame6607Props: frame66071Data,
    frame66063Props: frame66063Data,
    group466631Props: group466631Data,
    group466632Props: group466632Data,
    group466633Props: group466633Data,
    group466634Props: group466634Data,
    brandverseProps: brandverseData,
    happyWorkProps: happyWorkData,
    frame46707Props: frame467071Data,
    pricingCard4Props: pricingCard41Data,
    group46668Props: group466681Data,
    listOfItems3Props: listOfItems33Data,
    bigButtonProps: bigButton1Data,
};

const frame467431Data = {
    number: "02",
    crm: "CRM",
    enhanceSalesEffici: "Enhance sales efficiency with all-in-one Customer Management, tracking lists, details, activity, orders, and seamless data input.",
};

const frame467451Data = {
    discount: "/img/discount-1@2x.png",
    frame46743Props: frame467431Data,
};

const frame467432Data = {
    number: "03",
    crm: "Marketing",
    enhanceSalesEffici: "Efficiently manage Tag & Audience, automate LINE OA flows, and easily handle LINE OA Rich menu and Flex message configurations.",
};

const frame467452Data = {
    discount: "/img/coin-free-1@2x.png",
    frame46743Props: frame467432Data,
};

const frame467433Data = {
    number: "04",
    crm: "ChatHub",
    enhanceSalesEffici: "Real-time customer communication, automated responses, and comprehensive interaction logs for seamless support and insights",
};

const frame467453Data = {
    discount: "/img/clipboard-check-1@2x.png",
    frame46743Props: frame467433Data,
};

const frame467434Data = {
    number: "05",
    crm: "Omni Channel Commerce",
    enhanceSalesEffici: "Omni-channel commerce, integrated LINE OA, and streamlined management for web commerce, products, orders, and coupons.",
};

const frame467435Data = {
    number: "06",
    crm: "Reports",
    enhanceSalesEffici: "Insightful Transaction, Leads, Customer Reports & Branch Data Export for smart business analysis and decision-making.",
};

const frame467454Data = {
    discount: "/img/zoom-in-1@2x.png",
    frame46743Props: frame467435Data,
};

const group466002Data = {
    className: "group-46600-1",
};

const frame467231Data = {
    simplifyShoppingExperienceWithChat: "Simplify shopping experience with Chat",
};

const frame467232Data = {
    simplifyShoppingExperienceWithChat: "Serve Customer Convenience",
};

const frame467233Data = {
    simplifyShoppingExperienceWithChat: "Integrated Chat & Shop Experience",
};

const frame467234Data = {
    simplifyShoppingExperienceWithChat: "Influences consumer choices, boosts sales",
};

const frame661722Data = {
    brandverse: "Brandverse",
};

const frame661723Data = {
    brandverse: "HappyWork",
};

const frame467681Data = {
    surname: "Best Software I've ever used, super high quality and easy-to-use.",
    trueCorp: "#True Corp.",
};

const frame467682Data = {
    surname: "38 single houses, 2 resort residences, 14 townhomes, and 6 condominiums sold - fulfilling diverse housing needs with excellence.",
    trueCorp: "#Property Perfect O2O CRM",
};

const frame467683Data = {
    surname: "38 single houses, 2 resort residences, 14 townhomes, and 6 condominiums sold - fulfilling diverse housing needs with excellence.",
    trueCorp: "#Property Perfect O2O CRM",
};

const frame467684Data = {
    surname: "38 single houses, 2 resort residences, 14 townhomes, and 6 condominiums sold - fulfilling diverse housing needs with excellence.",
    trueCorp: "#Property Perfect O2O CRM",
};

const list82Data = {
    userLicense: "User License",
};

const list83Data = {
    userLicense: "LineOA Web Commerce",
};

const list84Data = {
    userLicense: "Product Management",
};

const list85Data = {
    userLicense: "Order Management",
};

const frame467751Data = {
    title: "Supported Channel",
    list81Props: list83Data,
    list82Props: list84Data,
    list83Props: list85Data,
};

const list86Data = {
    userLicense: "Line OA",
};

const list87Data = {
    userLicense: "Facebook Messenger",
};

const list88Data = {
    userLicense: "Chat Log",
};

const frame467752Data = {
    title: "Chat",
    list81Props: list86Data,
    list82Props: list87Data,
    list83Props: list88Data,
};

const list89Data = {
    userLicense: "Chatbot",
};

const list810Data = {
    userLicense: "Automation flow",
};

const list811Data = {
    userLicense: "Customer tag",
};

const frame467753Data = {
    title: "Marketing",
    list81Props: list89Data,
    list82Props: list810Data,
    list83Props: list811Data,
};

const list812Data = {
    userLicense: "Report",
};

const list813Data = {
    userLicense: "Dashboard",
};

const frame467781Data = {
    title: "Analytic",
    list81Props: list812Data,
    list82Props: list813Data,
};

const list814Data = {
    userLicense: "Onboarding & rollout support",
};

const list815Data = {
    userLicense: "SLA Support",
};

const frame467782Data = {
    title: "Services",
    list81Props: list814Data,
    list82Props: list815Data,
};

const list816Data = {
    userLicense: "One-time Setup Fees (THB)",
};

const list817Data = {
    userLicense: "Per Company / Month (THB)",
};

const frame467783Data = {
    title: "Investment",
    list81Props: list816Data,
    list82Props: list817Data,
};

const list92Data = {
    children: "1000 messages",
};

const list93Data = {
    children: "1000 messages",
    className: "list-60",
};

const list94Data = {
    children: "Unlimited",
    className: "list-61",
};

const list95Data = {
    children: "10 flows",
    className: "list-62",
};

const list96Data = {
    children: "3-5 business days",
};

const list102Data = {
    children: "100,000",
};

const list103Data = {
    children: "10,000",
};

const primaryButton33Data = {
    className: "primary-button-5",
};

const mobileData = {
    jnzMktCloudLogo: "/img/jnz-mkt-cloud-logo-1@2x.png",
    getStarted: "Get Started",
    image107: "/img/image-107-1@2x.png",
    image108: "/img/image-108-1@2x.png",
    image110: "/img/image-110-1@2x.png",
    image109: "/img/image-109-1@2x.png",
    cloud1: "/img/cloud-3@2x.png",
    image123: "/img/image-123@2x.png",
    oneAppToDoAll: "ONE APP TO DO ALL",
    forYourCustomerLove: "“FOR YOUR  CUSTOMER LOVE”",
    seamlessCommerceE: <React.Fragment>Seamless commerce, elevated CX with our <br />Omni-Channel MarTech. Experience our powerhouse Chat Engine and Data Collection. Empower your success with CRM + Commerce integration.</React.Fragment>,
    bg: "/img/bg-1@2x.png",
    mobile: "/img/mobile@2x.png",
    whatIs: "WHAT IS",
    jenosizeMarketingCloud: "JENOSIZE MARKETING CLOUD?",
    spanText1: "Jenosize Marketing Cloud",
    spanText2: " is a cloud-based customer experience solution platform that provides businesses with a suite of tools and services to manage their sales, marketing, customer service, and other business operations. It offers a wide range of features and functionalities, including single customer profile IDs, multi-channel interactions, Omni-channel commerce, CRM, helpdesk, marketing and loyalty.",
    image120: "/img/image-120-2@2x.png",
    spanText3: "Why",
    spanText4: "  Jenosize Marketing Cloud",
    spanText5: <React.Fragment>Shop, chat and succeed with our <br /></React.Fragment>,
    spanText6: "all-in-one",
    spanText7: " solution",
    group46631: "/img/group-46631-1@2x.png",
    number: "01",
    administrativeControlHub: "Administrative Control Hub",
    efficientlyManageU: "Efficiently manage user accounts, define roles and permissions, and oversee company and branch operations all from a single integrated platform.",
    group46630: "/img/analytics-1@2x.png",
    cloud2: "/img/cloud-3@2x.png",
    image127: "/img/image-127@2x.png",
    multiChannelInteraction: "Multi-Channel Interaction",
    enjoyASeamlessCha: "Enjoy a seamless chat-to-shop journey with customer chat options through LINE OA, FB Messenger, or web chat, assisted by chatbots for easy and efficient deal closures.",
    experienceIntegrate: "Experience Integrated Chat & Checkout, Live Chat with Store's Staff, Choose Delivery Types, Date & Time Slots, Payment Methods, and View Online Bills.",
    spanText8: "What we ",
    spanText9: "provide to the community, ",
    spanText10: "nation and the w       rld",
    image114: "/img/image-114-1@2x.png",
    image117: "/img/image-117@2x.png",
    spanText11: "Jenosize Technology",
    spanText12: " is more than a conventional IT or software company that merely fulfills coding requirements. Our approach goes beyond that. We possess a deep understanding of your business needs and challenges, allowing us to identify opportunities where technology can play a pivotal role in supporting and fostering sustainable growth for your business. Please browse through some of our key ",
    spanText13: "highlight products",
    spanText14: " ",
    spanText15: "bel            w",
    image115: "/img/image-115-1@2x.png",
    cloud3: "/img/cloud-3@2x.png",
    brandverseLogo1: "/img/brandverse-logo-2@2x.png",
    brandverseLogo2: "/img/brandverse-logo-3@2x.png",
    group466101: "/img/group-46610-1@2x.png",
    group466102: "/img/group-46610-2@2x.png",
    ourCustomers: "OUR CUSTOMERS",
    spanText16: "We're ",
    spanText17: "trusted by 250+ ",
    spanText18: "companies.",
    frame46635: "/img/frame-46635-1@2x.png",
    image121: "/img/image-121-1@2x.png",
    image122: "/img/image-122-1@2x.png",
    spanText19: "What",
    spanText20: " our users say",
    spanText21: "READ our ",
    spanText22: "customer stories",
    cloud4: "/img/cloud-3@2x.png",
    copyright2023JenosizeTechnology: "Copyright 2023 Jenosize Technology",
    planPricing: "PLAN & PRICING",
    pricingBuiltForB: <React.Fragment>PRICING BUILT FOR <br />BUSINESSES OF ALL SIZES</React.Fragment>,
    title1: "Features",
    title2: "Users",
    divider1: "/img/divider-24@2x.png",
    divider2: "/img/divider-24@2x.png",
    divider3: "/img/divider-24@2x.png",
    divider4: "/img/divider-24@2x.png",
    divider5: "/img/divider-24@2x.png",
    divider6: "/img/divider-24@2x.png",
    title3: "BASIC",
    title4: "Users",
    bxsCheckCircle21: "/img/bxs-check-circle--2--1-34@2x.png",
    address: "2 admin/5 staff/1 branch",
    divider7: "/img/divider-24@2x.png",
    title5: "Supported Channel",
    list1: "/img/list@2x.png",
    list2: "/img/list@2x.png",
    list3: "/img/list@2x.png",
    divider8: "/img/divider-24@2x.png",
    title6: "Chat",
    divider9: "/img/divider-24@2x.png",
    title7: "Marketing",
    list4: "/img/list@2x.png",
    list5: "/img/list@2x.png",
    divider10: "/img/divider-24@2x.png",
    title8: "Analytic",
    list6: "/img/list@2x.png",
    list7: "/img/list@2x.png",
    divider11: "/img/divider-24@2x.png",
    title9: "Services",
    list8: "/img/list@2x.png",
    divider12: "/img/divider-24@2x.png",
    title10: "Investment",
    image116: "/img/image-116-1@2x.png",
    experienceInActiN: "Experience in acti       n.",
    withToolsToMakeE: "With tools to make every part of your process more human and a support team excited to help you, getting started has never been easier",
    getStartedForFree: "Get Started for Free!",
    frame467451Props: frame467451Data,
    frame467452Props: frame467452Data,
    frame467453Props: frame467453Data,
    frame46743Props: frame467434Data,
    frame467454Props: frame467454Data,
    group46600Props: group466002Data,
    frame467231Props: frame467231Data,
    frame467232Props: frame467232Data,
    frame467233Props: frame467233Data,
    frame467234Props: frame467234Data,
    frame661721Props: frame661722Data,
    frame661722Props: frame661723Data,
    frame467681Props: frame467681Data,
    frame467682Props: frame467682Data,
    frame467683Props: frame467683Data,
    frame467684Props: frame467684Data,
    list8Props: list82Data,
    frame467751Props: frame467751Data,
    frame467752Props: frame467752Data,
    frame467753Props: frame467753Data,
    frame467781Props: frame467781Data,
    frame467782Props: frame467782Data,
    frame467783Props: frame467783Data,
    list91Props: list92Data,
    list92Props: list93Data,
    list93Props: list94Data,
    list94Props: list95Data,
    list95Props: list96Data,
    list101Props: list102Data,
    list102Props: list103Data,
    primaryButton3Props: primaryButton33Data,
};

const frame468132Data = {
    className: "frame-46813-1",
};

const frame466702Data = {
    className: "frame-46670-1",
};

const frame66053Data = {
    group46631: "/img/group-46631-1@2x.png",
    className: "frame-6605-1",
};

const frame66064Data = {
    discount: "/img/discount@2x.png",
    number: "02",
    crm: "CRM",
    enhanceSalesEffici: "Enhance sales efficiency with all-in-one Customer Management, tracking lists, details, activity, orders, and seamless data input.",
};

const frame66065Data = {
    discount: "/img/coin-free-1@2x.png",
    number: "03",
    crm: "Marketing",
    enhanceSalesEffici: "Efficiently manage Tag & Audience, automate LINE OA flows, and easily handle LINE OA Rich menu and Flex message configurations.",
};

const frame66072Data = {
    group46630: "/img/analytics-1@2x.png",
    className: "frame-6607-1",
};

const frame66066Data = {
    discount: "/img/zoom-in-1@2x.png",
    number: "06",
    crm: "Reports",
    enhanceSalesEffici: "Insightful Transaction, Leads, Customer Reports & Branch Data Export for smart business analysis and decision-making.",
};

const frame4672342Data = {
    simplifyShoppingExperienceWithChat: "Simplify shopping experience with Chat",
};

const frame4672343Data = {
    simplifyShoppingExperienceWithChat: "Serve Customer Convenience",
};

const frame4672344Data = {
    simplifyShoppingExperienceWithChat: "Integrated Chat & Shop Experience",
};

const frame4672345Data = {
    simplifyShoppingExperienceWithChat: "Influences consumer choices, boosts sales",
};

const frame467032Data = {
    className: "frame-46703-1",
};

const frame66173Data = {
    brandverse: "Brandverse",
};

const frame66174Data = {
    brandverse: "HappyWork",
};

const frame66212Data = {
    group46609: "/img/group-46609-3@2x.png",
    frame6617Props: frame66174Data,
};

const frame466783Data = {
    trueCorp: "#TRUE CORP.",
    surname: "BEST SOFTWARE I'VE EVER USED, SUPER HIGH QUALITY AND EASY-TO-USE.",
};

const frame466784Data = {
    trueCorp: "#PROPERTY PERFECT O2O CRM",
    surname: "38 Single Houses, 2 Resort Residences, 14 Townhomes, And 6 Condominiums Sold - Fulfilling Diverse Housing Needs With Excellence.",
    className: "frame-46683-1",
};

const frame466843Data = {
    bigCCallChatShop: "#BIG C CALL CHAT & SHOP",
    surpassing100KActi: "Surpassing 100k Active Users Within 2 Months And Driving Over 10m Baht Revenue From New Channels.",
};

const frame466844Data = {
    bigCCallChatShop: "#7-ELEVEN CHAT & SHOP",
    surpassing100KActi: "Successfully Launched 600 Branches In Phase I And An Additional ~10k Branches In The Subsequent Phase.",
};

const frame466822Data = {
    frame466781Props: frame466783Data,
    frame466782Props: frame466784Data,
    frame466841Props: frame466843Data,
    frame466842Props: frame466844Data,
};

const frame467072Data = {
    className: "frame-46707-1",
    frame466781Props: frame466822Data,
};

const frame662222Data = {
    className: "frame-6622-2",
};

const list14Data = {
    userLicense: "User License",
};

const list15Data = {
    userLicense: "LineOA Web Commerce",
    className: "list-14",
};

const list16Data = {
    userLicense: "Product Management",
    className: "list-15",
};

const listOfItems13Data = {
    supportedChannel: "Supported Channel",
    orderManagement: "Order Management",
    list1Props: list15Data,
    list2Props: list16Data,
};

const list17Data = {
    userLicense: "Line OA",
    className: "list-16",
};

const list18Data = {
    userLicense: "Facebook Messenger",
    className: "list-17",
};

const listOfItems14Data = {
    supportedChannel: "Chat",
    orderManagement: "Chat Log",
    className: "list-of-items-8",
    list1Props: list17Data,
    list2Props: list18Data,
};

const list19Data = {
    userLicense: "Chatbot",
    className: "list-18",
};

const list20Data = {
    userLicense: "Automation flow",
    className: "list-19",
};

const listOfItems15Data = {
    supportedChannel: "Marketing",
    orderManagement: "Customer tag",
    className: "list-of-items-9",
    list1Props: list19Data,
    list2Props: list20Data,
};

const list21Data = {
    userLicense: "Report",
    className: "list-20",
};

const list24Data = {
    userLicense: "Dashboard",
    className: "list-21",
};

const listOfItems25Data = {
    analytic: "Analytic",
    list1Props: list21Data,
    list2Props: list24Data,
};

const list25Data = {
    userLicense: "Onboarding & rollout support",
    className: "list-22",
};

const list26Data = {
    userLicense: "SLA Support",
    className: "list-23",
};

const listOfItems26Data = {
    analytic: "Services",
    className: "list-of-items-15",
    list1Props: list25Data,
    list2Props: list26Data,
};

const list27Data = {
    userLicense: "One-time Setup Fees (THB)",
    className: "list-24",
};

const list28Data = {
    userLicense: "Per Company / Month (THB)",
    className: "list-25",
};

const listOfItems27Data = {
    analytic: "Investment",
    list1Props: list27Data,
    list2Props: list28Data,
};

const pricingCard42Data = {
    className: "pricing-card-9",
    listProps: list14Data,
    listOfItems1Props: listOfItems13Data,
    listOfItems2Props: listOfItems14Data,
    listOfItems3Props: listOfItems15Data,
    listOfItems21Props: listOfItems25Data,
    listOfItems22Props: listOfItems26Data,
    listOfItems23Props: listOfItems27Data,
};

const listOfItems34Data = {
    listOfItems1: "/img/bxs-check-circle--2--1@2x.png",
    listOfItems2: "/img/bxs-check-circle--2--1@2x.png",
    listOfItems3: "/img/bxs-check-circle--2--1@2x.png",
};

const group466682Data = {
    className: "group-46668-2",
    listOfItems3Props: listOfItems34Data,
};

const pricingCard101Data = {
    group46668Props: group466682Data,
};

const listOfItems42Data = {
    className: "list-of-items-33",
};

const listOfItems35Data = {
    listOfItems1: "/img/bxs-check-circle--2--1-17@2x.png",
    listOfItems2: "/img/bxs-check-circle--2--1-17@2x.png",
    listOfItems3: "/img/bxs-check-circle--2--1-17@2x.png",
    className: "list-of-items-31",
};

const primaryButton22Data = {
    className: "primary-button-2",
};

const listOfItems82Data = {
    className: "list-of-items-41",
};

const listOfItems92Data = {
    className: "list-of-items-44",
};

const listOfItems102Data = {
    className: "list-of-items-47",
};

const listOfItems112Data = {
    className: "list-of-items-50",
};

const listOfItems122Data = {
    className: "list-of-items-53",
};

const bigButton2Data = {
    children: "Get Started for Free!",
};

const tabletLargeData = {
    jnzMktCloudLogo: "/img/jnz-mkt-cloud-logo-1@2x.png",
    image107: "/img/image-107-1@2x.png",
    image108: "/img/image-108-1@2x.png",
    image110: "/img/image-110-1@2x.png",
    image109: "/img/image-109-1@2x.png",
    cloud1: "/img/cloud-3.png",
    image123: "/img/image-123@2x.png",
    bg: "/img/bg-1.png",
    mobile: "/img/image-124@2x.png",
    image1201: "/img/image-120-2@2x.png",
    image125: "/img/image-125.png",
    cloud2: "/img/cloud-3.png",
    maskGroup: "/img/mask-group-2.png",
    image105: "/img/image-105-1.png",
    enjoyASeamlessCha: "Enjoy a seamless chat-to-shop journey with customer chat options through LINE OA, FB Messenger, or web chat, assisted by chatbots for easy and efficient deal closures.",
    experienceIntegrate: "Experience Integrated Chat & Checkout, Live Chat with Store's Staff, Choose Delivery Types, Date & Time Slots, Payment Methods, and View Online Bills.",
    spanText1: "What we ",
    spanText2: "provide to the community, ",
    spanText3: "nation and the w       rld",
    image114: "/img/image-114@2x.png",
    spanText4: "Jenosize Technology",
    spanText5: " is more than a conventional IT or software company that merely fulfills coding requirements. Our approach goes beyond that. We possess a deep understanding of your business needs and challenges, allowing us to identify opportunities where technology can play a pivotal role in supporting and fostering sustainable growth for your business. Please browse through some of our key ",
    spanText6: "highlight products",
    spanText7: " ",
    spanText8: "bel            w",
    image115: "/img/image-115@2x.png",
    brandverseLogo1: "/img/brandverse-logo-2@2x.png",
    brandverseLogo2: "/img/brandverse-logo-3@2x.png",
    group465991: "/img/iphone13promaxmockup1fp-copy-1-2.png",
    group465891: "/img/iphone13promaxmockup1fp-copy-1-2.png",
    group46609: "/img/group-46609-2.png",
    group46610: "/img/group-46610-1@2x.png",
    group465992: "/img/iphone13promaxmockup1fp-copy-1-2.png",
    group465892: "/img/iphone13promaxmockup1fp-copy-1-2.png",
    cloud3: "/img/cloud-5.png",
    image1202: "/img/image-120-3@2x.png",
    image121: "/img/image-121-1@2x.png",
    cloud4: "/img/cloud-3.png",
    copyright2023JenosizeTechnology: "Copyright 2023 Jenosize Technology",
    title1: "PRO",
    divider1: "/img/divider-41@2x.png",
    divider2: "/img/divider-36@2x.png",
    divider3: "/img/divider-36@2x.png",
    divider4: "/img/divider-36@2x.png",
    listOfItems1: "/img/list-of-items-22@2x.png",
    divider5: "/img/divider-36@2x.png",
    divider6: "/img/divider-36@2x.png",
    pricing1: "100,000",
    pricing2: "30,000",
    title2: "ENTERPRISE",
    divider7: "/img/divider-42@2x.png",
    divider8: "/img/divider-42@2x.png",
    divider9: "/img/divider-42@2x.png",
    divider10: "/img/divider-42@2x.png",
    divider11: "/img/divider-42@2x.png",
    divider12: "/img/divider-42@2x.png",
    listOfItems2: "/img/group@2x.png",
    pricing3: "Quote",
    pricing4: "Quote",
    withToolsToMakeE: <React.Fragment>With tools to make every part of your process more human and a support team <br />excited to help you, getting started has never been easier</React.Fragment>,
    frame46813Props: frame468132Data,
    frame46670Props: frame466702Data,
    frame6605Props: frame66053Data,
    frame66061Props: frame66064Data,
    frame66062Props: frame66065Data,
    frame6607Props: frame66072Data,
    frame66063Props: frame66066Data,
    frame4672341Props: frame4672342Data,
    frame4672342Props: frame4672343Data,
    frame4672343Props: frame4672344Data,
    frame4672344Props: frame4672345Data,
    frame46703Props: frame467032Data,
    frame6617Props: frame66173Data,
    frame6621Props: frame66212Data,
    frame46707Props: frame467072Data,
    frame66222Props2: frame662222Data,
    pricingCard4Props: pricingCard42Data,
    pricingCard10Props: pricingCard101Data,
    listOfItems4Props: listOfItems42Data,
    listOfItems3Props: listOfItems35Data,
    primaryButton2Props: primaryButton22Data,
    listOfItems8Props: listOfItems82Data,
    listOfItems9Props: listOfItems92Data,
    listOfItems10Props: listOfItems102Data,
    listOfItems11Props: listOfItems112Data,
    listOfItems12Props: listOfItems122Data,
    bigButtonProps: bigButton2Data,
};

const frame467911Data = {
    number: "01",
    administrativeControlHub: "Administrative Control Hub",
    efficientlyManageU: "Efficiently manage user accounts, define roles and permissions, and oversee company and branch operations all from a single integrated platform.",
};

const frame467912Data = {
    number: "02",
    administrativeControlHub: "CRM",
    efficientlyManageU: "Enhance sales efficiency with all-in-one Customer Management, tracking lists, details, activity, orders, and seamless data input.",
};

const frame467931Data = {
    discount: "/img/discount-1@2x.png",
    frame46791Props: frame467912Data,
};

const frame467913Data = {
    number: "03",
    administrativeControlHub: "Marketing",
    efficientlyManageU: "Efficiently manage Tag & Audience, automate LINE OA flows, and easily handle LINE OA Rich menu and Flex message configurations.",
};

const frame467932Data = {
    discount: "/img/coin-free-1@2x.png",
    frame46791Props: frame467913Data,
};

const frame467914Data = {
    number: "04",
    administrativeControlHub: "ChatHub",
    efficientlyManageU: "Real-time customer communication, automated responses, and comprehensive interaction logs for seamless support and insights",
};

const frame467933Data = {
    discount: "/img/clipboard-check@2x.png",
    frame46791Props: frame467914Data,
};

const frame467991Data = {
    frame467931Props: frame467932Data,
    frame467932Props: frame467933Data,
};

const frame467915Data = {
    number: "05",
    administrativeControlHub: "Omni Channel Commerce",
    efficientlyManageU: "Omni-channel commerce, integrated LINE OA, and streamlined management for web commerce, products, orders, and coupons.",
};

const frame467934Data = {
    discount: "/img/analytics-1@2x.png",
    frame46791Props: frame467915Data,
};

const frame467916Data = {
    number: "06",
    administrativeControlHub: "Reports",
    efficientlyManageU: "Insightful Transaction, Leads, Customer Reports & Branch Data Export for smart business analysis and decision-making.",
};

const frame467935Data = {
    discount: "/img/zoom-in-1@2x.png",
    frame46791Props: frame467916Data,
};

const frame467992Data = {
    frame467931Props: frame467934Data,
    frame467932Props: frame467935Data,
};

const group466003Data = {
    className: "group-46600-2",
};

const frame46723321Data = {
    simplifyShoppingExperienceWithChat: "Simplify shopping experience with Chat",
};

const frame46723322Data = {
    simplifyShoppingExperienceWithChat: "Serve Customer Convenience",
};

const frame46723323Data = {
    simplifyShoppingExperienceWithChat: "Integrated Chat & Shop Experience",
};

const frame46723324Data = {
    simplifyShoppingExperienceWithChat: "Influences consumer choices, boosts sales",
};

const frame66175Data = {
    brandverse: "Brandverse",
};

const frame66176Data = {
    brandverse: "HappyWork",
};

const frame66213Data = {
    group46609: "/img/group-46609-3@2x.png",
    frame6617Props: frame66176Data,
};

const oURCUSTOMERS2Data = {
    className: "our-customers-4",
};

const group466493Data = {
    className: "group-46649-2",
};

const frame466785Data = {
    trueCorp: "#TRUE CORP.",
    surname: "BEST SOFTWARE I'VE EVER USED, SUPER HIGH QUALITY AND EASY-TO-USE.",
};

const frame466786Data = {
    trueCorp: "#PROPERTY PERFECT O2O CRM",
    surname: "38 Single Houses, 2 Resort Residences, 14 Townhomes, And 6 Condominiums Sold - Fulfilling Diverse Housing Needs With Excellence.",
    className: "frame-46683-2",
};

const frame466845Data = {
    bigCCallChatShop: "#BIG C CALL CHAT & SHOP",
    surpassing100KActi: "Surpassing 100k Active Users Within 2 Months And Driving Over 10m Baht Revenue From New Channels.",
};

const frame466846Data = {
    bigCCallChatShop: "#7-ELEVEN CHAT & SHOP",
    surpassing100KActi: "Successfully Launched 600 Branches In Phase I And An Additional ~10k Branches In The Subsequent Phase.",
};

const frame466823Data = {
    className: "frame-46682-2",
    frame466781Props: frame466785Data,
    frame466782Props: frame466786Data,
    frame466841Props: frame466845Data,
    frame466842Props: frame466846Data,
};

const list30Data = {
    userLicense: "User License",
};

const list31Data = {
    userLicense: "LineOA Web Commerce",
    className: "list-26",
};

const list40Data = {
    userLicense: "Product Management",
    className: "list-27",
};

const listOfItems16Data = {
    supportedChannel: "Supported Channel",
    orderManagement: "Order Management",
    list1Props: list31Data,
    list2Props: list40Data,
};

const list41Data = {
    userLicense: "Line OA",
    className: "list-28",
};

const list50Data = {
    userLicense: "Facebook Messenger",
    className: "list-29",
};

const listOfItems17Data = {
    supportedChannel: "Chat",
    orderManagement: "Chat Log",
    className: "list-of-items-10",
    list1Props: list41Data,
    list2Props: list50Data,
};

const list51Data = {
    userLicense: "Chatbot",
    className: "list-30",
};

const list54Data = {
    userLicense: "Automation flow",
    className: "list-31",
};

const listOfItems18Data = {
    supportedChannel: "Marketing",
    orderManagement: "Customer tag",
    className: "list-of-items-11",
    list1Props: list51Data,
    list2Props: list54Data,
};

const list55Data = {
    userLicense: "Report",
    className: "list-32",
};

const list56Data = {
    userLicense: "Dashboard",
    className: "list-33",
};

const listOfItems28Data = {
    analytic: "Analytic",
    list1Props: list55Data,
    list2Props: list56Data,
};

const list57Data = {
    userLicense: "Onboarding & rollout support",
    className: "list-34",
};

const list58Data = {
    userLicense: "SLA Support",
    className: "list-35",
};

const listOfItems29Data = {
    analytic: "Services",
    className: "list-of-items-16",
    list1Props: list57Data,
    list2Props: list58Data,
};

const list59Data = {
    userLicense: "One-time Setup Fees (THB)",
    className: "list-36",
};

const list60Data = {
    userLicense: "Per Company / Month (THB)",
    className: "list-37",
};

const listOfItems210Data = {
    analytic: "Investment",
    list1Props: list59Data,
    list2Props: list60Data,
};

const pricingCard43Data = {
    className: "pricing-card-9-1",
    listProps: list30Data,
    listOfItems1Props: listOfItems16Data,
    listOfItems2Props: listOfItems17Data,
    listOfItems3Props: listOfItems18Data,
    listOfItems21Props: listOfItems28Data,
    listOfItems22Props: listOfItems29Data,
    listOfItems23Props: listOfItems210Data,
};

const listOfItems36Data = {
    listOfItems1: "/img/bxs-check-circle--2--1@2x.png",
    listOfItems2: "/img/bxs-check-circle--2--1@2x.png",
    listOfItems3: "/img/bxs-check-circle--2--1@2x.png",
};

const group466683Data = {
    className: "group-46668-3",
    listOfItems3Props: listOfItems36Data,
};

const pricingCard102Data = {
    group46668Props: group466683Data,
};

const primaryButton23Data = {
    className: "primary-button-3",
};

const listOfItems83Data = {
    className: "list-of-items-42",
};

const listOfItems93Data = {
    className: "list-of-items-45",
};

const listOfItems103Data = {
    className: "list-of-items-48",
};

const listOfItems113Data = {
    className: "list-of-items-51",
};

const listOfItems123Data = {
    className: "list-of-items-54",
};

const bigButton3Data = {
    children: "Get Started for Free!",
};

const frame466412Data = {
    className: "frame-46641-2",
};

const tabletSmallData = {
    image107: "/img/image-107-1@2x.png",
    image108: "/img/image-108-1@2x.png",
    image110: "/img/image-110-1@2x.png",
    image109: "/img/image-109-1@2x.png",
    cloud1: "/img/cloud-3.png",
    image123: "/img/image-123@2x.png",
    oneAppToDoAll: "ONE APP TO DO ALL",
    forYourCustomerLove: "“FOR YOUR  CUSTOMER LOVE”",
    seamlessCommerceE: <React.Fragment>Seamless commerce, elevated CX with our Omni-Channel MarTech. Experience our powerhouse Chat Engine and Data Collection. <br />Empower your success with CRM + Commerce integration.</React.Fragment>,
    bg: "/img/bg-1.png",
    mobile: "/img/mobile@2x.png",
    whatIs: "WHAT IS",
    jenosizeMarketingCloud: "JENOSIZE MARKETING CLOUD?",
    spanText1: "Jenosize Marketing Cloud",
    spanText2: " is a cloud-based customer experience solution platform that provides businesses with a suite of tools and services to manage their sales, marketing, customer service, and other business operations. It offers a wide range of features and functionalities, including single customer profile IDs, multi-channel interactions, Omni-channel commerce, CRM, helpdesk, marketing and loyalty.",
    image1201: "/img/image-120-2@2x.png",
    cloud2: "/img/cloud-3.png",
    spanText3: "Why",
    spanText4: "  Jenosize Marketing Cloud",
    spanText5: <React.Fragment>Shop, chat and succeed with our <br /></React.Fragment>,
    spanText6: "all-in-one",
    spanText7: " solution",
    group46631: "/img/group-46631-1@2x.png",
    image126: "/img/image-126.png",
    enjoyASeamlessCha: "Enjoy a seamless chat-to-shop journey with customer chat options through LINE OA, FB Messenger, or web chat, assisted by chatbots for easy and efficient deal closures.",
    experienceIntegrate: "Experience Integrated Chat & Checkout, Live Chat with Store's Staff, Choose Delivery Types, Date & Time Slots, Payment Methods, and View Online Bills.",
    cloud3: "/img/cloud-13.png",
    spanText8: "What we ",
    spanText9: "provide to the community, ",
    spanText10: "nation and the w       rld",
    image114: "/img/image-114-3@2x.png",
    image117: "/img/image-117@2x.png",
    spanText11: "Jenosize Technology",
    spanText12: " is more than a conventional IT or software company that merely fulfills coding requirements. Our approach goes beyond that. We possess a deep understanding of your business needs and challenges, allowing us to identify opportunities where technology can play a pivotal role in supporting and fostering sustainable growth for your business. Please browse through some of our key ",
    spanText13: "highlight products",
    spanText14: " ",
    spanText15: "bel            w",
    image115: "/img/image-115@2x.png",
    brandverseLogo1: "/img/brandverse-logo-2@2x.png",
    brandverseLogo2: "/img/brandverse-logo-3@2x.png",
    group465991: "/img/iphone13promaxmockup1fp-copy-1-2.png",
    group465891: "/img/iphone13promaxmockup1fp-copy-1-2.png",
    group46609: "/img/group-46609-3.png",
    group46610: "/img/group-46610-1@2x.png",
    group465992: "/img/iphone13promaxmockup1fp-copy-1-2.png",
    group465892: "/img/iphone13promaxmockup1fp-copy-1-2.png",
    image1202: "/img/image-120-6@2x.png",
    image121: "/img/image-121-3@2x.png",
    cloud4: "/img/cloud-3.png",
    copyright2023JenosizeTechnology: "Copyright 2023 Jenosize Technology",
    planPricing: "PLAN & PRICING",
    pricingBuiltForB: <React.Fragment>PRICING BUILT FOR <br />BUSINESSES OF ALL SIZES</React.Fragment>,
    title1: "PRO",
    listOfItems1: "/img/group@2x.png",
    userLicense: "User License",
    bxsCheckCircle21: "/img/bxs-check-circle--2--1-70@2x.png",
    address: "5 admin/5 staff/Unlimited",
    divider1: "/img/divider-77@2x.png",
    text1: "",
    listOfItems2: "/img/group@2x.png",
    listOfItems3: "/img/group@2x.png",
    listOfItems4: "/img/group@2x.png",
    divider2: "/img/divider-72@2x.png",
    divider3: "/img/divider-72@2x.png",
    text2: "",
    listOfItems5: "/img/group@2x.png",
    listOfItems6: "/img/group@2x.png",
    divider4: "/img/divider-72@2x.png",
    divider5: "/img/divider-72@2x.png",
    listOfItems7: "/img/group@2x.png",
    divider6: "/img/divider-72@2x.png",
    pricing1: "100,000",
    pricing2: "30,000",
    title2: "ENTERPRISE",
    divider7: "/img/divider-42@2x.png",
    divider8: "/img/divider-42@2x.png",
    divider9: "/img/divider-42@2x.png",
    divider10: "/img/divider-42@2x.png",
    divider11: "/img/divider-42@2x.png",
    divider12: "/img/divider-42@2x.png",
    listOfItems8: "/img/group@2x.png",
    pricing3: "Quote",
    pricing4: "Quote",
    experienceInActiN: "Experience in acti       n.",
    image116: "/img/image-116-3@2x.png",
    withToolsToMakeE: <React.Fragment>With tools to make every part of your process more human and a support <br />team excited to help you, getting started has never been easier</React.Fragment>,
    frame46791Props: frame467911Data,
    frame46793Props: frame467931Data,
    frame467991Props: frame467991Data,
    frame467992Props: frame467992Data,
    group46600Props: group466003Data,
    frame4672331Props: frame46723321Data,
    frame4672332Props: frame46723322Data,
    frame4672333Props: frame46723323Data,
    frame4672334Props: frame46723324Data,
    frame6617Props: frame66175Data,
    frame6621Props: frame66213Data,
    frame6621Props2: oURCUSTOMERS2Data,
    group46649Props: group466493Data,
    frame46682Props: frame466823Data,
    pricingCard4Props: pricingCard43Data,
    pricingCard10Props: pricingCard102Data,
    primaryButton2Props: primaryButton23Data,
    listOfItems8Props: listOfItems83Data,
    listOfItems9Props: listOfItems93Data,
    listOfItems10Props: listOfItems103Data,
    listOfItems11Props: listOfItems113Data,
    listOfItems12Props: listOfItems123Data,
    bigButtonProps: bigButton3Data,
    frame46641Props: frame466412Data,
};

